import { useCallback, useEffect, useState } from 'react';
import { Space, Row, Col, Typography, Avatar, Switch, AutoComplete, Card, Tag, Select, Spin, Button, FloatButton, Divider } from 'antd';
import { Professional, SpecialtyType, Specialty, AccreditationStatus, Accreditation, Center } from '../../store/types';
import { accreditationsSelectors, professionalsSelectors } from '../../store/selectors/professional';
import { accreditationStatusesSelectors, professionalTypeSelectors, specialtiesSelectors, specialtyTypesSelectors } from '../../store/selectors/domains';
import { EditOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'react-redux';
import { AuthRoutes } from '../../components';
import { useTranslation } from 'react-i18next';
import { includes } from '../../utils/util-custom-validators';
import { baseStyles } from '../../assets/styles';
import { formatSocialId } from '../../utils/util-generics';
import { centersSelectors } from '../../store/selectors';
import { fetchProfessionals } from '../../store/api';
import femaleIcon from '../../assets/images/female_icon.png';
import maleIcon from '../../assets/images/male_icon.png';
import specialtiesIcon from '../../assets/images/icon-specialties.png';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash.debounce';
import { useAppConfigContext } from '../../utils';

const { Text, Title } = Typography;
const { Option } = Select;

const route = (professionalTypeCode: string): string => {
	var route = '';
	switch (professionalTypeCode) {
		case 'ME':
			route = AuthRoutes.physicians;
			break;
		case 'EN':
			route = AuthRoutes.nurses;
			break;
		case 'KS':
			route = AuthRoutes.kinesiologists;
			break;
		case 'PS':
			route = AuthRoutes.psychologists;
			break;
		case 'OD':
			route = AuthRoutes.dentists;
			break;
		case 'NU':
			route = AuthRoutes.nutritionists;
			break;
		case 'TM':
			route = AuthRoutes.medicalTechnologists;
			break;
	}
	return route;
};

const filterProfessionals = (
	hide,
	searchText,
	index,
	professionalTypeId,
	centerId,
	specialtyTypeId,
	professionals,
	specialties,
): { professionals: Professional[]; partialProfessionals: Professional[]; hasMore: boolean } => {
	var item = {
		professionals: [],
		partialProfessionals: [],
		hasMore: false,
	};
	var items = professionals.filter(p => !p.isDeleted && p.professionalTypeId === professionalTypeId);
	if (hide) items = items.filter(i => i.isActive);
	if (centerId) items = items.filter(i => i.centers.includes(centerId));
	if (specialtyTypeId) {
		var specialtiesSelected = specialties.filter(s => !s.isDeleted && s.specialtyTypeId === specialtyTypeId);
		var professionalIds = specialtiesSelected.map(s => s.professionalId);
		items = items.filter(i => professionalIds.includes(i.id));
	}
	if (searchText !== '') {
		items = items.filter(
			p =>
				includes(p.lastName + ' ' + p.secondLastName + ' ' + p.names, searchText) ||
				includes(p.names.split(' ')[0] + ' ' + p.lastName + ' ' + p.secondLastName, searchText) ||
				includes(p.names + ' ' + p.lastName + ' ' + p.secondLastName, searchText) ||
				includes(p.socialId, searchText),
		);
	}
	items = items.sort((a, b) => a.lastName.localeCompare(b.lastName));
	item.professionals = items;
	if (items.length > index) {
		item.partialProfessionals = items.slice(0, index - 1);
		item.hasMore = true;
	} else {
		item.partialProfessionals = items.slice(0, items.length);
		item.hasMore = false;
	}
	return item;
};

/// Render the tables for nurses
const FormProfessionalList = ({ professionalTypeCode = 'ME', centerId = undefined, disabled = false }) => {
	dayjs.extend(relativeTime);
	const store = useStore();
	const location = useLocation();
	const extraItems = 30;
	const cardWidth = 250;
	const cardHeight = 400;
	const gutter = 24;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const styles = baseStyles;
	const { token, user } = useAppConfigContext();
	const [extraWidth, setExtraWidth] = useState<number>(0);
	const [professionalTypeId, setProfessionalTypeId] = useState<number>(0);
	const [specialtyTypes, setSpecialtyTypes] = useState<SpecialtyType[]>([]);
	const [specialties, setSpecialties] = useState<Specialty[]>([]);
	const [selectedSpecialtyTypeId, setSelectedSpecialtyTypeId] = useState<number>(0);
	const [selectedCenterId, setSelectedCenterId] = useState<number>(centerId);
	const [professionals, setProfessionals] = useState<Professional[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [partialProfessionals, setPartialProfessionals] = useState<Professional[]>([]);
	const [accreditationStatuses, setAccreditationStatuses] = useState<AccreditationStatus[]>([]);
	const [accreditations, setAccreditations] = useState<Accreditation[]>([]);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [index, setIndex] = useState<number>(extraItems);
	const [hideInactives, setHideInactives] = useState<boolean>(true);
	const [searchText, setSearchText] = useState<string>('');

	window.onresize = () => {
		var numberOfCards = Math.trunc((window.innerWidth - 20) / (cardWidth + gutter));
		var extra = window.innerWidth - 20 - numberOfCards * (cardWidth + gutter);
		var tempExtraWidth = Math.trunc(extra / numberOfCards);
		setExtraWidth(tempExtraWidth);
	};

	useEffect(() => {
		const getProfessionals = async hideInactives => {
			var professionals = [];
			if (hideInactives) professionals = professionalsSelectors.selectAll(store.getState() as any);
			else {
				var items = await fetchProfessionals(token, user);
				professionals = items.professionals;
			}
			return professionals;
		};

		setCenters(
			centersSelectors
				.selectAll(store.getState() as any)
				.filter(c => c.isVisible && !c.isDeleted)
				.sort((a, b) => a.name.localeCompare(b.name)),
		);
		setAccreditationStatuses(accreditationStatusesSelectors.selectAll(store.getState() as any).filter(s => !s.isDeleted));
		setAccreditations(accreditationsSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted));
		var professionalTypeIdSelected = professionalTypeSelectors.selectAll(store.getState() as any).find(p => p.code === professionalTypeCode)?.id;
		if (professionalTypeIdSelected !== professionalTypeId) setIndex(30);
		setProfessionalTypeId(professionalTypeIdSelected);
		setSpecialtyTypes(specialtyTypesSelectors.selectAll(store.getState() as any).filter(s => !s.isDeleted));
		var itemsSpecialty = specialtiesSelectors.selectAll(store.getState() as any).filter(s => !s.isDeleted);
		setSpecialties(itemsSpecialty);
		var numberOfCards = Math.trunc((window.innerWidth - 20) / (cardWidth + gutter));
		var extra = window.innerWidth - 20 - numberOfCards * (cardWidth + gutter);
		var tempExtraWidth = Math.trunc(extra / numberOfCards);
		setExtraWidth(tempExtraWidth);
		getProfessionals(hideInactives).then(itemProfessionals => {
			var selecteds = filterProfessionals(
				hideInactives,
				searchText,
				index,
				professionalTypeId,
				centerId ? centerId : selectedCenterId,
				selectedSpecialtyTypeId,
				itemProfessionals,
				itemsSpecialty,
			);
			setProfessionals(selecteds.professionals);
			setPartialProfessionals(selecteds.partialProfessionals);
			setHasMore(selecteds.hasMore);
		});
	}, [centerId, hideInactives, index, professionalTypeCode, professionalTypeId, searchText, selectedCenterId, selectedSpecialtyTypeId, store, token, user]);

	const handleSelect = (option: number) => {
		navigate(route(professionalTypeCode) + `/details`, {
			state: { id: option, professionalTypeId: professionalTypeId, disabled: disabled, route: location.pathname },
		});
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(
		debounce(value => {
			setSearchText(value);
		}, 500),
		[searchText],
	);

	const handleSearchChange = (value: string) => {
		debounceSearch(value);
	};

	const handleChangeSpecialtyType = value => {
		setSelectedSpecialtyTypeId(value);
		setProfessionals([]);
		setPartialProfessionals([]);
	};

	const handleChangeCenter = value => {
		setSelectedCenterId(value);
		setProfessionals([]);
		setPartialProfessionals([]);
	};

	const handleHideInactiveChange = value => {
		setHideInactives(value);
		setProfessionals([]);
		setPartialProfessionals([]);
	};

	const handleNext = () => {
		var step = extraItems;
		if (index + extraItems > professionals.length) {
			step = professionals.length - index;
			setHasMore(false);
		}
		setPartialProfessionals(partialProfessionals.concat(professionals.slice(index, step)));
		setIndex(index + step);
	};
	return (
		<Col style={{ width: '100%' }}>
			<Row style={{ paddingTop: 10 }} align="middle">
				<Col flex="auto">
					<Space wrap direction="horizontal">
						<Text ellipsis strong>
							{t('general.searchEngine')}
						</Text>
						<AutoComplete
							popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
							style={styles.search}
							placeholder={t('general.search')}
							onChange={handleSearchChange}
							allowClear
						/>
						<Text ellipsis strong>
							{t('general.filters')}
						</Text>
						<Select
							style={{ width: 300 }}
							showSearch
							optionFilterProp="children"
							onChange={handleChangeSpecialtyType}
							placeholder={t('messages.selectSpecialtyToFilter')}
							allowClear
						>
							{specialtyTypes
								.filter(st => st.professionalTypeId === professionalTypeId)
								.map(pt => {
									return (
										<Option key={`professionalType-${pt.id}`} value={pt.id}>
											{pt.description}
										</Option>
									);
								})}
						</Select>
						{centerId ? null : (
							<Select
								style={{ width: 300 }}
								showSearch
								optionFilterProp="children"
								onChange={handleChangeCenter}
								placeholder={t('messages.selectCenterToFilter')}
								allowClear
							>
								{centers.map(c => {
									return (
										<Option key={`centers-${c.id}`} value={c.id}>
											{c.name}
										</Option>
									);
								})}
							</Select>
						)}
						<Space>
							<Text> {t('professionals.hideInactive')}</Text>
							<Switch checked={hideInactives} onChange={handleHideInactiveChange}></Switch>
						</Space>
					</Space>
				</Col>
				<Col style={{ paddingRight: 10 }}>
					<Space size="middle">
						{centerId ? null : (
							<Row>
								<Space>
									<Text>
										{t('messages.totalSelectedProfessionals')} : {professionals.length.toLocaleString()}
									</Text>
								</Space>
							</Row>
						)}
					</Space>
				</Col>
			</Row>
			<InfiniteScroll
				key="infinite-scroll"
				dataLength={partialProfessionals.length}
				next={handleNext}
				hasMore={hasMore}
				loader={
					<Row justify="center" style={{ padding: 20 }}>
						<Spin key="spin" size="large"></Spin>
					</Row>
				}
				style={{ overflow: 'hidden', paddingTop: 10 }}
			>
				<Row gutter={[gutter, gutter]}>
					<FloatButton.BackTop key="back-top" type="primary" tooltip={t('messages.backToTop')} />
					{partialProfessionals.map((item, index) => {
						var tempSpecialties = specialties.filter(s => s.professionalId === item.id).sort(value => (value.isActive ? -1 : 1));
						var items = accreditations.filter(a => a.professionalId === item.id).sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)));
						var accreditationStatus = items.length > 0 ? accreditationStatuses.find(acs => acs.id === items[0].accreditationStatusId) : undefined;
						var tags = (
							<div key={`div-specialties-${item.id}`}>
								{tempSpecialties.length > 0 ? (
									<Row justify="center">
										<Tag style={styles.tag} key={`specialtyType-${item.id}-1`}>
											<Text style={styles.tag} ellipsis>
												{specialtyTypes.find(spt => tempSpecialties[0].specialtyTypeId === spt.id)?.description.toUpperCase()}
												{tempSpecialties[0].isActive ? '*' : ''}
											</Text>
										</Tag>
									</Row>
								) : null}
								<Row justify="center">
									{tempSpecialties.length > 1 ? (
										<Tag style={styles.tag} key={`specialtyType-${item.id}-2`}>
											<Text
												style={styles.tag}
												ellipsis={{ tooltip: specialtyTypes.find(spt => tempSpecialties[1].specialtyTypeId === spt.id)?.description.toUpperCase() }}
											>
												{specialtyTypes.find(spt => tempSpecialties[1].specialtyTypeId === spt.id)?.description.toUpperCase()}
											</Text>
										</Tag>
									) : null}
									{tempSpecialties.length > 2 ? (
										<Tag style={styles.tag} key={`specialtyType-${item.id}-3`}>
											{`${tempSpecialties.length - 2}+`}
										</Tag>
									) : null}
								</Row>
							</div>
						);
						return (
							<Col span={window.innerWidth / cardWidth}>
								<Card
									size="small"
									key={`card-${index}`}
									style={{ width: cardWidth + extraWidth, height: cardHeight }}
									extra={
										disabled
											? []
											: [<Button onClick={() => handleSelect(item.id)} key={`edit-${item.id}`} type="primary" shape="round" icon={<EditOutlined />} />]
									}
								>
									<Row key={`row-avatar-${index}`} justify="center" style={{ paddingTop: 20 }}>
										{item.avatar !== '' ? (
											<Avatar alt="avatar" key={`avatar-picture-${item.id}`} size={120} src={`${item.avatar}?${Date.now()}`} />
										) : (
											<Avatar alt="avatar" key={`avatar-initials-${item.id}`} size={120} src={item.genderId === 1 ? maleIcon : femaleIcon} />
										)}
									</Row>
									<Row key={`row-title1-${index}`} justify="center">
										<Title level={5} style={{ margin: 0 }}>{`${item.names}`}</Title>
									</Row>
									<Row key={`row-title2-${index}`} justify="center">
										<Title
											level={4}
											style={{ textAlign: 'center', margin: 0 }}
											ellipsis={{
												rows: 1,
												expandable: false,
												tooltip: true,
											}}
										>{`${item.lastName} ${item.secondLastName}`}</Title>
									</Row>
									<Row key={`row-rut-${index}`} justify="center">
										<Text strong>{formatSocialId(item.socialId)}</Text>
									</Row>
									<Row key={`row-status-${index}`} justify="center">
										{accreditationStatus?.code === 'DA' || accreditationStatus?.code === 'RE' ? (
											<Tag key={`status-${item.id}`} style={{ background: 'red', ...styles.tagAlternative }}>
												{accreditationStatus?.description.toUpperCase()}
											</Tag>
										) : (
											<Tag key={`status-${item.id}`} style={styles.tagAlternative}>
												{accreditationStatus?.description.toUpperCase()}
											</Tag>
										)}
									</Row>
									<Divider style={{ height: 15 }}>
										<img style={{ height: 25 }} src={specialtiesIcon} alt="specialties"></img>
									</Divider>
									<Row key={`row-tags-${index}`} justify="center">
										<Space key={`space-${index}`} direction="vertical" size="small">
											{tags}
										</Space>
									</Row>
								</Card>
							</Col>
						);
					})}
				</Row>
			</InfiniteScroll>
		</Col>
	);
};

export default FormProfessionalList;
