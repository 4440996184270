import { Font, StyleSheet } from '@react-pdf/renderer';
import { colors } from './styles';

Font.register({
	family: 'Oswald',
	src: require('./fonts/oswald.ttf'),
});

const styles = StyleSheet.create({
	body: {
		paddingTop: 20,
		paddingBottom: 40,
		paddingHorizontal: 20,
	},
	header: {
		top: 0,
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		top: 780,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
	title: {
		fontSize: 16,
		margin: 12,
		fontFamily: 'Oswald',
		textAlign: 'center',
		color: colors.brandColor1,
	},
	section: { fontSize: 16, margin: 12, fontFamily: 'Oswald' },
	text: {
		fontSize: 12,
		textAlign: 'justify',
		fontFamily: 'Times-Roman',
	},
	table_text_left: { textAlign: 'left', fontSize: 12, width: 150 },
	table_text_center: { textAlign: 'center', fontSize: 12, width: 40 },
});
export default styles;
