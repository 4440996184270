import { createElement, ReactNode } from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { styles, colors, dateFormat, dateTimeFormat } from '../../assets';
import { PDFCOMPINDocumentProps } from '../../types';
import logoImage from '../../assets/images/logo-redsalud-light.png';
import dayjs from 'dayjs';

// Create COMPIN document
const PDFCOMPINDocument = (props: PDFCOMPINDocumentProps): ReactNode => {
	const patientName = props.patient.nombre;
	const patientLastName = props.patient.apellido_paterno;
	const patientSecondLastName = props.patient.apellido_materno;
	const patientId = props.patient.numero_identificacion;
	const patientBirthDate = props.patient.fecha_nacimiento;
	const patientSex = props.patient.sexo;
	const patientAddress = props.patient.direccion;
	const patientPhone = props.patient.telefono;
	const profesionalName = props.encounter.profesional?.nombre_completo;
	const profesionalSpecialty = props.encounter.profesional?.especialidad;
	const profesionalId = props.encounter.profesional?.rut;
	const date = props.encounter?.fecha;
	const age = dayjs(props.encounter?.fecha).diff(dayjs(patientBirthDate), 'years');
	const location = props.encounter.establecimiento?.descripcion;
	const userData = props.user;
	const diagnoses = props.encounter.diagnosticos || [];
	const indications = props.encounter.indicaciones || [];
	const prescriptions = props.encounter.medicamentos || [];
	var allNotes = [];

	props.encounter.registros?.forEach(r => {
		var notes = r.registro.split('\n');
		notes.forEach(n => allNotes.push(n));
	});

	const footer = () => {
		return <Text render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`} style={styles.pageNumber}></Text>;
	};

	const header = () => {
		return (
			<Text style={styles.header} fixed>
				{'Generado por ' + userData + ' el ' + dayjs(new Date()).format(dateTimeFormat)}
			</Text>
		);
	};

	return (
		<Document pageLayout="singlePage">
			<Page style={styles.body}>
				<View style={{ flexDirection: 'column' }}>
					{header()}
					<View style={{ flexDirection: 'row', display: 'flex', paddingTop: 20 }}>
						<View style={{ display: 'flex', height: 50, width: '50%', justifyContent: 'flex-start' }}>
							<Image src={logoImage} style={{ height: 30, width: 100 }}></Image>
						</View>
						<View style={{ display: 'flex', height: 50, width: '50%', justifyContent: 'flex-start' }}>
							<Text style={{ textAlign: 'right', fontSize: 16, color: colors.brandColor1 }}>Dirección Médica Corporativa</Text>
							<Text style={{ textAlign: 'right', fontSize: 16, color: colors.brandColor1 }}>Dirección de Informática Médica</Text>
						</View>
					</View>
					<Text style={styles.title}>Carátula</Text>
					<Text style={styles.section}>Datos del paciente</Text>
					<View style={{ padding: '10 10 10 10', border: 1 }}>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Primer apellido</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientLastName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Segundo apellido</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientSecondLastName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Nombres</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>RUT</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientId}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Fecha de Nacimiento</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{dayjs(patientBirthDate, 'YYYY-MM-DD hh:mm:ss').format(dateFormat)}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Sexo</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientSex}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Edad</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{age + ' años'}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Domicilio</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientAddress}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Teléfono</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{patientPhone}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Representante legal o Apoderado</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}></Text>
						</View>
					</View>
					<Text style={styles.section}>Datos de la atención</Text>
					<View style={{ padding: '10 10 10 10', border: 1 }}>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Nombre del profesional</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{profesionalName}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>RUT del profesional</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{profesionalId}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Especialidad</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{profesionalSpecialty}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Fecha de atención</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{dayjs(date, 'YYYY-MM-DD hh:mm:ss').format(dateFormat)}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Lugar de la atención</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>{location}</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Nombre del prestador</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>MEGASALUD SPA</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>RUT del prestador</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>96942400 - 2</Text>
						</View>
						<View style={{ flexDirection: 'row', display: 'flex' }}>
							<Text style={styles.table_text_left}>Software</Text>
							<Text style={styles.table_text_center}>:</Text>
							<Text style={{ textAlign: 'center', fontSize: 14 }}>Software TrakCare de InterSystems</Text>
						</View>
					</View>
					{footer()}
				</View>
			</Page>
			<Page wrap style={styles.body}>
				<View style={{ flexDirection: 'column' }}>
					{header()}
					<Text style={styles.title}>Registro Clínico</Text>
					<Text style={styles.section}>Registro</Text>
					{allNotes
						? allNotes.map((item, index) => (
								<Text key={'notes-' + index} style={styles.text}>
									{item}
								</Text>
						  ))
						: null}
					{diagnoses && diagnoses.length > 0 ? <Text style={styles.section}>Diagnósticos</Text> : null}
					{diagnoses.map((item, index) => (
						<Text key={'notes-' + index} style={styles.text}>
							{' - ' + item.registro}
						</Text>
					))}
					{indications && prescriptions && indications.length > 0 && prescriptions.length > 0 ? <Text style={styles.section}>Indicaciones</Text> : null}
					{indications.map((item, index) => (
						<Text key={'indications-' + index} style={styles.text}>
							{' - ' + item.registro}
						</Text>
					))}
					{prescriptions.map((item, index) => (
						<Text key={'prescription-' + index} style={styles.text}>
							{' - ' + item.registro}
						</Text>
					))}
					{footer()}
				</View>
			</Page>
		</Document>
	);
};

const renderPDFCOMPINDocument = async (props: PDFCOMPINDocumentProps) => {
	const { pdf } = await import('@react-pdf/renderer');
	const blob = await pdf(createElement(PDFCOMPINDocument, props)).toBlob();
	return URL.createObjectURL(blob);
};

export default renderPDFCOMPINDocument;
