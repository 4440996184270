import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, Select, InputNumber, Typography } from 'antd';
import { Professional, initialProfessionalValue, ProfessionalType, RelationType } from '../../store/types';
import {
	FormProfessionalPrivileges,
	FormProfessionalAccreditationsDocuments,
	FormProfessionalAccreditations,
	FormProfessionalCategories,
	FormProfessionalMilestones,
	FormProfessionalDocuments,
} from '../../components';
import { professionalTypeSelectors, relationTypesSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { baseStyles } from '../../assets/styles';
import { objectsAreEquals } from '../../utils/util-custom-validators';
import { fetchProfessional } from '../../store/api';
import { useAppConfigContext } from '../../utils';
import FormProfessionalAccreditationComments from './form-professional-accreditation-comments';

const { Option } = Select;
const { Text } = Typography;

const FormProfessionalAccreditationData = ({ professionalId, professionalType, setProfessionalData, setIsChanged }) => {
	const store = useStore();
	const styles = baseStyles;
	const [formProfessionalAccreditationData] = Form.useForm();
	const { token, user } = useAppConfigContext();
	const { t } = useTranslation();
	const [originalProfessional, setOriginalProfessional] = useState<Professional>(initialProfessionalValue);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [relationTypes, setRelationTypes] = useState<RelationType[]>([]);

	useEffect(() => {
		setProfessionalTypes(professionalTypeSelectors.selectAll(store.getState() as any));
		setRelationTypes(relationTypesSelectors.selectAll(store.getState() as any));
		fetchProfessional(professionalId, token, user).then(item => {
			setOriginalProfessional(item);
			setProfessional(item);
			formProfessionalAccreditationData.setFieldsValue({
				sisNumber: item.sisNumber,
				eunacomResult: item.eunacomResult,
				hours: item.hours,
				relationType: item.relationTypeId,
				professionalType: item.professionalTypeId,
			});
		});
	}, [formProfessionalAccreditationData, professionalId, professionalTypes, relationTypes, store, token, user]);

	const handleValuesChange = (changedValues: any) => {
		var item = professional;
		if (changedValues['sisNumber']) {
			item = { ...professional, sisNumber: changedValues['sisNumber'] };
		}
		if (changedValues['relationType']) {
			item = { ...professional, relationTypeId: changedValues['relationType'] };
		}
		if (changedValues['eunacomResult']) {
			item = { ...professional, eunacomResult: changedValues['eunacomResult'] };
		}
		if (changedValues['hours']) {
			item = { ...professional, hours: changedValues['hours'] };
		}
		if (changedValues['professionalType']) {
			item = { ...professional, professionalTypeId: changedValues['professionalType'] };
		}
		setIsChanged(!objectsAreEquals(originalProfessional, item));
		setProfessional(item);
		setProfessionalData(item);
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<Card size="small" title={t('professionals.accreditationDetails')} style={styles.card}>
						<Form {...baseStyles.formLayout} form={formProfessionalAccreditationData} onValuesChange={handleValuesChange} name="accreditation-details">
							<Form.Item label={t('professionals.professionalType')} name="professionalType" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select>
									{professionalTypes.map(pt => {
										return (
											<Option key={`professionalType-${pt.id}`} value={pt.id}>
												{pt.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('professionals.idNumber')} name="sisNumber" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('professionals.eunacomResult')} name="eunacomResult" rules={[{ required: false }]}>
								<Input placeholder="Escribir no aplica según corresponda" />
							</Form.Item>
							<Form.Item label={t('professionals.exclusiveHours')} name="hours" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<InputNumber max={90} min={0} step={1}></InputNumber>
							</Form.Item>
							<Form.Item label={t('professionals.relationType')} name="relationType">
								<Select>
									{relationTypes.map(rt => {
										return (
											<Option key={`relationType-${rt.id}`} value={rt.id}>
												{rt.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Text strong>{t('general.lastModification') + ': ' + professional.modifiedBy}</Text>
						</Form>
					</Card>
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalAccreditations key={`accreditations-row`} professionalId={professionalId} professionalType={professionalType} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalAccreditationComments key={`accreditations-row`} professionalId={professionalId} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalMilestones key={`milestones-row`} professionalId={professionalId} professionalType={professionalType} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalCategories key={`categories-row}`} professionalId={professionalId} professionalType={professionalType} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalPrivileges key={`privileges-row`} professionalId={professionalId} professionalType={professionalType} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalAccreditationsDocuments key={`documents-row`} professionalId={professionalId} professionalType={professionalType} />
				</Col>
				<Col className="gutter-row" lg={24} xl={24} xxl={12}>
					<FormProfessionalDocuments professionalId={professionalId} />
				</Col>
			</Row>
		</div>
	);
};

export default FormProfessionalAccreditationData;
