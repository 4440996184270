import { useCallback, useEffect, useState } from 'react';
import { Avatar, Card, Col, Row, Space, Typography, Select, AutoComplete, Spin, Layout, FloatButton } from 'antd';
import { useStore } from 'react-redux';
import { Document, DocumentAttachment, ParentType, Tag as TagItem } from '../../store/types';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { arrayIncludes, includes } from '../../utils/util-custom-validators';
import { baseStyles, colors } from '../../assets/styles';
import { fetchDocuments, fetchTags } from '../../store/api';
import { useAppConfigContext } from '../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash.debounce';

const { Text, Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const PageViewDocuments = () => {
	const { t } = useTranslation();
	const store = useStore();
	const extraItems = 50;
	const cardWidth = 220;
	const cardHeight = 340;
	const styles = baseStyles;
	const { token, user } = useAppConfigContext();
	const [documents, setDocuments] = useState<Document[]>([]);
	const [attachments, setAttachments] = useState<DocumentAttachment[]>([]);
	const [tags, setTags] = useState<TagItem[]>([]);
	const [partialDocuments, setPartialDocuments] = useState<Document[]>([]);
	const [index, setIndex] = useState<number>(50);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const spinner = (
		<Col>
			<Row justify="center">
				<Spin size="large"></Spin>
			</Row>
		</Col>
	);

	useEffect(() => {
		var selectedTags = [];
		fetchDocuments(token, user).then(result => {
			var items = result.documents.filter(ni => !ni.isDeleted && ni.isPublished);
			items = items.sort((a, b) => a.title.localeCompare(b.title));
			items.forEach(i => (selectedTags = selectedTags.concat(i.tags)));
			selectedTags = selectedTags.filter((item, pos, self) => {
				return self.indexOf(item) === pos;
			});
			fetchTags(token, user).then(resultTags => {
				var tagItems = resultTags.filter(t => selectedTags.includes(t.description));
				tagItems = tagItems.sort((a, b) => a.description.localeCompare(b.description));
				setTags(tagItems);
			});
			setDocuments(items);
			setAttachments(result.documentAttachments);
			setPartialDocuments(items.slice(0, items.length > index ? index - 1 : items.length));
			setHasMore(items.length > index ? true : false);
		});
	}, [index, store, token, user]);

	const handleNext = () => {
		var step = extraItems;
		if (index + extraItems > documents.length) {
			step = documents.length - index;
			setHasMore(false);
		}
		setPartialDocuments(partialDocuments.concat(documents.slice(index, step)));
		setIndex(index + step);
	};

	const handleSelect = option => {
		var selected = documents.find(d => d.id === option);
		var selectedAttachments = attachments.filter(da => da.parentId === selected.id && da.parentType === ParentType.documentItem);
		selectedAttachments.forEach(a => window.open(a.url, '_blank', 'noopener,noreferrer'));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(
		debounce(value => {
			setPartialDocuments(documents.filter(d => includes(d.title, value)));
			setHasMore(false);
		}, 500),
		[],
	);

	const handleSearch = (value: string) => {
		if (!value) {
			setPartialDocuments(documents.slice(0, documents.length > index ? index - 1 : documents.length));
			setHasMore(documents.length > index ? true : false);
		}
		debounceSearch(value);
	};

	const handleClear = () => {
		setHasMore(false);
		setIndex(50);
		setPartialDocuments(documents.slice(0, documents.length > index ? index - 1 : documents.length));
	};

	const handleChangeTag = value => {
		var items = documents.filter(ni => !ni.isDeleted && ni.isPublished);
		if (value) {
			items = items.filter(ni => arrayIncludes(ni.tags, value));
			items = items.sort((a, b) => a.title.localeCompare(b.title));
			setPartialDocuments(items);
			setHasMore(false);
		} else {
			items = items.sort((a, b) => a.title.localeCompare(b.title));
			setPartialDocuments(items.slice(0, items.length > index ? index - 1 : items.length));
			setHasMore(true);
		}
	};

	return (
		<div>
			<Col>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Row>
									<Col>
										<Text strong>{t('general.searchEngine')}</Text>
										<AutoComplete
											popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
											style={styles.search}
											placeholder={t('general.search')}
											allowClear
											onClear={handleClear}
											onSelect={handleSelect}
											onSearch={handleSearch}
										/>
									</Col>
									<Col>
										<Text strong>{t('general.filters')}</Text>
										<Select style={{ width: 250 }} onChange={handleChangeTag} onClear={handleClear} placeholder={t('messages.selectTagToFilter')} allowClear>
											{tags.map(pt => {
												return (
													<Option key={`tags-${pt.id}`} value={pt.description}>
														{pt.description}
													</Option>
												);
											})}
										</Select>
									</Col>
								</Row>
							</Space>
						</Col>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						{documents.length > 0 ? (
							<InfiniteScroll dataLength={partialDocuments.length} next={handleNext} hasMore={hasMore} loader={spinner} style={{ overflow: 'hidden' }}>
								<Row gutter={[24, 24]}>
									<FloatButton.BackTop />
									{partialDocuments.map((item, index) => {
										return (
											<Col span={window.innerWidth / cardWidth}>
												<Card
													hoverable
													key={`card-${index}`}
													id={`card-${index}`}
													style={{ width: cardWidth, height: cardHeight }}
													styles={{ header: { backgroundColor: colors.brandColor1, color: colors.backgroundColor }, body: { height: 280 } }}
													actions={[
														<Text
															key={`actions-${index}`}
															onClick={() => {
																handleSelect(item.id);
															}}
														>
															{t('general.view')}
														</Text>,
													]}
												>
													<Space direction="vertical" style={{ width: '100%', justifyContent: 'center' }}>
														<Row justify="center" style={{ paddingTop: 10 }}>
															{item.avatar !== '' ? <Avatar size={120} src={`${item.avatar}?${Date.now()}`} /> : <Avatar size={120} icon={<UserOutlined />} />}
														</Row>
														<Row justify="center">
															<Title
																level={4}
																style={{ textAlign: 'center' }}
																ellipsis={{
																	rows: 3,
																	expandable: false,
																	tooltip: true,
																}}
															>
																{`${item.title}`}
															</Title>
														</Row>
													</Space>
												</Card>
											</Col>
										);
									})}
								</Row>
							</InfiniteScroll>
						) : null}
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PageViewDocuments;
