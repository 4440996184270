import { AutoComplete, Avatar, Card, Col, Divider, FloatButton, Layout, Row, Select, Space, Spin, Tag, Typography } from 'antd';
import { Center, Professional, Specialty, SpecialtyType } from '../../store/types';
import { includes } from '../../utils/util-custom-validators';
import { baseStyles } from '../../assets/styles';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchActiveProfessionals, fetchCenters, fetchSpecialties, fetchSpecialtyTypes } from '../../store/api';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useAppConfigContext } from '../../utils';
import femaleIcon from '../../assets/images/female_icon.png';
import maleIcon from '../../assets/images/male_icon.png';
import specialtiesIcon from '../../assets/images/icon-specialties.png';
import debounce from 'lodash.debounce';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Content } = Layout;
const { Text, Title } = Typography;

const filterProfessionals = (
	searchText,
	index,
	centerId,
	specialtyTypeId,
	professionals,
	specialties,
): { professionals: Professional[]; partialProfessionals: Professional[]; hasMore: boolean } => {
	var item = {
		professionals: [],
		partialProfessionals: [],
		hasMore: false,
	};
	var items = professionals.filter(p => !p.isDeleted && p.centers.length > 0);
	if (centerId !== 0) items = items.filter(i => i.centers.includes(centerId));
	if (specialtyTypeId !== 0) {
		var specialtiesSelected = specialties.filter(s => !s.isDeleted && s.specialtyTypeId === specialtyTypeId);
		var professionalIds = specialtiesSelected.map(s => s.professionalId);
		items = items.filter(i => professionalIds.includes(i.id));
	}
	if (searchText !== '') {
		items = items.filter(
			p =>
				includes(p.lastName + ' ' + p.secondLastName + ' ' + p.names, searchText) ||
				includes(p.names.split(' ')[0] + ' ' + p.lastName + ' ' + p.secondLastName, searchText) ||
				includes(p.names + ' ' + p.lastName + ' ' + p.secondLastName, searchText) ||
				includes(p.socialId, searchText),
		);
	}
	item.professionals = items;
	if (items.length > index) {
		item.partialProfessionals = items.slice(0, index - 1);
		item.hasMore = true;
	} else {
		item.partialProfessionals = items.slice(0, items.length);
		item.hasMore = false;
	}
	return item;
};

const PageProfessionalsPhysicians = () => {
	const extraItems = 50;
	const cardWidth = 220;
	const cardHeight = 400;
	const gutter = 24;
	const { t } = useTranslation();
	const styles = baseStyles;
	const { token, user, initialConfig } = useAppConfigContext();
	const [extraWidth, setExtraWidth] = useState<number>(0);
	const [specialtyTypes, setSpecialtyTypes] = useState<SpecialtyType[]>([]);
	const [specialties, setSpecialties] = useState<Specialty[]>([]);
	const [selectedSpecialtyTypeId, setSelectedSpecialtyTypeId] = useState<number>(0);
	const [selectedCenterId, setSelectedCenterId] = useState<number>(0);
	const [allProfessionals, setAllProfessionals] = useState<Professional[]>([]);
	const [allSpecialties, setAllSpecialties] = useState<Specialty[]>([]);
	const [professionals, setProfessionals] = useState<Professional[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [partialProfessionals, setPartialProfessionals] = useState<Professional[]>([]);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [index, setIndex] = useState<number>(extraItems);
	const [searchText, setSearchText] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const [mge, setMge] = useState<number>(0);

	window.onresize = () => {
		var numberOfCards = Math.trunc((window.innerWidth - 20) / (cardWidth + gutter));
		var extra = window.innerWidth - 20 - numberOfCards * (cardWidth + gutter);
		var tempExtraWidth = Math.trunc(extra / numberOfCards) - 1;
		setExtraWidth(tempExtraWidth);
	};

	useEffect(() => {
		if (loading) {
			setLoading(false);
			var numberOfCards = Math.trunc((window.innerWidth - 20) / (cardWidth + gutter));
			var extra = window.innerWidth - 20 - numberOfCards * (cardWidth + gutter);
			var tempExtraWidth = Math.trunc(extra / numberOfCards) - 1;
			setExtraWidth(tempExtraWidth);
			fetchCenters(token, user, initialConfig.networkId).then(cItems => {
				setCenters(cItems.centers.filter(c => c.isVisible).sort((a, b) => a.name.localeCompare(b.name)));
				fetchSpecialtyTypes(token, user).then(stItems => {
					setMge(stItems.find(s => s.description === 'Médico Cirujano').id);
					setSpecialtyTypes(stItems.filter(st => !st.isDeleted).sort((a, b) => a.description.localeCompare(b.description)));
					fetchSpecialties(token, user).then(sItems => {
						setSpecialties(sItems);
						setAllSpecialties(sItems);
						fetchActiveProfessionals(token, user).then(pItems => {
							var items = pItems.professionals.filter(p => p.professionalTypeId === 1);
							items = items.sort((a, b) => a.lastName.localeCompare(b.lastName));
							setAllProfessionals(items);
							var selecteds = filterProfessionals(searchText, index, selectedCenterId, selectedSpecialtyTypeId, items, sItems);
							setProfessionals(selecteds.professionals);
							setPartialProfessionals(selecteds.partialProfessionals);
						});
					});
				});
			});
		}
	}, [index, centers, loading, searchText, selectedCenterId, selectedSpecialtyTypeId, specialties, token, user, initialConfig.networkId]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(
		debounce(value => {
			setSearchText(value);
			var selecteds = filterProfessionals(value, index, selectedCenterId, selectedSpecialtyTypeId, allProfessionals, allSpecialties);
			setProfessionals(selecteds.professionals);
			setPartialProfessionals(selecteds.partialProfessionals);
			if (index > selecteds.professionals.length) setHasMore(false);
			else setHasMore(true);
		}, 500),
		[searchText],
	);

	const handleSearchChange = (value: string) => {
		debounceSearch(value);
	};

	const handleChangeCenter = value => {
		if (value) {
			setSelectedCenterId(value);
			var selecteds = filterProfessionals(searchText, index, value, selectedSpecialtyTypeId, allProfessionals, allSpecialties);
			setProfessionals(selecteds.professionals);
			setPartialProfessionals(selecteds.partialProfessionals);
			if (index > selecteds.professionals.length) setHasMore(false);
			else setHasMore(true);
		} else {
			setSelectedCenterId(0);
			selecteds = filterProfessionals(searchText, index, 0, selectedSpecialtyTypeId, allProfessionals, allSpecialties);
			setProfessionals(selecteds.professionals);
			setPartialProfessionals(selecteds.partialProfessionals);
			if (index > selecteds.professionals.length) setHasMore(false);
			else setHasMore(true);
		}
	};

	const handleChangeSpecialtyType = value => {
		if (value) {
			setSelectedSpecialtyTypeId(value);
			var selecteds = filterProfessionals(searchText, index, selectedCenterId, value, allProfessionals, specialties);
			setProfessionals(selecteds.professionals);
			setPartialProfessionals(selecteds.partialProfessionals);
			if (index > selecteds.professionals.length) setHasMore(false);
			else setHasMore(true);
		} else {
			setSelectedSpecialtyTypeId(0);
			selecteds = filterProfessionals(searchText, index, selectedCenterId, 0, allProfessionals, allSpecialties);
			setProfessionals(selecteds.professionals);
			setPartialProfessionals(selecteds.partialProfessionals);
			setHasMore(true);
			if (index > selecteds.professionals.length) setHasMore(false);
			else setHasMore(true);
		}
	};

	const handleNext = () => {
		if (!loading) {
			var step = extraItems;
			if (index + extraItems > professionals.length) {
				step = professionals.length - index;
				setHasMore(false);
			}
			setPartialProfessionals(partialProfessionals.concat(professionals.slice(index, index + step)));
			setIndex(index + step);
		}
	};

	return (
		<Layout>
			<Content className="site-layout" style={{ paddingTop: '10px', paddingLeft: '10px', minHeight: '100vh' }}>
				<Col style={{ width: '100%' }}>
					<Row align="middle">
						<Col flex="auto">
							<Space direction="horizontal" wrap>
								<Text ellipsis strong>
									{t('general.searchEngine')}
								</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									onChange={handleSearchChange}
									allowClear
								/>
								<Text ellipsis strong>
									{t('general.filters')}
								</Text>
								<Select
									style={{ width: 300 }}
									showSearch
									optionFilterProp="children"
									onChange={handleChangeSpecialtyType}
									placeholder={t('messages.selectSpecialtyToFilter')}
									allowClear
									options={specialtyTypes
										.filter(st => st.professionalTypeId === 1)
										.map(pt => {
											return {
												key: `professionalType-${pt.id}`,
												value: pt.id,
												label: pt.description,
											};
										})}
								/>
								<Select
									style={{ width: 300 }}
									showSearch
									optionFilterProp="children"
									onChange={handleChangeCenter}
									placeholder={t('messages.selectCenterToFilter')}
									allowClear
									options={centers.map(c => {
										return {
											key: `center-${c.id}`,
											value: c.id,
											label: c.name,
										};
									})}
								/>
							</Space>
						</Col>
						<Col style={{ paddingRight: 10 }}>
							<Space size="middle"></Space>
						</Col>
					</Row>
					<InfiniteScroll
						key="infinite-scroll"
						dataLength={partialProfessionals.length}
						next={handleNext}
						hasMore={hasMore}
						loader={
							<Row justify="center" style={{ padding: 20 }}>
								<Spin key="spin" size="large"></Spin>
							</Row>
						}
						style={{ overflow: 'hidden', paddingTop: 10 }}
					>
						<Row gutter={[gutter, gutter]}>
							<FloatButton.BackTop key="back-top" type="primary" tooltip={t('messages.backToTop')} />
							{partialProfessionals.map((item, index) => {
								var tempSpecialties = specialties.filter(s => s.professionalId === item.id).sort(value => (value.isActive ? -1 : 1));
								var especialtiesTags = (
									<div key={`div-specialties-${item.id}`}>
										{tempSpecialties.length > 0 ? (
											<Row justify="center">
												<Tag style={styles.tag} key={`specialtyType-${item.id}-1`}>
													<Text style={{ ...styles.tag, maxWidth: 200 }} ellipsis>
														{specialtyTypes.find(spt => tempSpecialties[0].specialtyTypeId === spt.id)?.description.toUpperCase()}
													</Text>
												</Tag>
											</Row>
										) : null}
										<Row justify="center">
											{tempSpecialties.length > 1 && tempSpecialties.filter(s => s.specialtyTypeId === mge).length === 0 ? (
												<Tag style={styles.tag} key={`specialtyType-${item.id}-2`}>
													<Text
														style={{ ...styles.tag, maxWidth: 200 }}
														ellipsis={{ tooltip: specialtyTypes.find(spt => tempSpecialties[1].specialtyTypeId === spt.id)?.description.toUpperCase() }}
													>
														{specialtyTypes.find(spt => tempSpecialties[1].specialtyTypeId === spt.id)?.description.toUpperCase()}
													</Text>
												</Tag>
											) : null}
											{tempSpecialties.length > 2 ? (
												<Tag style={styles.tag} key={`specialtyType-${item.id}-3`}>
													{`${tempSpecialties.length - 2}+`}
												</Tag>
											) : null}
										</Row>
									</div>
								);
								var centerTags = (
									<div key={`div-centers-${item.id}`}>
										{item.centers.length > 0 ? (
											<Row justify="center">
												<Tag style={styles.tagAlternative} key={`specialtyType-${item.id}-1`}>
													<Text style={styles.tagAlternative} ellipsis>
														{centers.find(c => item.centers[0] === c.id)?.name.toUpperCase()}
													</Text>
												</Tag>
											</Row>
										) : null}
										<Row justify="center">
											{item.centers.length > 1 ? (
												<Tag style={styles.tagAlternative} key={`specialtyType-${item.id}-2`}>
													<Text style={styles.tagAlternative} ellipsis={{ tooltip: centers.find(c => item.centers[1] === c.id)?.name.toUpperCase() }}>
														{centers.find(c => item.centers[1] === c.id)?.name.toUpperCase()}
													</Text>
												</Tag>
											) : null}
											{item.centers.length > 2 ? (
												<Tag style={styles.tagAlternative} key={`specialtyType-${item.id}-3`}>
													{`${item.centers.length - 2}+`}
												</Tag>
											) : null}
										</Row>
									</div>
								);
								return (
									<Col span={window.innerWidth / cardWidth}>
										<Card size="small" key={`card-${item.id}`} style={{ width: cardWidth + extraWidth, height: cardHeight }}>
											<Row justify="center" style={{ paddingTop: 20 }}>
												{item.avatar !== '' ? (
													<Avatar alt="avatar" key={`avatar-picture-${item.id}`} size={120} src={`${item.avatar}?${Date.now()}`} />
												) : (
													<Avatar alt="avatar" key={`avatar-initials-${item.id}`} size={120} src={item.genderId === 1 ? maleIcon : femaleIcon} />
												)}
											</Row>
											<Row justify="center">
												<Title level={5} style={{ margin: 0 }}>{`${item.names}`}</Title>
											</Row>
											<Row justify="center">
												<Title
													level={4}
													style={{ textAlign: 'center', margin: 0 }}
													ellipsis={{
														rows: 1,
														expandable: false,
														tooltip: true,
													}}
												>{`${item.lastName} ${item.secondLastName}`}</Title>
											</Row>
											<Divider style={{ height: 15 }}>
												<img key={`img-${item.id}`} style={{ height: 25 }} src={specialtiesIcon} alt="specialties"></img>
											</Divider>
											<Row justify="center" style={{ height: 30 }}>
												<Space key={`space-${index}`} direction="vertical" size="small">
													{especialtiesTags}
												</Space>
											</Row>
											<Divider style={{ height: 15 }}>
												<EnvironmentOutlined />
											</Divider>
											<Row justify="center" style={{ height: 40 }}>
												<Space direction="vertical" size="small">
													{centerTags}
												</Space>
											</Row>
										</Card>
									</Col>
								);
							})}
						</Row>
					</InfiniteScroll>
				</Col>
			</Content>
		</Layout>
	);
};

export default PageProfessionalsPhysicians;
