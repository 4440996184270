import { useEffect } from 'react';
import { Col, Row, Space, Card, Layout, Typography, ConfigProvider, theme } from 'antd';
import { AuthenticationGoogle, AuthRoutes, LogoBig, NonAuthRoutes } from '../../components';
import { useTranslation } from 'react-i18next';
import { colors } from '../../assets/styles';
import { useAppConfigContext } from '../../utils/useAppConfigContext';
import { locales } from '../../App';
import { useThemeDetector } from '../../utils/useThemeDetector';
import background from '../../assets/images/background-redsalud-santiago.jpg';

const { Content } = Layout;
const { Text } = Typography;

const PageLogin = () => {
	const { t } = useTranslation();
	const themeDetector = useThemeDetector();
	const { initialConfig, setIsDarkMode } = useAppConfigContext();
	document.title = initialConfig.systemName;
	document.body.style.background = themeDetector ? 'black' : 'white';
	useEffect(() => {
		setIsDarkMode(themeDetector);
	}, [setIsDarkMode, themeDetector]);

	return (
		<ConfigProvider
			locale={locales[initialConfig.language]}
			theme={{
				token: { colorPrimary: colors.brandColor1, colorSuccess: colors.brandColor2 },
				algorithm: themeDetector ? theme.darkAlgorithm : theme.defaultAlgorithm,
				components: {
					Input: {
						colorTextQuaternary: themeDetector ? '#141414' : '#fff',
						algorithm: true,
					},
					DatePicker: { colorTextQuaternary: themeDetector ? '#141414' : '#fff', algorithm: true },
				},
			}}
		>
			<Layout style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
				<Content>
					<Col>
						<Row style={{ height: '20vh' }}></Row>
						<Row style={{ height: '30vh' }}>
							<Col flex={1}></Col>
							<Col>
								<Card
									title={
										t('messages.startPage') + ' (' + initialConfig.version + ' - ' + process.env.REACT_APP_ENVIRONMENT + ' - ' + initialConfig.language + ')'
									}
									style={{ minWidth: 640, width: '30vw', height: '100%' }}
									styles={{
										header: { backgroundColor: colors.brandColor1, color: colors.backgroundColor },
									}}
								>
									<Row style={{ height: 20 }} />
									<Row align="middle" justify="center">
										<Col flex="auto">
											<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
												<LogoBig />
											</Row>
										</Col>
									</Row>
									<Row style={{ height: 80 }} />
									<Row style={{ height: 20 }} />
									<Row style={{ width: '100%' }} justify="center">
										<Space direction="vertical" size={15}>
											<AuthenticationGoogle route={AuthRoutes.loading} />
											<AuthenticationGoogle route={NonAuthRoutes.downloadApp} text={t('messages.downloadApp')} />
										</Space>
									</Row>
									<Row style={{ height: 20 }} />
									<Row style={{ height: 20 }}>
										<Text style={{ textAlign: 'center', width: '100vh' }}>{` ${t(
											'general.createdBy',
										)} Dr. Francisco Fukuda - Dirección de Informática Médica`}</Text>
									</Row>
								</Card>
							</Col>
							<Col flex={1}></Col>
						</Row>
						<Row style={{ height: '50vh' }}></Row>
					</Col>
				</Content>
			</Layout>
		</ConfigProvider>
	);
};

export default PageLogin;
