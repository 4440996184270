import { useState, ReactNode, useEffect } from 'react';
import { Phone, PhoneNumberType, initialPhoneValue } from '../../store/types';
import { useStore } from 'react-redux';
import { phones as phonesReducer } from '../../store/reducers';
import { Table, Button, Space, Tooltip, Typography, message, Popconfirm, Card } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { phoneNumberTypesSelectors, phonesSelectors } from '../../store/selectors';
import FormProfessionalPhoneEdit from './form-professional-phone-edit';
import { deletePhone, fetchProfessionalPhonesById } from '../../store/api';
import { useTranslation } from 'react-i18next';
import { baseStyles } from '../../assets/styles';
import { useAppConfigContext } from '../../utils';

const key = 'saving';
const { Text } = Typography;

const FormProfessionalPhones = ({ professionalId }) => {
	var id = professionalId;
	const store = useStore();
	const { t } = useTranslation();
	const styles = baseStyles;
	const { token, user } = useAppConfigContext();
	const [phoneNumberTypes, setPhoneNumberTypes] = useState<PhoneNumberType[]>([]);
	const [phones, setPhones] = useState<Phone[]>([]);
	const [phone, setPhone] = useState<Phone>({ ...initialPhoneValue, professionalId: id });
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setPhoneNumberTypes(phoneNumberTypesSelectors.selectAll(store.getState() as any));
		setIsLoading(true);
		fetchProfessionalPhonesById(professionalId, token, user).then(items => {
			store.dispatch(phonesReducer.actions.phonesLoading());
			store.dispatch(phonesReducer.actions.phonesReceived(items));
			setPhones(items.filter(s => !s.isDeleted));
			setIsLoading(false);
		});
	}, [professionalId, store, token, user]);

	const handleEdit = (item: Phone) => {
		setModalTitle(t('professionals.editPhone'));
		setPhone(item);
		setIsModalVisible(true);
	};

	const handleAdd = () => {
		setModalTitle(t('professionals.addPhone'));
		setPhone({ ...initialPhoneValue, professionalId: id });
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setPhones(phonesSelectors.selectAll(store.getState() as any).filter(e => e.professionalId === id && !e.isDeleted));
	};

	const handleDelete = async (item: Phone) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deletePhone(item, token, user);
		if (response) {
			setIsLoading(true);
			fetchProfessionalPhonesById(professionalId, token, user).then(items => {
				store.dispatch(phonesReducer.actions.phonesLoading());
				store.dispatch(phonesReducer.actions.phonesReceived(items));
				setPhones(items.filter(s => !s.isDeleted));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const columns: ColumnProps<Phone>[] = [
		{
			title: t('professionals.phoneNumber'),
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
		},
		{
			title: t('professionals.phoneType'),
			dataIndex: 'phoneNumberType',
			key: 'phoneNumberType',
			ellipsis: true,
			width: 120,
			render: (_text: string, record: Phone): ReactNode => <Text>{phoneNumberTypes?.find(e => e.id === record?.phoneNumberTypeId)?.description}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Phone): ReactNode => {
				return (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button type="primary" shape="round" onClick={() => handleEdit(item)}>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round" icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.phones')}
			style={styles.card}
			extra={<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAdd}></Button>}
		>
			<Table<Phone> rowKey={record => record.id} tableLayout="auto" size="small" dataSource={phones} columns={columns} pagination={false} loading={isLoading} />
			<FormProfessionalPhoneEdit title={modalTitle} open={isModalVisible} phoneId={phone.id} professionalId={id} onClose={handleClose} />
		</Card>
	);
};
export default FormProfessionalPhones;
