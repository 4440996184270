import { createContext, ReactNode, useContext, useState } from 'react';
import { initialUserValue, User } from '../store/types';
import { AppConfigProps, AppConfigProviderProps } from '../types/app';

const AppConfigContext = createContext<AppConfigProps | undefined>(undefined);

export const useAppConfigContext = (): AppConfigProps => {
	const context = useContext(AppConfigContext);
	if (!context) {
		throw new Error('useAppContext must be used within an AppProvider');
	}
	return context;
};

export const AppConfigProvider: React.FC<{ config: AppConfigProviderProps; children: ReactNode }> = ({ config, children }) => {
	const [initialConfig, setInitialConfig] = useState<AppConfigProviderProps>(config);
	const [country, setCountry] = useState<string>('');
	const [token, setToken] = useState<string>('');
	const [user, setUser] = useState<User>(initialUserValue);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

	return (
		<AppConfigContext.Provider
			value={{
				initialConfig,
				country,
				token,
				user,
				isLoading,
				isDarkMode,
				setCountry,
				setInitialConfig,
				setToken,
				setUser,
				setIsLoading,
				setIsDarkMode,
			}}
		>
			{children}
		</AppConfigContext.Provider>
	);
};
