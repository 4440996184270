import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { DatePicker, Input, Card, Button, message, Form, Select, Alert, Row, Col, Switch, Typography, Space, InputNumber } from 'antd';
import { Professional, Gender, initialProfessionalValue, Country, ParentType, Institution, SpecialtyType } from '../../store/types';
import { gendersSelectors, countriesSelectors, institutionsSelectors, specialtyTypesSelectors } from '../../store/selectors';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import { DownCircleOutlined } from '@ant-design/icons';
import { dateFormat } from '../../assets/formats';
import { baseStyles } from '../../assets/styles';
import { SizedModal, UploadDragPaste } from '../../components';
import { useTranslation } from 'react-i18next';
import { convertHtmlToDraft, convertDraftToHtml } from '../../utils/util-drafts';
import { includes, isValidRut, objectsAreEquals } from '../../utils/util-custom-validators';
import { fetchProfessional, getProfessionalData, professionalExists } from '../../store/api/professional';
import { NormalizeName } from '../../utils/util-formatId';
import { useAppConfigContext } from '../../utils/useAppConfigContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import locale from 'antd/es/date-picker/locale/es_ES';

dayjs.extend(customParseFormat);

const { Option } = Select;
const { Search, TextArea } = Input;
const { Text } = Typography;

const key = 'saving';

const FormProfessionalDetails = ({ professionalId, professionalTypeId, setProfessionalData, setHasErrors, setIsChanged }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const { isDarkMode, token, user, country } = useAppConfigContext();
	const [formProfessionalDetails] = Form.useForm();
	const [formTemplate] = Form.useForm();
	const [modalValue, setModalValue] = useState<string>('');
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [originalProfessional, setOriginalProfessional] = useState<Professional>(initialProfessionalValue);
	const [professional, setProfessional] = useState<Professional>(initialProfessionalValue);
	const [genders, setGenders] = useState<Gender[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [institutions, setInstitutions] = useState<Institution[]>([]);
	const [specialtyTypes, setSpecialtyTypes] = useState<SpecialtyType[]>([]);
	const [imageUrl, setImageUrl] = useState<string>('');
	const [editorState, setEditorState] = useState<EditorState>();
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isTemplateModalVisible, setIsTemplateModalVisible] = useState<boolean>(false);
	const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false);
	const [templateText, setTemplateText] = useState<string>('');
	const [templateData, setTemplateData] = useState<object>({
		subSpecialtyId: undefined,
		subSpecialtyInstitutionId: undefined,
		subSpecialtyYear: undefined,
		specialtyId: undefined,
		specialtyInstitutionId: undefined,
		specialtyYear: undefined,
		undergraduateInstitutionId: undefined,
		undergraduateYear: undefined,
		eunacom: undefined,
		experienceYear: undefined,
	});

	useEffect(() => {
		const handleTemplateDataChange = (data: any, institutionsItems: Institution[], specialtyTypeItems: SpecialtyType[]) => {
			var temp = '';
			if (data.subSpecialtyId && data.subSpecialtyInstitutionId && data.subSpecialtyYear)
				temp +=
					'Subespecialista en ' +
					specialtyTypeItems.find(s => s.id === data.subSpecialtyId)?.description +
					' otorgado por ' +
					institutionsItems.find(i => i.id === data.subSpecialtyInstitutionId)?.description +
					', ' +
					data.subSpecialtyYear +
					'.';
			if (data.specialtyId && data.specialtyInstitutionId && data.specialtyYear)
				temp +=
					' *Especialista en ' +
					specialtyTypeItems.find(s => s.id === data.specialtyId)?.description +
					' otorgado por ' +
					institutionsItems.find(i => i.id === data.specialtyInstitutionId)?.description +
					', ' +
					data.specialtyYear +
					'.';
			if (data.undergraduateInstitutionId && data.undergraduateYear)
				temp +=
					' *Médico Cirujano de la ' + institutionsItems.find(i => i.id === data.undergraduateInstitutionId)?.description + ', ' + data.undergraduateYear + '.';
			if (data.eunacom) temp += ' Cuenta con Examen Único Nacional de Conocimientos de Medicina aprobado';
			if (data.experienceYear) temp += ', con más de ' + data.experienceYear + ' años de experiencia';
			temp += '.';
			setTemplateText(temp);
		};
		var institutionsItems = institutionsSelectors.selectAll(store.getState() as any);
		setInstitutions(institutionsItems);
		var specialtyTypeItems = specialtyTypesSelectors.selectAll(store.getState() as any);
		setSpecialtyTypes(specialtyTypeItems);
		var genderItems = gendersSelectors.selectAll(store.getState() as any);
		setGenders(genderItems);
		var countriesItems = countriesSelectors.selectAll(store.getState() as any);
		setCountries(countriesSelectors.selectAll(store.getState() as any));
		fetchProfessional(professionalId, token, user).then(item => {
			setOriginalProfessional(
				item || {
					...initialProfessionalValue,
					professionalTypeId: professionalTypeId,
					nationalityId: countriesItems.find(c => c.code === country)?.id,
				},
			);
			setOriginalProfessional(item);
			setEditorState(convertHtmlToDraft(item.review));
			setProfessional(item);
			setImageUrl(item.avatar);
			formProfessionalDetails.setFieldsValue({
				names: item.names,
				lastName: item.lastName,
				secondLastName: item.secondLastName,
				gender: genderItems.find(g => g.id === item.genderId)?.description || '',
				birthDate: dayjs(item.birthDate || new Date()),
				socialId: item.socialId,
				nationality: countriesItems.find(c => c.id === item.nationalityId)?.description || countriesItems.find(c => c.code === country)?.description,
				isActive: item.isActive,
				modifiedBy: item.modifiedBy,
			});
		});
		handleTemplateDataChange(templateData, institutionsItems, specialtyTypeItems);
	}, [country, formProfessionalDetails, professionalId, professionalTypeId, store, templateData, templateText, token, user]);

	const setAvatarUrl = (url: string) => {
		setIsChanged(true);
		setImageUrl(url);
		setProfessional({ ...professional, avatar: url });
		setProfessionalData({ ...professional, avatar: url });
	};

	const handleDetailsChange = (editorState: EditorState) => {
		var item = professional;
		setEditorState(editorState);
		item = { ...professional, review: convertDraftToHtml(editorState) };
		setIsChanged(!objectsAreEquals(originalProfessional, item));
		setProfessional(item);
		setProfessionalData(item);
	};

	const handleShowModal = async () => {
		setIsModalVisible(true);
		setModalValue(professional.socialId);
	};

	const handleNormalizeName = async () => {
		formProfessionalDetails.setFieldsValue({
			names: NormalizeName(formProfessionalDetails.getFieldValue('names')),
			lastName: NormalizeName(formProfessionalDetails.getFieldValue('lastName')),
			secondLastName: NormalizeName(formProfessionalDetails.getFieldValue('secondLastName')),
		});
	};

	const handleUseTemplate = () => {
		setIsTemplateModalVisible(true);
	};

	const handleGetDataFromSupersalud = async value => {
		try {
			setIsModalVisible(false);
			message.loading({ content: t('messages.fetchingData'), key, duration: 0 });
			var result = await getProfessionalData(value, token, user);
			if (result !== undefined) {
				var name = result.nombre_completo.split(' ');
				formProfessionalDetails.setFieldsValue({
					names: result.nombre_completo,
					lastName: name[name.length - 2],
					secondLastName: name[name.length - 1],
					gender: result.sexo,
					birthDate: dayjs(result.fecha_nacimiento),
					socialId: result.rut,
				});
				setProfessional({
					...professional,
					names: result.nombre_completo,
					lastName: name[name.length - 2],
					secondLastName: name[name.length - 1],
					gender: result.sexo,
					birthDate: dayjs(result.fecha_nacimiento, 'DD/MM/YYYY', true).toString(),
					socialId: result.rut,
				});
				setProfessionalData({
					...professional,
					names: result.nombre_completo,
					lastName: name[name.length - 2],
					secondLastName: name[name.length - 1],
					gender: result.sexo,
					birthDate: dayjs(result.fecha_nacimiento, 'DD/MM/YYYY', true).toString(),
					socialId: result.rut,
				});
				var review = convertDraftToHtml(editorState);
				review = '';
				result.especialidades.forEach(element => {
					review = review + '<p>' + element + '</p>';
				});
				review = review + '<p>N° de registro ' + result.numero_sis + '</p>';
				setIsValidData(true);
				setEditorState(convertHtmlToDraft(review));
				message.success({ content: t('messages.dataFetched'), key, duration: 2 });
				setIsChanged(true);
			} else message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		}
	};

	const handleValuesChange = (changedValues: any) => {
		var item = professional;
		if (changedValues['socialId']) {
			var value = changedValues['socialId'];
			value = value.replace(/\./g, '');
			formProfessionalDetails.setFieldsValue({
				socialId: value.toUpperCase(),
			});
			item = { ...professional, socialId: value.toUpperCase() };
		}
		if (changedValues['names'] !== undefined) {
			item = { ...professional, names: changedValues['names'] };
		}
		if (changedValues['lastName'] !== undefined) {
			item = { ...professional, lastName: changedValues['lastName'] };
		}
		if (changedValues['secondLastName'] !== undefined) {
			item = { ...professional, secondLastName: changedValues['secondLastName'] };
		}
		if (changedValues['birthDate'] !== undefined) {
			item = { ...professional, birthDate: changedValues['birthDate'].toDate() };
		}
		if (changedValues['isActive'] !== undefined) {
			item = { ...professional, isActive: changedValues['isActive'] };
		}
		if (changedValues['gender'] !== undefined) {
			item = { ...professional, genderId: changedValues['gender'] };
		}
		if (changedValues['nationality'] !== undefined) {
			item = { ...professional, nationalityId: changedValues['nationality'] };
		}
		setIsChanged(!objectsAreEquals(originalProfessional, item) || originalProfessional.isActive !== changedValues['isActive']);
		setProfessional(item);
		setProfessionalData(item);
		setTimeout(() => {
			formProfessionalDetails
				.validateFields()
				.then(() => {
					setHasErrors(false);
				})
				.catch(errors => {
					if (errors.errorFields.length > 0) setHasErrors(true);
				});
		});
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col lg={24} xl={12}>
					<Card
						size="small"
						title={t('professionals.details')}
						style={styles.card}
						extra={[
							<Space>
								<Button style={styles.buttonCard} type="primary" icon={<DownCircleOutlined />} shape="round" onClick={handleShowModal}>
									{t('professionals.getDataFromSuperSalud')}
								</Button>
								<Button style={styles.buttonCard} type="primary" shape="round" onClick={handleNormalizeName}>
									{t('professionals.normalizeName')}
								</Button>
							</Space>,
						]}
					>
						<Form {...baseStyles.formLayout} form={formProfessionalDetails} onValuesChange={handleValuesChange} name="professional-details">
							<Form.Item label={t('general.names')} name="names" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.lastname')} name="lastName" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.secondLastname')} name="secondLastName" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Input />
							</Form.Item>
							<Form.Item label={t('general.birthdate')}>
								<Row gutter={8}>
									<Col>
										<Form.Item name="birthDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
											<DatePicker locale={locale} format={dateFormat} />
										</Form.Item>
									</Col>
									<Col>
										<Space>
											<Text>{t('general.age') + ':'}</Text>
											<Input
												style={{ color: isDarkMode ? 'white' : 'black' }}
												disabled
												value={dayjs(new Date()).diff(professional.birthDate, 'year') + ' ' + t('general.years')}
											/>
										</Space>
									</Col>
								</Row>
							</Form.Item>
							<Form.Item label={t('general.gender')} name="gender" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select>
									{genders.map(g => {
										return (
											<Option key={`gender-${g.id}`} value={g.id}>
												{g.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item
								label={t('general.socialId')}
								name="socialId"
								rules={[
									{ required: true, message: t('messages.enterValue') },
									{
										validator: async (_, value) => {
											if (!isValidRut(value)) return Promise.reject(new Error(t('messages.errorSocialId')));
											else if (await professionalExists(value, professionalId, token, user)) return Promise.reject(new Error(t('messages.duplicateSocialId')));
											else return Promise.resolve();
										},
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item label={t('professionals.countryOfNationality')} name="nationality" rules={[{ required: true, message: t('messages.enterValue') }]}>
								<Select
									showSearch
									allowClear
									optionFilterProp="children"
									filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
								>
									{countries.map(item => (
										<Option key={`country-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label={t('professionals.isActive')} name="isActive" valuePropName="checked">
								<Switch />
							</Form.Item>
							<Form.Item label={t('professionals.image')}>
								<UploadDragPaste id={professionalId} parentType={ParentType.professional} url={imageUrl} setUrl={setAvatarUrl} size="medium" />
							</Form.Item>
							<Text strong>{t('general.lastModification') + ': ' + professional.modifiedBy}</Text>
							{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
						</Form>
					</Card>
				</Col>
				<Col lg={24} xl={12}>
					<Card
						size="small"
						title={t('professionals.review')}
						style={styles.card}
						extra={[
							<Button style={styles.buttonCard} type="primary" shape="round" onClick={handleUseTemplate}>
								{t('professionals.useTemplate')}
							</Button>,
						]}
					>
						<Editor
							editorStyle={{ height: 500 }}
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={handleDetailsChange}
						/>
					</Card>
				</Col>
			</Row>
			<SizedModal
				title={t('professionals.getDataFromSuperSalud')}
				open={isModalVisible}
				okText={t('general.get')}
				cancelText={t('general.cancel')}
				onOk={() => {
					handleGetDataFromSupersalud(modalValue);
				}}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				size="xsmall"
			>
				<Row align="middle">
					<Search
						addonBefore={t('general.socialId')}
						onSearch={value => {
							if (!isValidRut(value)) setIsModalVisible(false);
							else handleGetDataFromSupersalud(value);
						}}
						value={modalValue}
						onChange={event => {
							setModalValue(event.target.value);
							if (!isValidRut(event.target.value)) setIsErrorMessageVisible(true);
							else setIsErrorMessageVisible(false);
						}}
					/>
				</Row>
				<Row>
					{isErrorMessageVisible ? (
						<Col>
							<Text type="warning">{t('messages.enterValidValue')}</Text>
						</Col>
					) : null}
				</Row>
			</SizedModal>
			<SizedModal
				title={t('professionals.useTemplate')}
				open={isTemplateModalVisible}
				okText={t('general.ok')}
				cancelText={t('general.cancel')}
				onOk={() => {
					setEditorState(convertHtmlToDraft(templateText));
					setProfessional({ ...professional, review: templateText });
					setProfessionalData({ ...professional, review: templateText });
					setIsTemplateModalVisible(false);
				}}
				onCancel={() => {
					setIsTemplateModalVisible(false);
				}}
				size="large"
			>
				<Form {...baseStyles.formLayout} form={formTemplate} name="formTemplate-details">
					<Col>
						<Space direction="vertical">
							<Space.Compact>
								<Input style={{ width: 180 }} value={t('professionals.subSpecialtyInfo')} disabled></Input>
								<Select
									style={{ width: 300 }}
									showSearch
									allowClear
									optionFilterProp="children"
									onChange={value =>
										value ? setTemplateData({ ...templateData, subSpecialtyId: value }) : setTemplateData({ ...templateData, subSpecialtyId: undefined })
									}
									filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
								>
									{specialtyTypes.map(item => (
										<Option key={`specialtyType-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
								<Select
									style={{ width: 300 }}
									showSearch
									allowClear
									optionFilterProp="children"
									onChange={value =>
										value
											? setTemplateData({ ...templateData, subSpecialtyInstitutionId: value })
											: setTemplateData({ ...templateData, subSpecialtyInstitutionId: undefined })
									}
									filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
								>
									{institutions.map(item => (
										<Option key={`institutions-ss-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
								<DatePicker
									picker="year"
									onChange={value =>
										value
											? setTemplateData({ ...templateData, subSpecialtyYear: value.year() })
											: setTemplateData({ ...templateData, subSpecialtyYear: undefined })
									}
								></DatePicker>
							</Space.Compact>
							<Space.Compact>
								<Input style={{ width: 180 }} value={t('professionals.specialtyInfo')} disabled></Input>
								<Select
									style={{ width: 300 }}
									showSearch
									allowClear
									optionFilterProp="children"
									onChange={value =>
										value ? setTemplateData({ ...templateData, specialtyId: value }) : setTemplateData({ ...templateData, specialtyId: undefined })
									}
									filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
								>
									{specialtyTypes.map(item => (
										<Option key={`specialtyType-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
								<Select
									style={{ width: 300 }}
									showSearch
									allowClear
									optionFilterProp="children"
									onChange={value =>
										value
											? setTemplateData({ ...templateData, specialtyInstitutionId: value })
											: setTemplateData({ ...templateData, specialtyInstitutionId: undefined })
									}
									filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
								>
									{institutions.map(item => (
										<Option key={`institutions-s-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
								<DatePicker
									picker="year"
									onChange={value =>
										value ? setTemplateData({ ...templateData, specialtyYear: value.year() }) : setTemplateData({ ...templateData, specialtyYear: undefined })
									}
								></DatePicker>
							</Space.Compact>
							<Space.Compact>
								<Input style={{ width: 180 }} value={t('professionals.undergraduateInfo')} disabled></Input>
								<Select
									style={{ width: 300 }}
									showSearch
									allowClear
									optionFilterProp="children"
									onChange={value =>
										value
											? setTemplateData({ ...templateData, undergraduateInstitutionId: value })
											: setTemplateData({ ...templateData, undergraduateInstitutionId: undefined })
									}
									filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
								>
									{institutions.map(item => (
										<Option key={`institutions-u-${item.id}`} value={item.id}>
											{item.description}
										</Option>
									))}
								</Select>
								<DatePicker
									picker="year"
									onChange={value =>
										value
											? setTemplateData({ ...templateData, undergraduateYear: value.year() })
											: setTemplateData({ ...templateData, undergraduateYear: undefined })
									}
								></DatePicker>
							</Space.Compact>
							<Space.Compact>
								<Input style={{ width: 180 }} value={t('professionals.eunacomInfo')} disabled></Input>
								<Switch
									onChange={value => (value ? setTemplateData({ ...templateData, eunacom: value }) : setTemplateData({ ...templateData, eunacom: undefined }))}
								></Switch>
							</Space.Compact>
							<Space.Compact>
								<Input style={{ width: 180 }} value={t('professionals.experienceInfo')} disabled></Input>
								<InputNumber
									defaultValue={1}
									min={0}
									max={70}
									onChange={value =>
										value ? setTemplateData({ ...templateData, experienceYear: value }) : setTemplateData({ ...templateData, experienceYear: undefined })
									}
								></InputNumber>
							</Space.Compact>
							<TextArea autoSize value={templateText}></TextArea>
						</Space>
					</Col>
				</Form>
			</SizedModal>
		</div>
	);
};
export default FormProfessionalDetails;
