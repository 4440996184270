import { ReactNode, useState, useEffect } from 'react';
import { Person, initialPersonValue, Gender, Country, Email, EmailType, Phone, PhoneNumberType, Address, AddressType, Commune } from '../../store/types';
import { AuthRoutes, SizedModal } from '../../components';
import { Breadcrumb, Button, Card, Col, DatePicker, Form, Input, Layout, message, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from 'antd';
import { useStore } from 'react-redux';
import { persons as personsReducer } from '../../store/reducers';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { personsSelectors } from '../../store/selectors/management';
import { SaveOutlined, DownCircleOutlined, PlusOutlined, DeleteOutlined, EditOutlined, ApartmentOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { dateFormat, baseStyles, colors } from '../../assets';
import {
	addressTypesSelectors,
	communesSelectors,
	countriesSelectors,
	emailTypesSelectors,
	gendersSelectors,
	phoneNumberTypesSelectors,
} from '../../store/selectors';
import { ColumnProps } from 'antd/lib/table';
import { urls } from '../../store/api';
import { includes, isValidRut, objectsAreEquals, useAppConfigContext } from '../../utils';
import { createUpdatePerson, parsePerson, personExists } from '../../store/api/management';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';

const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;
const { Content } = Layout;

const key = 'saving';

const PagePersonDetails = () => {
	const store = useStore();
	const location = useLocation();
	const params = location.state as any;
	const personId: number = parseInt(params.id);
	const styles = baseStyles;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [formPersonDetails] = Form.useForm();
	const [title, setTitle] = useState<string>('');
	const [person, setPerson] = useState<Person>();
	const [persons, setPersons] = useState<Person[]>();
	const [genders, setGender] = useState<Gender[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [communes, setCommunes] = useState<Commune[]>([]);
	const [emailTypes, setEmailTypes] = useState<EmailType[]>([]);
	const [phoneNumberTypes, setPhoneNumberTypes] = useState<PhoneNumberType[]>([]);
	const [addressTypes, setAddressTypes] = useState<AddressType[]>([]);
	const [span, setSpan] = useState<number>(24);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [originalPerson, setOriginalPerson] = useState<Person>(initialPersonValue);
	const [changed, setChanged] = useState<boolean>(false);
	const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false);
	const [modalValue, setModalValue] = useState<string>('');

	window.onresize = () => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
	};

	useEffect(() => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
		setPersons(personsSelectors.selectAll(store.getState() as any));
		var item = personsSelectors.selectById(store.getState() as any, personId) || initialPersonValue;
		setPerson(item);
		setGender(gendersSelectors.selectAll(store.getState() as any));
		setCountries(countriesSelectors.selectAll(store.getState() as any));
		setCommunes(communesSelectors.selectAll(store.getState() as any));
		setEmailTypes(emailTypesSelectors.selectAll(store.getState() as any));
		setPhoneNumberTypes(phoneNumberTypesSelectors.selectAll(store.getState() as any));
		setAddressTypes(addressTypesSelectors.selectAll(store.getState() as any));
		setTitle(`${item.names} ${item.lastName} ${item.secondLastName}`);
		setOriginalPerson(item);
		formPersonDetails.setFieldsValue({
			names: item.names,
			lastName: item.lastName,
			secondLastName: item.secondLastName,
			gender: item.genderId,
			birthDate: dayjs(item.birthDate || new Date()),
			socialId: item.socialId,
			nationality: item.nationalityId,
		});
	}, [countries, formPersonDetails, genders, personId, store]);

	const isSocialIdDuplicate = (socialId: string): boolean => {
		return persons.find(p => p.socialId === socialId && !p.isDeleted) === undefined ? false : true;
	};

	const handleGetDataFromAgenda = async value => {
		setIsModalVisible(false);
		message.loading({ content: t('messages.fetchingData'), key, duration: 0 });
		var bearer = 'Bearer ' + token;
		const requestOptions = {
			method: 'GET',
			headers: {
				Authorization: `${bearer}`,
				Username: `${user?.username}`,
			},
		};
		var response = await fetch(`${urls.getPersonDataFromAgenda}?socialId=${value}`, requestOptions);
		if (response.ok) {
			var data = await response.json();
			var jsonObject: any = data;
			var item = parsePerson(jsonObject);
			setPerson(item);
			setTitle(`${item.names} ${item.lastName} ${item.secondLastName}`);
			formPersonDetails.setFieldsValue({
				names: item.names,
				lastName: item.lastName,
				secondLastName: item.secondLastName,
				gender: item.genderId,
				birthDate: dayjs(item.birthDate || new Date()),
				socialId: item.socialId,
				nationality: countries.find(c => c.code === 'CL')?.id || 0,
			});
			message.info({ content: t('messages.dataFetched'), key, duration: 2 });
		} else {
			message.info({ content: t('messages.personNotFound'), key, duration: 2 });
		}
		setChanged(!objectsAreEquals(originalPerson, item));
	};

	const handlePersonSave = async () => {
		try {
			const values = await formPersonDetails.validateFields();
			if (values.errorField === undefined) {
				if (isSocialIdDuplicate(values.socialId)) {
					message.error({ content: t('messages.duplicateSocialId'), key, duration: 2 });
					return;
				} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
				var temp: Person = {
					...person,
					names: values.names,
					lastName: values.lastName,
					secondLastName: values.secondLastName,
					genderId: values.gender,
					birthDate: values.birthDate.toDate(),
					socialId: values.socialId,
					nationalityId: values.nationality,
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdatePerson(temp, token, user);
				if (response !== undefined) {
					store.dispatch(personsReducer.actions.personsCreateUpdate(response));
					message.info({ content: t('messages.dataSaved'), key, duration: 2 });
					setPerson(response);
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleAddEmail = async () => {};

	const handleAddAddress = async () => {};

	const handleAddPhone = async () => {};

	const handleValuesChange = (changedValues: any) => {
		var item = person;
		if (changedValues['names'])
			setTitle(`${changedValues['names']} ${formPersonDetails.getFieldValue('lastName')} ${formPersonDetails.getFieldValue('secondLastName')}`);
		if (changedValues['lastName'])
			setTitle(`${formPersonDetails.getFieldValue('names')} ${changedValues['lastName']} ${formPersonDetails.getFieldValue('secondLastName')}`);
		if (changedValues['secondLastName'])
			setTitle(`${formPersonDetails.getFieldValue('names')} ${formPersonDetails.getFieldValue('lastName')} ${changedValues['secondLastName']}`);
		setChanged(!objectsAreEquals(originalPerson, item));
	};

	const columnsPhone: ColumnProps<Phone>[] = [
		{
			title: t('professionals.phoneNumber'),
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
		},
		{
			title: t('professionals.phoneType'),
			dataIndex: 'phoneNumberType',
			key: 'phoneNumberType',
			ellipsis: true,
			width: 120,
			render: (_text: string, record: Phone): ReactNode => <Text>{phoneNumberTypes?.find(e => e.id === record?.phoneNumberTypeId)?.description}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Phone): ReactNode => {
				return (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button type="primary" shape="round" onClick={() => {}}>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm title={t('messages.confirmDeleteItem')} onConfirm={() => {}} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round" icon={<DeleteOutlined />} />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsEmails: ColumnProps<Email>[] = [
		{
			title: t('professionals.emailUrl'),
			dataIndex: 'url',
			key: 'url',
		},
		{
			title: t('professionals.emailType'),
			dataIndex: 'emailType',
			key: 'emailType',
			ellipsis: true,
			width: 120,
			render: (_text: string, item: Email): ReactNode => <Text>{emailTypes?.find(e => e.id === item?.emailTypeId)?.description}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Email): ReactNode => {
				return (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button type="primary" shape="round" onClick={() => {}}>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm title={t('messages.confirmDeleteItem')} onConfirm={() => {}} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round">
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsAddress: ColumnProps<Address>[] = [
		{
			title: t('professionals.address'),
			dataIndex: 'street',
			render: (_text: string, item: Address): ReactNode => (
				<Text>
					{item.street} {item.number} {item.other ? item.other : ''}
				</Text>
			),
		},
		{
			title: t('professionals.commune'),
			dataIndex: 'other',
			key: 'other',
			ellipsis: true,
			render: (_text: string, item: Address): ReactNode => <Text>{communes?.find(c => c.id === item.communeId)?.description}</Text>,
		},
		{
			title: t('professionals.addressType'),
			dataIndex: 'addressType',
			key: 'addressType',
			ellipsis: true,
			width: 120,
			render: (_text: string, item: Address): ReactNode => <Text>{addressTypes?.find(a => a.id === item?.addressTypeId)?.description}</Text>,
			responsive: ['md'],
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Address): ReactNode => {
				return (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button type="primary" shape="round" onClick={() => {}}>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm title={t('messages.confirmDeleteItem')} onConfirm={() => {}} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Tooltip title={t('general.delete')}>
								<Button danger shape="round">
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.persons') },
								{ title: title },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							{changed ? (
								<Popconfirm
									title={t('messages.confirmExit')}
									onConfirm={() => {
										navigate(AuthRoutes.persons + '/index');
									}}
									okText={t('general.ok')}
									cancelText={t('general.cancel')}
								>
									<Tooltip title={t('general.back')}>
										<Button icon={<ArrowLeftOutlined />} type="primary" shape="round">
											{t('general.back')}
										</Button>
									</Tooltip>
								</Popconfirm>
							) : (
								<Tooltip title={t('general.back')}>
									<Button icon={<ArrowLeftOutlined />} type="primary" shape="round" onClick={() => navigate(AuthRoutes.persons + '/index')}>
										{t('general.back')}
									</Button>
								</Tooltip>
							)}
							,
							<Button
								key="button-getDataFromAgenda"
								type="primary"
								icon={<DownCircleOutlined />}
								shape="round"
								onClick={() => {
									setIsModalVisible(true);
								}}
							>
								{t('management.getDataFromAgenda')}
							</Button>
							,
							<Button key="button-savePerson" type="primary" disabled={!changed} shape="round" icon={<SaveOutlined />} onClick={handlePersonSave}>
								{t('general.save')}
							</Button>
						</Space>
					</Col>
				</Row>

				<Content className="site-layout-background">
					<Row>
						<Col span={span}>
							<Card size="small" title={t('management.details')} style={styles.card}>
								<Form {...baseStyles.formLayout} form={formPersonDetails} name="person-details" onValuesChange={handleValuesChange}>
									<Form.Item label={t('general.names')} name="names" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('general.lastname')} name="lastName" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('general.secondLastname')} name="secondLastName" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Input />
									</Form.Item>
									<Form.Item label={t('general.birthdate')} name="birthDate" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<DatePicker locale={locale} format={dateFormat} />
									</Form.Item>
									<Form.Item label={t('general.gender')} name="gender" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{genders.map(g => {
												return (
													<Option key={`gender-${g.id}`} value={g.id}>
														{g.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item
										label={t('general.socialId')}
										name="socialId"
										rules={[
											{ required: true },
											{
												validator: async (_, value) => {
													if (!isValidRut(value)) return Promise.reject(new Error(t('messages.errorSocialId')));
													else if (await personExists(value, personId, token, user)) return Promise.reject(new Error(t('messages.duplicateSocialId')));
													else return Promise.resolve();
												},
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item label={t('professionals.countryOfNationality')} name="nationality" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
										>
											{countries.map(item => (
												<Option value={item.id}>{item.description}</Option>
											))}
										</Select>
									</Form.Item>
								</Form>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.emails')}
								style={styles.card}
								extra={<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddEmail}></Button>}
							>
								<Table<Email> rowKey={record => record.id} tableLayout="auto" size="small" dataSource={[]} columns={columnsEmails} pagination={false} />
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.phones')}
								style={styles.card}
								extra={<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddPhone}></Button>}
							>
								<Table<Phone> rowKey={record => record.id} tableLayout="auto" size="small" dataSource={[]} columns={columnsPhone} pagination={false} />
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.addresses')}
								style={styles.card}
								extra={<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddAddress}></Button>}
							>
								<Table<Address> rowKey={item => item.id} tableLayout="auto" size="small" dataSource={[]} columns={columnsAddress} pagination={false} />
							</Card>
						</Col>
					</Row>
				</Content>
				<SizedModal
					title={t('management.getDataFromAgenda')}
					open={isModalVisible}
					okText={t('general.get')}
					cancelText={t('general.cancel')}
					onOk={() => {
						handleGetDataFromAgenda(modalValue);
					}}
					onCancel={() => {
						setIsModalVisible(false);
					}}
					size="xsmall"
				>
					<Row align="middle">
						<Search
							addonBefore={t('general.socialId')}
							onSearch={value => {
								if (!isValidRut(value)) setIsModalVisible(false);
								else handleGetDataFromAgenda(value);
							}}
							value={modalValue}
							onChange={event => {
								setModalValue(event.target.value);
								if (!isValidRut(event.target.value)) setIsErrorMessageVisible(true);
								else setIsErrorMessageVisible(false);
							}}
						/>
					</Row>
					<Row>
						{isErrorMessageVisible ? (
							<Col>
								<Text type="warning">{t('messages.enterValidValue')}</Text>
							</Col>
						) : null}
					</Row>
				</SizedModal>
			</Col>
		</div>
	);
};
export default PagePersonDetails;
