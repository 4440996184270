import { useEffect, useState } from 'react';
import { DatePicker, Input, Form, Select, message } from 'antd';
import { useStore } from 'react-redux';
import { Specialty, SpecialtyType, Institution, initialSpecialtyValue } from '../../store/types';
import { specialtyTypesSelectors, institutionsSelectors } from '../../store/selectors/domains';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { specialties } from '../../store/reducers';
import { SizedModal } from '..';
import { createUpdateSpecialty } from '../../store/api';
import { specialtySelectors } from '../../store/selectors';
import { includes } from '../../utils/util-custom-validators';
import { useAppConfigContext } from '../../utils';
import dayjs from 'dayjs';

const key = 'saving';
const { TextArea } = Input;
const { Option } = Select;
/**
 * Form to display a form for an specialty
 */
const FormProfessionalSpecialtyEdit = ({ professionalId, specialtyId, professionalTypeId, open, title, onClose }) => {
	const store = useStore();
	const [formProfessionalSpecialtyEdit] = Form.useForm();
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [specialtyTypes, setSpecialtyTypes] = useState<SpecialtyType[]>([]);
	const [institutions, setInstitutions] = useState<Institution[]>([]);
	const [specialty, setSpecialty] = useState<Specialty>(initialSpecialtyValue);

	useEffect(() => {
		setSpecialtyTypes(
			specialtyTypesSelectors
				.selectAll(store.getState() as any)
				.filter(s => !s.isDeleted && s.professionalTypeId === professionalTypeId)
				.sort((a, b) => a.description.localeCompare(b.description)),
		);
		setInstitutions(
			institutionsSelectors
				.selectAll(store.getState() as any)
				.filter(i => !i.isDeleted)
				.sort((a, b) => a.name.localeCompare(b.name)),
		);
		var item = specialtySelectors.selectById(store.getState() as any, specialtyId) || { ...initialSpecialtyValue, professionalId: professionalId };
		setSpecialty(item);
		setIsVisible(open);
		formProfessionalSpecialtyEdit.setFieldsValue({
			description: item.description,
			specialtyType: item.specialtyTypeId,
			institution: item.institutionId || '',
			endDate: dayjs(item.endDate),
			isActive: item.isActive,
			details: item.details,
		});
	}, [formProfessionalSpecialtyEdit, open, professionalId, professionalTypeId, specialtyId, store]);

	const handleCancel = () => {
		onClose();
	};

	const handleOk = async () => {
		try {
			var values = await formProfessionalSpecialtyEdit.validateFields();
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var temp: Specialty = {
				...specialty,
				startDate: values['endDate'].toDate(),
				endDate: values['endDate'].toDate(),
				details: values['details'],
				description: values['description'],
				isActive: values['isActive'],
				institutionId: values['institution'],
				specialtyTypeId: values['specialtyType'],
			};
			var result = await createUpdateSpecialty(temp, token, user);
			if (result !== undefined) {
				store.dispatch(specialties.actions.specialtyCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 1 });
			} else message.info({ content: t('messages.errorOnSave'), key, duration: 1 });
			onClose();
		} catch (errors) {}
	};

	return (
		<SizedModal size="small" title={title} open={isVisible} onOk={handleOk} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalSpecialtyEdit} name="specialty-details">
				<Form.Item label={t('professionals.specialtyType')} name="specialtyType" rules={[{ required: true, message: t('messages.enterType') }]}>
					<Select
						showSearch
						allowClear
						optionFilterProp="children"
						filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
					>
						{specialtyTypes.map(item => (
							<Option key={`specialtyType-${item.id}`} value={item.id}>
								{item.description}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.institution')} name="institution" rules={[{ required: true, message: t('messages.enterInstitution') }]}>
					<Select
						showSearch
						allowClear
						optionFilterProp="children"
						filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
					>
						{institutions.map(item => (
							<Option key={`institution-${item.id}`} value={item.id}>
								{item.description}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.endDate')} name="endDate" rules={[{ required: true, message: 'Debe ingresar un Nombre' }]}>
					<DatePicker picker="year" />
				</Form.Item>
				<Form.Item label={t('professionals.specialtyDescription')} name="description">
					<Input />
				</Form.Item>
				<Form.Item label={t('professionals.specialtyDetails')} name="details" rules={[{ required: false }]}>
					<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
				</Form.Item>
			</Form>
		</SizedModal>
	);
};
export default FormProfessionalSpecialtyEdit;
