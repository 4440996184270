import { useEffect, useState } from 'react';
import { AutoComplete, Avatar, Breadcrumb, Card, Col, FloatButton, Layout, Row, Select, Space, Tag, Typography } from 'antd';
import { useStore } from 'react-redux';
import { newsItemsSelectors, professionalTypeSelectors, tagsSelectors } from '../../store/selectors';
import { useTranslation } from 'react-i18next';
import { News, ProfessionalType, Tag as TagItem } from '../../store/types';
import { InfoCircleOutlined, MedicineBoxOutlined, UserOutlined } from '@ant-design/icons';
import { baseStyles, colors } from '../../assets/styles';
import { arrayIncludes, includes } from '../../utils/util-custom-validators';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes } from '../../components';

const { Text, Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const PageViewNews = () => {
	const { t } = useTranslation();
	const store = useStore();
	const cardWidth = 350;
	const cardHeight = 500;
	const state = store.getState() as any;
	const styles = baseStyles;
	const navigate = useNavigate();
	const [newsItems, setNewsItems] = useState<News[]>([]);
	const [selectedNewsItems, setSelectedNewsItems] = useState<News[]>([]);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [tags, setTags] = useState<TagItem[]>([]);

	useEffect(() => {
		var selectedTags = [];
		setProfessionalTypes(professionalTypeSelectors.selectAll(state));
		var items = newsItemsSelectors
			.selectAll(state)
			.filter(ni => !ni.isDeleted && ni.isPublished)
			.sort((a, b) => dayjs(a.publishDate).diff(dayjs(b.publishDate)))
			.reverse();
		items.forEach(i => (selectedTags = selectedTags.concat(i.tags)));
		selectedTags = selectedTags.filter((item, pos, self) => {
			return self.indexOf(item) === pos;
		});
		setTags(
			tagsSelectors
				.selectAll(state)
				.filter(t => selectedTags.includes(t.description))
				.sort((a, b) => a.description.localeCompare(b.description)),
		);
		setNewsItems(items);
		setSelectedNewsItems(items);
	}, [state]);

	const handleSearch = (value: string) => {
		if (value && value.length > 3) {
			setSelectedNewsItems(newsItems.filter(d => includes(d.title, value)));
		} else setSelectedNewsItems(newsItems);
	};

	const handleClear = () => {
		setSelectedNewsItems(newsItems);
	};

	const handleChangeTag = value => {
		var items = [];
		if (value)
			items = newsItemsSelectors
				.selectAll(state)
				.filter(ni => arrayIncludes(ni.tags, value) && !ni.isDeleted && ni.isPublished)
				.sort((a, b) => dayjs(a.publishDate).diff(dayjs(b.publishDate)))
				.reverse();
		else
			items = newsItemsSelectors
				.selectAll(state)
				.filter(ni => !ni.isDeleted && ni.isPublished)
				.sort((a, b) => dayjs(a.publishDate).diff(dayjs(b.publishDate)))
				.reverse();
		setSelectedNewsItems(items);
	};

	const handleChangeProfessionalType = value => {
		var items = [];
		if (value)
			items = newsItemsSelectors
				.selectAll(state)
				.filter(ni => ni.professionalTypeId === value && !ni.isDeleted && ni.isPublished)
				.sort((a, b) => dayjs(a.publishDate).diff(dayjs(b.publishDate)))
				.reverse();
		else
			items = newsItemsSelectors
				.selectAll(state)
				.filter(ni => !ni.isDeleted && ni.isPublished)
				.sort((a, b) => dayjs(a.publishDate).diff(dayjs(b.publishDate)))
				.reverse();
		setNewsItems(items);
	};

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[{ title: <MedicineBoxOutlined twoToneColor={colors.brandColor1} /> }, { title: t('view.viewContent') }, { title: t('view.news') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col></Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									onClear={handleClear}
									onSearch={handleSearch}
								/>
								<Text strong>{t('general.filters')}</Text>
								<Select style={{ width: 250 }} onChange={handleChangeProfessionalType} placeholder={t('messages.selectProfessionalTypeToFilter')} allowClear>
									{professionalTypes.map(pt => {
										return (
											<Option key={`professionalType-${pt.id}`} value={pt.id}>
												{pt.description}
											</Option>
										);
									})}
								</Select>
								<Select style={{ width: 250 }} onChange={handleChangeTag} placeholder={t('messages.selectTagToFilter')} allowClear>
									{tags.map(pt => {
										return (
											<Option key={`tags-${pt.id}`} value={pt.description}>
												{pt.description}
											</Option>
										);
									})}
								</Select>
							</Space>
						</Col>
						<Col flex={4} />
						<Col flex={3} />
					</Row>
					<Row style={{ paddingTop: 10 }}>
						{selectedNewsItems.length > 0 ? (
							<Row gutter={[24, 24]} style={{ width: '95%' }}>
								<FloatButton.BackTop key="back-top" type="primary" tooltip={t('messages.backToTop')} />
								{selectedNewsItems.map((item, index) => {
									return (
										<Col span={window.innerWidth / cardWidth}>
											<Card
												type="inner"
												key={`card-${index}`}
												id={`card-${index}`}
												style={{ width: cardWidth, height: cardHeight }}
												styles={{ header: { backgroundColor: colors.brandColor1, border: 0 }, body: { height: 400 } }}
												extra={[
													<InfoCircleOutlined
														key={`infocircle-${index}`}
														onClick={() => {
															navigate(AuthRoutes.viewNewsItem, { state: { id: item.id } });
														}}
													/>,
												]}
												actions={[
													<Text
														key={`actions-${index}`}
														onClick={() => {
															navigate(AuthRoutes.viewNewsItem, { state: { id: item.id } });
														}}
													>
														{t('general.view')}
													</Text>,
												]}
											>
												<Space direction="vertical" style={{ width: '100%', justifyContent: 'center' }}>
													<Row justify="center" style={{ paddingTop: 20 }}>
														{item.avatar !== '' ? (
															<Avatar key={`avatar-pic-${index}`} size={120} src={`${item.avatar}?${Date.now()}`} />
														) : (
															<Avatar key={`avatar-icon-${index}`} size={120} icon={<UserOutlined />} />
														)}
													</Row>
													<Row justify="center">
														<Title
															level={4}
															style={{ textAlign: 'center' }}
															ellipsis={{
																rows: 3,
																expandable: false,
																tooltip: true,
															}}
														>{`${item.title}`}</Title>
													</Row>
													<Row justify="center">
														<Space direction="vertical">
															<Text style={{ textAlign: 'center' }}>{`${t('news.author')} : ${item.author}`}</Text>
															<Text style={{ textAlign: 'center' }}>{`${t('news.newsDate')} : ${dayjs(item.newsItemDate).format('DD-MM-YYYY')}`}</Text>
														</Space>
													</Row>
													<Row justify="center">
														<Col>
															{item.tags.map(t => (
																<Tag style={styles.tag} key={`${index}-${t}`}>
																	{t.toUpperCase()}
																</Tag>
															))}
														</Col>
													</Row>
												</Space>
											</Card>
										</Col>
									);
								})}
							</Row>
						) : null}
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PageViewNews;
