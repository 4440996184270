import { Col, Row, Layout, Card, Typography, ConfigProvider, theme } from 'antd';
import { LogoBig, ProgressBarLoadingData, RequiredAuth } from '../../components';
import { fetchData } from '../../store/mainStore';
import { fetchDomains } from '../../store/mainStore';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { colors } from '../../assets/styles';
import { locales } from '../../App';
import { useAppConfigContext } from '../../utils/useAppConfigContext';
import background from '../../assets/images/background-redsalud-santiago.jpg';

const { Content } = Layout;

const { Text } = Typography;

/**
 * Page to show loading status
 */
const PageLoading = () => {
	const { t } = useTranslation();
	const { initialConfig, isDarkMode, token, user } = useAppConfigContext();
	document.title = initialConfig.systemName;

	useEffect(() => {
		fetchDomains(token, user);
		fetchData(token, user, initialConfig.networkId);
	}, [initialConfig.networkId, token, user]);

	return (
		<RequiredAuth>
			<ConfigProvider
				locale={locales[initialConfig.language]}
				theme={{
					token: { colorPrimary: colors.brandColor1, colorSuccess: colors.brandColor2 },
					algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
					components: {
						Input: {
							colorTextQuaternary: isDarkMode ? '#141414' : '#fff',
							algorithm: true,
						},
						DatePicker: { colorTextQuaternary: isDarkMode ? '#141414' : '#fff', algorithm: true },
					},
				}}
			>
				<Layout style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
					<Content>
						<Col>
							<Row style={{ height: '20vh' }}></Row>
							<Row style={{ height: '30vh' }}>
								<Col flex={1}></Col>
								<Col>
									<Card
										title={
											t('messages.startPage') + ' (' + initialConfig.version + ' - ' + process.env.REACT_APP_ENVIRONMENT + ' - ' + initialConfig.language + ')'
										}
										style={{ width: 640, height: '100%' }}
										styles={{ header: { backgroundColor: colors.brandColor1, color: colors.backgroundColor } }}
									>
										<Row style={{ height: 20 }} />
										<Row align="middle" justify="center">
											<Col flex="auto">
												<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
													<LogoBig />
												</Row>
											</Col>
										</Row>
										<Row style={{ height: 80 }} />
										<Row style={{ height: 20 }} />
										<Row style={{ width: '100%' }} justify="center">
											<ProgressBarLoadingData />
										</Row>
										<Row style={{ height: 20 }} />
										<Row style={{ height: 20 }}>
											<Text style={{ textAlign: 'center', width: '100vh' }}>{`RedSalud ©2024-2025 ${t(
												'general.createdBy',
											)} Dr. Francisco Fukuda - Dirección de Informática Médica`}</Text>
										</Row>
									</Card>
								</Col>
								<Col flex={1}></Col>
							</Row>
							<Row style={{ height: '50vh' }}></Row>
						</Col>
					</Content>
				</Layout>
			</ConfigProvider>
		</RequiredAuth>
	);
};

export default PageLoading;
