import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Input, Form, Alert, Select, message, Space, InputNumber } from 'antd';
import { Privilege, PrivilegeType, PrivilegeStatus, initialPrivilegeValue } from '../../store/types';
import { privilegeTypesSelectors, privilegeStatusesSelectors } from '../../store/selectors/domains';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { SizedModal } from '..';
import { privilegeSelectors } from '../../store/selectors';
import { privileges } from '../../store/reducers';
import { createUpdatePrivilege } from '../../store/api';
import { useAppConfigContext } from '../../utils';

const { TextArea } = Input;
const { Option } = Select;
const key = 'saving';

const FormProfessionalPrivileges = (props: {
	professionalId: number;
	privilegeId: number;
	disabled?: boolean;
	open: boolean;
	title: string;
	onClose: () => void;
}) => {
	const store = useStore();
	const [formProfessionalPrivileges] = Form.useForm();
	const { token, user } = useAppConfigContext();
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [privilege, setPrivilege] = useState<Privilege>(initialPrivilegeValue);
	const [privilegeTypes, setPrivilegeTypes] = useState<PrivilegeType[]>([]);
	const [privilegeStatuses, setPrivilegeStatuses] = useState<PrivilegeStatus[]>([]);
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [duplicatePrivilege, setDuplicatePrivilege] = useState<boolean>(false);

	useEffect(() => {
		setDuplicatePrivilege(false);
		var privilegeTypesItems = privilegeTypesSelectors.selectAll(store.getState() as any).filter(pt => !pt.isDeleted);
		setPrivilegeTypes(privilegeTypesItems);
		setPrivilegeStatuses(privilegeStatusesSelectors.selectAll(store.getState() as any).filter(ps => !ps.isDeleted));
		var item = privilegeSelectors.selectById(store.getState() as any, props.privilegeId) || {
			...initialPrivilegeValue,
			privilegeTypeId: privilegeTypesItems[0].id,
			professionalId: props.professionalId,
		};
		setPrivilege(item);
		setIsVisible(props.open);
		formProfessionalPrivileges.setFieldsValue({
			privilegeType: item.privilegeTypeId,
			privilegeStatus: item.privilegeStatusId,
			comments: item.comments,
			requestedHours: item.requestedHours,
			realHours: item.realHours,
		});
	}, [formProfessionalPrivileges, props, store]);

	const handleCancel = () => {
		setIsValidData(true);
		setPrivilege(initialPrivilegeValue);
		props.onClose();
	};

	const handleValuesChange = (changedValues: any) => {
		if (changedValues['type']) {
			var tPrivilegeType = changedValues['type'];
			if (
				privilegeSelectors
					.selectAll(store.getState() as any)
					.filter(i => !i.isDeleted)
					.find(p => p.professionalId === props.professionalId && p.privilegeTypeId === tPrivilegeType) !== undefined
			) {
				setIsValidData(false);
				setDuplicatePrivilege(true);
			} else {
				setIsValidData(true);
				setDuplicatePrivilege(false);
			}
		}
	};

	const handleSave = async () => {
		if (!isValidData) return;
		var values = await formProfessionalPrivileges.validateFields();
		if (values.errorField === undefined) {
			var temp: Privilege = {
				...privilege,
				privilegeTypeId: values['privilegeType'] || 0,
				privilegeStatusId: values['privilegeStatus'] || 0,
				realHours: values['realHours'] || 0,
				requestedHours: values['requestedHours'] || 0,
				comments: values['comments'] || '',
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var result = await createUpdatePrivilege(temp, props.professionalId, token, user);
			if (result !== undefined) {
				store.dispatch(privileges.actions.privilegeCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('message.errorOnSave'), key, duration: 2 });
		}
		props.onClose();
	};

	return (
		<SizedModal size="small" title={props.title} open={isVisible} onOk={handleSave} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalPrivileges} onValuesChange={handleValuesChange} name="privilege-details">
				<Form.Item label={t('professionals.privilegeType')} name="privilegeType">
					<Select>
						{privilegeTypes.map(p => {
							return (
								<Option key={`privilegeType-${p.id}`} value={p.id}>
									{p.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.privilegeStatus')} name="privilegeStatus">
					<Select>
						{privilegeStatuses.map(p => {
							return (
								<Option key={`privilegeStatus-${p.id}`} value={p.id}>
									{p.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.requestedHours')} name="requestedHours" rules={[{ required: false }]}>
					<InputNumber />
				</Form.Item>
				<Form.Item label={t('professionals.realHours')} name="realHours" rules={[{ required: false }]}>
					<InputNumber />
				</Form.Item>
				<Form.Item label={t('professionals.privilegeComments')} name="comments" rules={[{ required: false }]}>
					<TextArea />
				</Form.Item>
				<Space direction="horizontal">
					{duplicatePrivilege ? <Alert message={t('messages.privilegeAlreadyExists')} type="error" /> : null}
					{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
				</Space>
			</Form>
		</SizedModal>
	);
};
export default FormProfessionalPrivileges;
