import { useEffect, useState } from 'react';
import { Input, Form, Typography, Select } from 'antd';
import { useStore } from 'react-redux';
import { communesSelectors, provincesSelectors, regionsSelectors, addressesSelectors, countriesSelectors } from '../../store/selectors';
import { Region, Province, Commune, Address, Country, initialAddressValue } from '../../store/types';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { includes } from '../../utils/util-custom-validators';
import { useAppConfigContext } from '../../utils';

const { Text } = Typography;
const { Option } = Select;

const FormCenterAddressEdit = ({ addressId, centerId, setAddressData, setIsChanged, disabled = false }) => {
	const { t } = useTranslation();
	const store = useStore();
	const { country } = useAppConfigContext();
	const [formCenterAddressEdit] = Form.useForm();
	const [province, setProvince] = useState<string>('');
	const [region, setRegion] = useState<string>('');
	const [communes, setCommunes] = useState<Commune[]>([]);
	const [provinces, setProvinces] = useState<Province[]>([]);
	const [regions, setRegions] = useState<Region[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [address, setAddress] = useState<Address>(initialAddressValue);

	useEffect(() => {
		setCommunes(communesSelectors.selectAll(store.getState() as any));
		setProvinces(provincesSelectors.selectAll(store.getState() as any));
		setRegions(regionsSelectors.selectAll(store.getState() as any));
		setCountries(countriesSelectors.selectAll(store.getState() as any));
		var item = addressesSelectors.selectById(store.getState() as any, addressId) || initialAddressValue;
		setAddress(item);
		var tCommune = communes.find(c => c.id === item.communeId);
		var tProvince = provinces.find(p => p.id === tCommune?.provinceId);
		setProvince(tProvince?.description);
		var tRegion = regions.find(r => r.id === tProvince?.regionId);
		setRegion(tRegion?.description);
		formCenterAddressEdit.setFieldsValue({
			street: item.street,
			number: item.number,
			other: item.other,
			commune: item.communeId,
			province: provinces.find(p => p.id === tProvince.id)?.description,
			region: regions.find(r => r.id === tRegion.id)?.description,
			country: item.countryId || countries.find(c => c.code === country)?.id,
			latitude: item.latitude,
			longitude: item.longitude,
		});
	}, [addressId, centerId, communes, countries, country, formCenterAddressEdit, provinces, regions, store]);

	const handleValuesChange = (changedValues: any) => {
		setIsChanged(true);
		var item: Address = initialAddressValue;
		if (changedValues['street']) item = { ...address, street: changedValues['street'] };
		if (changedValues['number']) item = { ...address, number: changedValues['number'] };
		if (changedValues['other']) item = { ...address, other: changedValues['other'] };
		if (changedValues['latitude']) item = { ...address, latitude: changedValues['latitude'] };
		if (changedValues['longitude']) item = { ...address, longitude: changedValues['longitude'] };
		if (changedValues['commune']) {
			var tCommune = communes.find(c => c.id === changedValues['commune']);
			var tProvince = provinces.find(p => p.id === tCommune?.provinceId);
			var tRegion = regions.find(r => r.id === tProvince?.regionId);
			formCenterAddressEdit.setFieldsValue({
				province: provinces.find(p => p.id === tProvince.id)?.description,
				region: regions.find(r => r.id === tRegion.id)?.description,
			});
			item = { ...address, communeId: changedValues['commune'] };
		}
		if (changedValues['country']) item = { ...address, countryId: changedValues['country'] };
		setAddress(item);
		setAddressData(item);
	};

	return (
		<Form {...baseStyles.formLayout} form={formCenterAddressEdit} name="address-details" onValuesChange={handleValuesChange}>
			<Form.Item label={t('general.street')} name="street" rules={[{ required: true }]}>
				{disabled ? <Text>{address.street}</Text> : <Input />}
			</Form.Item>
			<Form.Item label={t('general.number')} name="number" rules={[{ required: true }]}>
				{disabled ? <Text>{address.number}</Text> : <Input />}
			</Form.Item>
			<Form.Item label={t('general.other')} name="other">
				{disabled ? <Text>{address.other}</Text> : <Input />}
			</Form.Item>
			<Form.Item label={t('general.latitude')} name="latitude">
				{disabled ? <Text>{address.latitude}</Text> : <Input />}
			</Form.Item>
			<Form.Item label={t('general.longitude')} name="longitude">
				{disabled ? <Text>{address.longitude}</Text> : <Input />}
			</Form.Item>
			<Form.Item label={t('general.commune')} name="commune" rules={[{ required: true }]}>
				{disabled ? (
					<Text>{communes.find(c => c.id === address.communeId)?.description}</Text>
				) : (
					<Select
						showSearch
						allowClear
						optionFilterProp="children"
						filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
					>
						{communes.map(item => (
							<Option key={`communes-${item.id}`} value={item.id}>
								{item.description}
							</Option>
						))}
					</Select>
				)}
			</Form.Item>
			<Form.Item label={t('general.province')} name="province">
				{disabled ? <Text>{province}</Text> : <Input disabled />}
			</Form.Item>
			<Form.Item label={t('general.region')} name="region">
				{disabled ? <Text>{region}</Text> : <Input disabled />}
			</Form.Item>
			<Form.Item label={t('general.country')} name="country" rules={[{ required: true }]}>
				{disabled ? (
					<Text>{countries.find(c => c.id === address.countryId)?.description}</Text>
				) : (
					<Select
						showSearch
						allowClear
						optionFilterProp="children"
						filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
					>
						{countries.map(item => (
							<Option key={`countries-${item.id}`} value={item.id}>
								{item.description}
							</Option>
						))}
					</Select>
				)}
			</Form.Item>
		</Form>
	);
};
export default FormCenterAddressEdit;
