import { DeleteOutlined, EditOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Space, Tooltip, Typography, Table, message, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { CheckUncheck } from '..';
import { baseStyles } from '../../assets/styles';
import { deleteAccreditationComment, fetchAccreditacionCommentsById } from '../../store/api';
import { accreditationComments as accreditationCommentsReducer } from '../../store/reducers';
import { accreditationCommentSelectors } from '../../store/selectors';
import { AccreditationComment, initialAccreditationCommentValue } from '../../store/types';
import { isAuthorized } from '../../utils/util-authentication';
import { useAppConfigContext } from '../../utils';
import FormProfessionalAccreditationCommentEdit from './form-professional-accreditation-comment-edit';

const { Text } = Typography;
const key = 'saving';

const FormProfessionalAccreditationComments = ({ professionalId, disabled = false }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [accreditationComment, setAccreditationComment] = useState<AccreditationComment>(initialAccreditationCommentValue);
	const [accreditationComments, setAccreditationComments] = useState<AccreditationComment[]>([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>('');

	useEffect(() => {
		setIsLoading(true);
		fetchAccreditacionCommentsById(professionalId, token, user).then(items => {
			store.dispatch(accreditationCommentsReducer.actions.accreditationCommentsLoading());
			store.dispatch(accreditationCommentsReducer.actions.accreditationCommentsReceived(items));
			setAccreditationComments(items.filter(c => !c.isDeleted && (c.isPrivate === isAuthorized('Access_Reserved_Data', user) || !c.isPrivate)));
			setIsLoading(false);
		});
	}, [professionalId, store, token, user]);

	const handleDelete = async (item: AccreditationComment) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteAccreditationComment(item, token, user);
		if (response) {
			setIsLoading(true);
			fetchAccreditacionCommentsById(professionalId, token, user).then(items => {
				store.dispatch(accreditationCommentsReducer.actions.accreditationCommentsLoading());
				store.dispatch(accreditationCommentsReducer.actions.accreditationCommentsReceived(items));
				setAccreditationComments(items.filter(c => !c.isDeleted && (c.isPrivate === isAuthorized('Access_Reserved_Data', user) || !c.isPrivate)));
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleEditAccreditationComment = (item: AccreditationComment) => {
		setModalTitle(t('professionals.editComment'));
		setAccreditationComment(item);
		setIsModalVisible(true);
	};

	const handleAddAccreditationComment = () => {
		setModalTitle(t('professionals.addComment'));
		setAccreditationComment({ ...initialAccreditationCommentValue, professionalId: professionalId });
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setAccreditationComments(accreditationCommentSelectors.selectAll(store.getState() as any).filter(c => c.professionalId === professionalId && !c.isDeleted));
	};

	const columns: ColumnProps<AccreditationComment>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: AccreditationComment): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('professionals.comment'),
			dataIndex: 'comment',
			key: 'comment',
		},
		{
			title: t('professionals.dateTime'),
			dataIndex: 'comentDateTime',
			key: 'comentDateTime',
			width: 150,
			render: (_text: string, item: AccreditationComment): ReactNode => <Text>{dayjs(item.commentDateTime).format('DD/MM/YYYY HH:mm')}</Text>,
		},
		{
			title: t('professionals.isPrivate'),
			dataIndex: 'isPrivate',
			key: 'isPrivate',
			width: 150,
			render: (_text: string, item: AccreditationComment): ReactNode => <CheckUncheck isChecked={item.isPrivate} />,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: AccreditationComment): ReactNode => {
				return disabled || !isAuthorized('Component_Comments', user) ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								type="primary"
								size="small"
								shape="round"
								onClick={() => {
									handleEditAccreditationComment(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" danger shape="round">
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.comments')}
			style={styles.card}
			extra={[
				disabled || !isAuthorized('Component_Comments', user) ? null : (
					<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddAccreditationComment}></Button>
				),
			]}
		>
			<Table<AccreditationComment>
				key={`accomplishments-${Date.now()}`}
				rowKey={item => item.id}
				tableLayout="auto"
				size="small"
				dataSource={accreditationComments}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			<FormProfessionalAccreditationCommentEdit
				title={modalTitle}
				open={isModalVisible}
				accreditationCommentId={accreditationComment.id}
				professionalId={professionalId}
				onClose={handleClose}
			/>
		</Card>
	);
};
export default FormProfessionalAccreditationComments;
