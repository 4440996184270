import { useCallback, useEffect, useRef, useState } from 'react';
import { Input, Breadcrumb, Button, Col, Layout, Row, Space, Typography, FloatButton, Popover } from 'antd';
import { Spin, Anchor, Empty, notification, Tag, Descriptions, message, Menu, MenuProps, DatePicker } from 'antd';
import { ApartmentOutlined, CalendarOutlined, ClearOutlined, FileTextOutlined, FilterOutlined } from '@ant-design/icons';
import { LaptopOutlined, LoadingOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons';
import { colors, dateFormat, dateTimeFormat } from '../../assets';
import { useAppConfigContext, uuid, isValidRut, filterNotes } from '../../utils';
import { useTranslation } from 'react-i18next';
import { urls } from '../../store/api';
import { useNavigate } from 'react-router-dom';
import {
	DocumentViewer,
	IconClinic,
	IconStethoscope,
	NodeEncounter,
	NodeNote,
	renderPDFClinicalNotesDocument,
	renderPDFCOMPINDocument,
	SizedModal,
} from '../../components';
import { FilterDataType, FilterType, PDFClinicalNotesProps, PDFCOMPINDocumentProps } from '../../types';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Content } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;

const PageEHRDetails = () => {
	const toolsRef = useRef(null);
	const ehrRef = useRef(null);
	const menuHeight = 120;
	const patientDataRef = useRef(null);
	const { isDarkMode, user } = useAppConfigContext();
	const [messageApi, messageContextHolder] = message.useMessage();
	const [patientDataViewHeight, setPatientDataViewHeight] = useState(0);
	const [contentsHeight, setContentsHeight] = useState(window.innerHeight - toolsRef.current?.offsetHeight - menuHeight || 0);
	const [openModal, setOpenModal] = useState(false);
	const [patientData, setPatientData] = useState<any>(undefined);
	const [filteredPatientData, setFilteredPatientData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedEncounter, setSelectedEncounter] = useState<any>(undefined);
	const [selectedPatient, setSelectedPatient] = useState<any>(undefined);
	const [filters, setFilters] = useState<FilterType[]>([]);
	const [filterTags, setFilterTags] = useState([]);
	const [localUrl, setLocalUrl] = useState('');
	const [loadingMessage, setLoadingMessage] = useState<string>('');
	const [professionalOptions, setProfessionalOptions] = useState([]);
	const [specialtyOptions, setSpecialtyOptions] = useState([]);
	const [centerOptions, setCenterOptions] = useState([]);
	const [systemOptions, setSystemOptions] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);
	const [selectedStartDate, setSelectedStartDate] = useState<string>(dayjs().format());
	const [selectedEndDate, setSelectedEndDate] = useState<string>(dayjs().format());
	const [minDate, setMinDate] = useState<string>(dayjs().format());
	const [maxDate, setMaxDate] = useState<string>(dayjs().format());
	const [anchorNodes, setAnchorNodes] = useState([]);
	const [noteNodes, setNoteNodes] = useState([]);
	const [notificacionApi, notificationContextHolder] = notification.useNotification();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const mapOptions = (type, options) => {
		return options.map((value, index) => {
			return {
				key: 'filters-' + type + '-' + index,
				value: value,
				label: value,
			};
		});
	};

	const onFilterTagClose = item => {
		setIsLoading(true);
		setLoadingMessage(t('general.filtering'));
		var tempFilters = filters.filter(f => f.key !== item) || [];
		setFilters(tempFilters);
		setFilterTags(
			tempFilters.map(item => (
				<Tag style={{ backgroundColor: colors.brandColor1 }} onClose={onFilterTagClose} closable>
					{item.value}
				</Tag>
			)),
		);
		var data: FilterDataType = {
			filters: tempFilters,
			patientData: patientData,
			startDate: selectedStartDate,
			endDate: selectedEndDate,
		};
		createNodes(filterNotes(data));
		setIsLoading(false);
	};

	const createNodes = useCallback((data: any[]) => {
		var profesionals = [];
		var specialties = [];
		var centers = [];
		var systems = [];
		var types = [];
		var years = [];
		var tempNoteNodes = [];
		var tempAnchorNodes = [];
		var anchorNode: any = {};
		var guid = '';
		var tempMinDate = dayjs().format();
		var tempMaxDate = dayjs().format();
		data.forEach(encounter => {
			if (dayjs(encounter.fecha) < dayjs(tempMinDate)) tempMinDate = dayjs(encounter.fecha).format();
			if (dayjs(encounter.fecha) > dayjs(tempMaxDate)) tempMaxDate = dayjs(encounter.fecha).format();
			if (!profesionals.includes(encounter.profesional?.nombre_completo)) profesionals.push(encounter.profesional?.nombre_completo);
			if (!specialties.includes(encounter.profesional?.especialidad)) specialties.push(encounter.profesional?.especialidad);
			if (!centers.includes(encounter.establecimiento?.descripcion)) centers.push(encounter.establecimiento?.descripcion);
			if (!systems.includes(encounter.sistema)) systems.push(encounter.sistema);
			if (!types.includes(encounter.tipo)) types.push(encounter.tipo);
			if (!years.includes(dayjs(encounter.fecha).year)) years.push(dayjs(encounter.fecha).year);
			anchorNode = tempAnchorNodes.find(a => a.key === dayjs(encounter.fecha).format(dateFormat));
			if (anchorNode) {
				guid = uuid();
				encounter.guid = guid;
				anchorNode.children.push({
					key: guid,
					href: '#' + guid,
					title: (
						<NodeEncounter
							date={encounter.fecha}
							professional_name={encounter.profesional?.nombre_completo}
							professional_specialty={encounter.profesional.especialidad ? encounter.profesional.especialidad : 'Sin especialidad'}
							system={encounter.sistema}
							scope={encounter.ambito}
							type={encounter.tipo}
							location={encounter.establecimiento?.descripcion}
						/>
					),
				});
			} else {
				guid = uuid();
				encounter.guid = guid;
				anchorNode = {
					key: dayjs(encounter.fecha).format(dateFormat),
					href: '#' + dayjs(encounter.fecha).format(dateFormat),
					title: (
						<Space>
							<CalendarOutlined />
							<Text strong>{dayjs(encounter.fecha).format(dateFormat)}</Text>
						</Space>
					),
					children: [
						{
							key: guid,
							href: '#' + guid,
							title: (
								<NodeEncounter
									date={encounter.fecha}
									professional_name={encounter.profesional?.nombre_completo}
									professional_specialty={encounter.profesional.especialidad ? encounter.profesional.especialidad : 'Sin especialidad'}
									system={encounter.sistema}
									scope={encounter.ambito}
									type={encounter.tipo}
									location={encounter.establecimiento?.descripcion}
								/>
							),
						},
					],
				};
				tempNoteNodes.push(<NodeNote encounter={encounter} guid={guid} />);
				tempAnchorNodes.push(anchorNode);
			}
		});
		profesionals.sort((a, b) => a?.localeCompare(b));
		specialties.sort((a, b) => a?.localeCompare(b));
		centers.sort((a, b) => a?.localeCompare(b));
		systems.sort((a, b) => a?.localeCompare(b));
		types.sort((a, b) => a?.localeCompare(b));
		setProfessionalOptions(mapOptions('professionals', profesionals));
		setSpecialtyOptions(mapOptions('specialties', specialties));
		setCenterOptions(mapOptions('centers', centers));
		setSystemOptions(mapOptions('systems', systems));
		setTypeOptions(mapOptions('types', types));
		setSelectedStartDate(tempMinDate);
		setSelectedEndDate(tempMaxDate);
		setMinDate(tempMinDate);
		setMaxDate(tempMaxDate);
		setAnchorNodes(tempAnchorNodes);
		setNoteNodes(tempNoteNodes);
	}, []);

	useEffect(() => {
		const handleResize = () => {
			if (toolsRef.current) {
				setContentsHeight(window.innerHeight - toolsRef.current.offsetHeight - patientDataViewHeight - menuHeight);
			}
			if (patientDataRef.current) {
				setPatientDataViewHeight(patientDataRef.current.offsetHeight);
				setContentsHeight(window.innerHeight - toolsRef.current.offsetHeight - patientDataRef.current.offsetHeight - menuHeight);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [patientDataViewHeight, openModal, createNodes]);

	const getPatientData = async (rut: string) => {
		setIsLoading(true);
		setLoadingMessage(t('general.loading'));
		setFilters([]);
		if (toolsRef.current) {
			setContentsHeight(window.innerHeight - toolsRef.current.offsetHeight - menuHeight);
		}
		const request = urls.getEHRData + '?rut=' + rut;
		const requestOptions = {
			method: 'GET',
		};
		var response = await fetch(request, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			setPatientData(data);
			setSelectedPatient({
				nombre: data.nombre,
				apellido_paterno: data.apellido_paterno,
				apellido_materno: data.apellido_materno,
				numero_identificacion: data.numero_identificacion,
				fecha_nacimiento: data.fecha_nacimiento,
				sexo: data.sexo,
				direccion: data.direccion,
				telefono: data.telefono,
			});
			setFilteredPatientData(data.episodios);
			createNodes(data.episodios);
		} else {
			setPatientData(undefined);
			setSelectedPatient(undefined);
			setFilteredPatientData(undefined);
		}
		setIsLoading(false);
	};

	const onAnchorClick = (e, link) => {
		if (link.href === '') return;
		var guid = link.href.substring(1);
		var encounter = patientData.episodios.find(episode => episode.guid === guid);
		setSelectedEncounter(encounter);
		navigate(link.href);
		notificacionApi.info({
			message: t('messages.selectedEpisode'),
			style: { width: 500 },
			description: (
				<Space direction="vertical">
					<Text>{dayjs(encounter?.fecha).format(dateTimeFormat) + ' - ' + encounter?.profesional?.nombre_completo} </Text>
					<Row>
						<Tag color={colors.brandColor1} style={{ backgroundColor: colors.brandColor2 }} icon={<IconStethoscope />}>
							{encounter?.profesional?.especialidad}
						</Tag>
						<Tag color={colors.brandColor1} style={{ backgroundColor: colors.brandColor1 }} icon={<IconClinic />}>
							{encounter?.establecimiento.descripcion}
						</Tag>
					</Row>
				</Space>
			),
			placement: 'bottomLeft',
		});
	};

	const onSearchPatient = (value: string) => {
		if (isValidRut(value)) getPatientData(value);
		else if (value !== '')
			messageApi.open({
				type: 'error',
				content: t('messages.invalidRut'),
			});
		else setSelectedPatient(undefined);
	};

	const onRangePickerSelect = (_dates, dateStrings, _info) => {
		setIsLoading(true);
		setLoadingMessage(t('general.filtering'));
		var data: FilterDataType;
		if (dateStrings[0] === '' && dateStrings[1] === '') {
			setSelectedStartDate(minDate);
			setSelectedEndDate(dayjs().format());
			data = {
				filters: filters,
				patientData: patientData,
				startDate: dayjs(minDate).format(),
				endDate: dayjs(maxDate).format(),
			};
			createNodes(filterNotes(data));
		} else if (dateStrings[0] === '' || dateStrings[1] === '') return;
		else {
			var tempStartDate = dayjs(dateStrings[0], 'DD/MM/YYYY').format();
			var tempEndDate = dayjs(dateStrings[1], 'DD/MM/YYYY').format() || dayjs().format();
			setSelectedStartDate(dayjs(dateStrings[0]).format());
			setSelectedEndDate(dayjs(dateStrings[1]).format());
			data = {
				filters: filters,
				patientData: patientData,
				startDate: tempStartDate,
				endDate: tempEndDate,
			};
			createNodes(filterNotes(data));
			setIsLoading(false);
		}
	};

	const clearAllFilters = () => {
		setIsLoading(true);
		var tempFilters = [];
		setFilters(tempFilters);
		setFilterTags(null);
		setSelectedStartDate(minDate);
		setSelectedEndDate(maxDate);
		var data: FilterDataType = {
			filters: tempFilters,
			patientData: patientData,
			startDate: '',
			endDate: '',
		};
		createNodes(filterNotes(data));
		setIsLoading(false);
	};

	const onMenuClick: MenuProps['onClick'] = e => {
		var menuType = e.key.split('-')[0];
		var menuOption = e.key.split('-')[1];
		if (menuType === 'tools') {
			if (menuOption === 'getEHRfile') {
				var data_clinical_notes: PDFClinicalNotesProps = {
					title: 'Resumen COMPIN',
					author: 'Dirección de Informátrica Médica',
					description: 'Resumen Episodio para COMPIN',
					patient: selectedPatient,
					encounters: filteredPatientData,
					user: user ? user.givenName + ' ' + user.familyName : '',
				};
				setIsLoading(true);
				setLoadingMessage(t('messages.generatingDocument'));
				renderPDFClinicalNotesDocument(data_clinical_notes).then(url => {
					setLocalUrl(url);
					setOpenModal(true);
				});
			} else if (menuOption === 'getCOMPINfile') {
				var data_pdf_document: PDFCOMPINDocumentProps = {
					title: 'Resumen COMPIN',
					author: 'Dirección de Informátrica Médica',
					description: 'Resumen Episodio para COMPIN',
					patient: selectedPatient,
					encounter: selectedEncounter,
					user: user ? user.givenName + ' ' + user.familyName : '',
				};
				setIsLoading(true);
				setLoadingMessage(t('messages.generatingDocument'));
				renderPDFCOMPINDocument(data_pdf_document).then(url => {
					setLocalUrl(url);
					setOpenModal(true);
				});
			}
		}
		if (menuType === 'filters') {
			setIsLoading(true);
			setLoadingMessage(t('general.filtering'));
			var filterValue: FilterType;
			var tempFilters = [];
			if (menuOption === 'professionals') {
				filterValue = {
					type: 'professionals',
					key: e.key,
					value: professionalOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'specialties') {
				filterValue = {
					type: 'specialties',
					key: e.key,
					value: specialtyOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'centers') {
				filterValue = {
					type: 'centers',
					key: e.key,
					value: centerOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'systems') {
				filterValue = {
					type: 'systems',
					key: e.key,
					value: systemOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			if (menuOption === 'types') {
				filterValue = {
					type: 'types',
					key: e.key,
					value: typeOptions.find(item => item.key === e.key).value,
				};
				tempFilters = [...filters, filterValue];
			}
			setFilters(tempFilters);
			setFilterTags(
				tempFilters.map(item => (
					<Tag style={{ backgroundColor: colors.brandColor1, color: isDarkMode ? 'white' : 'black' }} onClose={() => onFilterTagClose(item.key)} closable>
						{item.value}
					</Tag>
				)),
			);
			var data: FilterDataType = {
				filters: tempFilters,
				patientData: patientData,
				startDate: selectedStartDate,
				endDate: selectedEndDate,
			};
			createNodes(filterNotes(data));
			setIsLoading(false);
		}
	};

	return (
		<div>
			<Content className="site-layout-background">
				<Col>
					<div ref={toolsRef}>
						<Row>
							<Breadcrumb
								separator=">"
								style={{ height: 30, margin: '10 10 0 0' }}
								items={[
									{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
									{ title: t('management.management') },
									{ title: t('management.ehrTools') },
								]}
							/>
						</Row>
						<Row style={{ paddingBottom: 10, height: '100%' }}>
							<Col>
								<Space direction="horizontal">
									<Text strong>{t('management.patientId')}</Text>
									<Search style={{ width: 200 }} placeholder={t('management.searchPatient')} onSearch={onSearchPatient} allowClear />
									{patientData ? (
										<Input
											disabled
											style={{ width: 150, color: isDarkMode ? 'white' : 'black' }}
											value={filteredPatientData.length + ' ' + t('management.encounters')}
										/>
									) : null}
									<Text strong>{t('management.dateRange')}</Text>
									<RangePicker
										allowClear
										value={[dayjs(selectedStartDate) || dayjs(), dayjs(selectedEndDate) || dayjs()]}
										allowEmpty={patientData === undefined ? true : false}
										picker="month"
										showTime={false}
										format={dateFormat}
										minDate={dayjs(minDate)}
										maxDate={dayjs(maxDate)}
										onCalendarChange={onRangePickerSelect}
									/>
									{filters.length > 0 ? <Text strong>{t('management.activeFilters')}</Text> : null}
									{filterTags}
								</Space>
							</Col>
							<Col flex={1}>
								<Row justify="end">
									<Space direction="horizontal" style={{ paddingRight: 10 }}>
										{filters.length > 0 || selectedStartDate !== '' || selectedEndDate !== '' ? (
											<Button shape="round" type="primary" icon={<ClearOutlined />} onClick={() => clearAllFilters()}>
												{t('management.clearFilters')}
											</Button>
										) : null}
									</Space>
								</Row>
							</Col>
						</Row>
					</div>
					<Row>
						<Col style={{ width: '100%', height: '100%' }}>
							<Spin tip={loadingMessage} indicator={<LoadingOutlined spin />} size="large" spinning={isLoading}>
								<Row ref={patientDataRef} style={{ padding: 10 }}>
									{patientData ? (
										<Descriptions
											column={5}
											items={[
												{
													key: '1',
													label: t('management.name'),
													children: <Text ellipsis>{patientData.apellido_paterno + ' ' + patientData.apellido_materno + ', ' + patientData.nombre}</Text>,
												},
												{
													key: '2',
													label: t('management.patientId'),
													children: patientData.numero_identificacion,
												},
												{
													key: '3',
													label: t('management.birthDate'),
													children: dayjs(patientData.fecha_nacimiento).format(dateFormat),
												},
												{
													key: '4',
													label: t('general.age'),
													children: dayjs().diff(patientData.fecha_nacimiento, 'years') + ' ' + t('general.years'),
												},
												{
													key: '5',
													label: t('management.sex'),
													children: patientData.sexo,
												},
											]}
										/>
									) : null}
								</Row>
								<Row style={{ height: contentsHeight }}>
									{patientData ? (
										<>
											<Col span={6} style={{ height: contentsHeight, overflowY: 'scroll' }}>
												<Anchor affix={false} direction="vertical" items={anchorNodes} onClick={onAnchorClick} />
											</Col>
											<Col ref={ehrRef} span={18} style={{ height: contentsHeight, paddingTop: 20, paddingLeft: 20, overflowY: 'scroll' }}>
												{noteNodes}
											</Col>
											<FloatButton.Group shape="circle">
												<Popover
													placement="right"
													title={t('general.filters')}
													content={
														<Menu
															selectable={false}
															onClick={onMenuClick}
															style={{ width: 256 }}
															mode="vertical"
															forceSubMenuRender
															items={[
																{ key: 'filters-centers', icon: <IconClinic />, label: t('management.center'), children: centerOptions },
																{ key: 'filters-systems', icon: <LaptopOutlined />, label: t('management.system'), children: systemOptions },
																{ key: 'filters-types', icon: <FileTextOutlined />, label: t('management.type'), children: typeOptions },
																{ key: 'filters-specialties', icon: <IconStethoscope />, label: t('management.specialty'), children: specialtyOptions },
																{ key: 'filters-professionals', icon: <UserOutlined />, label: t('management.professionals'), children: professionalOptions },
															]}
														/>
													}
												>
													<FloatButton type="primary" icon={<FilterOutlined />} />
												</Popover>
												<Popover
													placement="rightBottom"
													title={t('management.ehrTools')}
													content={
														<Menu
															selectable={false}
															onClick={onMenuClick}
															style={{ width: 256 }}
															mode="vertical"
															items={[
																{ key: 'tools-summaryHR', label: t('management.summaryHR'), disabled: patientData === undefined ? true : false },
																{ key: 'tools-getEHRfile', label: t('management.getEHRfile'), disabled: selectedPatient === undefined ? true : false },
																{ key: 'tools-getCOMPINfile', label: t('management.getCOMPINfile'), disabled: selectedEncounter === undefined ? true : false },
															]}
														/>
													}
												>
													<FloatButton type="primary" icon={<ToolOutlined />} />
												</Popover>
												<FloatButton.BackTop type="primary" tooltip={t('messages.backToTop')} target={() => ehrRef.current} visibilityHeight={0} />
											</FloatButton.Group>
										</>
									) : (
										<Col span={24}>
											<Empty imageStyle={{ height: 60 }} description={<Text style={{ color: 'gray' }}>{t('messages.searchPatient')}</Text>} />
										</Col>
									)}
								</Row>
								{notificationContextHolder}
								{messageContextHolder}
							</Spin>
						</Col>
					</Row>
				</Col>
			</Content>
			<SizedModal
				size="medium"
				title={t('management.ehrTools')}
				closable={false}
				open={openModal}
				onClose={() => setOpenModal(false)}
				footer={
					<Space>
						<Button
							shape="round"
							type="primary"
							onClick={() => {
								const link = document.createElement('a');
								link.download = 'documento_' + patientData.numero_identificacion;
								link.href = localUrl;
								link.click();
								setIsLoading(false);
								setOpenModal(false);
							}}
						>
							{t('general.download')}
						</Button>
						<Button
							shape="round"
							type="primary"
							onClick={() => {
								setIsLoading(false);
								setOpenModal(false);
							}}
						>
							{t('general.ok')}
						</Button>
					</Space>
				}
				destroyOnClose={true}
			>
				<DocumentViewer url={localUrl} size="large"></DocumentViewer>
			</SizedModal>
		</div>
	);
};
export default PageEHRDetails;
