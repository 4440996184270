import { useEffect, useState } from 'react';
import { Select, Input, Form, message, Alert } from 'antd';
import { useStore } from 'react-redux';
import { emailsSelectors, emailTypesSelectors } from '../../store/selectors';
import { EmailType, Email, initialEmailValue } from '../../store/types';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { isValidEmail } from '../../utils/util-custom-validators';
import { createUpdateEmail } from '../../store/api';
import { emails } from '../../store/reducers';
import { useAppConfigContext } from '../../utils';
import SizedModal from '../main/modal-sized';

const { Option } = Select;

const key = 'saving';

const FormProfessionalEmailEdit = (props: { professionalId: number; emailId: number; open: boolean; title: string; onClose: () => void }) => {
	const store = useStore();
	const [formProfessionalEmailEdit] = Form.useForm();
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [emailTypes, setEmailTypes] = useState<EmailType[]>([]);
	const [isValidData, setIsValidData] = useState<boolean>(true);

	useEffect(() => {
		setEmailTypes(emailTypesSelectors.selectAll(store.getState() as any).filter(t => !t.isDeleted));
		var item = emailsSelectors.selectById(store.getState() as any, props.emailId) || { ...initialEmailValue, professionalId: props.professionalId };
		setIsVisible(props.open);
		formProfessionalEmailEdit.setFieldsValue({
			emailType: item.emailTypeId,
			url: item.url,
		});
	}, [formProfessionalEmailEdit, store, props]);

	const handleCancel = () => {
		setIsValidData(true);
		props.onClose();
	};

	const handleValuesChange = (changedValues: any) => {
		if (changedValues['url']) {
			if (isValidEmail(changedValues['url'])) setIsValidData(true);
			else setIsValidData(false);
		}
	};

	const handleSave = async () => {
		if (!isValidData) return;
		try {
			var values = await formProfessionalEmailEdit.validateFields();
			var temp: Email = {
				id: props.emailId || 0,
				professionalId: props.professionalId,
				personId: 0,
				url: values['url'],
				emailTypeId: values['emailType'] || 0,
				isDeleted: false,
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var result = await createUpdateEmail(temp, token, user);
			if (result !== undefined) {
				store.dispatch(emails.actions.emailCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('message.errorOnSave'), key, duration: 2 });
			props.onClose();
		} catch (errors) {
			setIsValidData(false);
		}
	};

	return (
		<SizedModal size="small" title={props.title} open={isVisible} onOk={handleSave} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalEmailEdit} name="email-details" onValuesChange={handleValuesChange}>
				<Form.Item label={t('professionals.emailType')} name="emailType" rules={[{ required: true, message: t('messages.enterType') }]}>
					<Select>
						{emailTypes.map(e => {
							return (
								<Option key={`emailType-${e.id}`} value={e.id}>
									{e.description}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t('professionals.emailAddress')} name="url" rules={[{ required: true, message: t('messages.enterValue') }]}>
					<Input />
				</Form.Item>
				{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
			</Form>
		</SizedModal>
	);
};

export default FormProfessionalEmailEdit;
