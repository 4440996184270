import { FunctionComponent, useEffect, useState } from 'react';
import { Space, Typography, Spin, Row, Col, InputNumber, Skeleton, Image } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import { urls } from '../../store/api';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { Text } = Typography;

interface ModalDocumentViewerProps extends ModalProps {
	url: string;
	size: 'small' | 'medium' | 'large';
}

const DocumentViewer: FunctionComponent<ModalDocumentViewerProps> = ({ url, size }) => {
	const { t } = useTranslation();
	const [pdf, setPdf] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [width, setWidth] = useState<number>(window.innerWidth * 0.5);
	const [height, setHeight] = useState<number>(window.innerHeight * 0.75);
	const [scale, setScale] = useState<number>(1);
	const [numPages, setNumPages] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [localUrl, setLocalUrl] = useState<string>('');
	const [contentType, setContenType] = useState<string>('');

	useEffect(() => {
		setIsLoading(true);
		switch (size) {
			case 'small':
				setWidth(window.innerWidth / 8);
				setHeight(window.innerHeight / 4);
				break;
			case 'medium':
				setWidth(window.innerWidth / 5);
				setHeight(window.innerHeight / 3);
				break;
			case 'large':
				setWidth(window.innerWidth / 2);
				setHeight(window.innerHeight / 2);
				break;
		}
		if (size === 'small') setWidth(window.innerWidth / 8);
		else if (size === 'medium') setWidth(window.innerWidth / 5);
		else if (size === 'large') setWidth(window.innerWidth / 2);
		if (url !== null && url !== '' && url.substring(0, 4) !== 'blob') {
			fetch(`${urls.getDocument}?uri=${url}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/pdf',
				},
			}).then(response => {
				var responseContentType = response.headers.get('Content-Type');
				setContenType(responseContentType);
				response.blob().then(blob => {
					var file = new Blob([blob], { type: responseContentType });
					var local = window.URL.createObjectURL(file);
					setLocalUrl(local);
					setIsLoading(false);
				});
			});
		} else {
			setLocalUrl(url);
			setContenType('application/pdf');
		}
		setIsLoading(false);
	}, [size, url]);

	const handlePageDimensions = async (pageNumber: number, pdf: any) => {
		if (pageNumber <= (pdf?.numPages || 0) || pageNumber >= 1) {
			var page = await pdf?.getPage(pageNumber);
			var pageWidth = page?.getViewport({ scale: 1 }).width || 1;
			var widthScale = Math.min(1, (width / pageWidth) * 0.8);
			var scale = widthScale;
			return scale;
		} else return 1;
	};

	const handleDocumentLoadSuccess = async (pdf: any) => {
		setPageNumber(1);
		setNumPages(pdf.numPages);
		setPdf(pdf);
		setScale(await handlePageDimensions(1, pdf));
	};

	const handlePageNumberChange = (value: number | string | undefined | null) => {
		if (typeof value === 'number') {
			setPageNumber(value);
		}
	};

	const handleLeftClick = async () => {
		var newPageNumber = Math.max(1, pageNumber - 1);
		if (pdf !== undefined) setScale(await handlePageDimensions(newPageNumber, pdf));
		setPageNumber(newPageNumber);
	};

	const handleRightClick = async () => {
		var newPageNumber = Math.min(numPages, pageNumber + 1);
		if (pdf !== undefined) setScale(await handlePageDimensions(newPageNumber, pdf));
		setPageNumber(newPageNumber);
	};

	const spinner = (
		<Col>
			<Row justify="center">
				<Spin size="large"></Spin>
			</Row>
		</Col>
	);
	if (contentType === 'application/pdf')
		return (
			<Col>
				<Row style={{ padding: 5 }}>
					<Col flex={1}>
						<Row justify="start">
							<LeftCircleOutlined style={{ fontSize: 24 }} onClick={handleLeftClick} />
						</Row>
					</Col>
					<Col flex={8}>
						<Row justify="center" align="middle">
							<Space direction="horizontal" size="small">
								<Text>{t('general.page')}</Text>
								<InputNumber min={1} max={numPages} value={pageNumber} onChange={handlePageNumberChange} type="number" width="150px" />
								<Text>{t('general.of')}</Text>
								<InputNumber type="number" value={numPages} width="150px" disabled />
							</Space>
						</Row>
					</Col>
					<Col flex={1}>
						<Row justify="end">
							<RightCircleOutlined style={{ fontSize: 24 }} onClick={handleRightClick} />
						</Row>
					</Col>
				</Row>
				<Row align="middle" justify="center">
					<Col>
						<Row>
							{!isLoading ? (
								<Document file={localUrl} loading={spinner} onLoadSuccess={handleDocumentLoadSuccess}>
									<Row style={{ backgroundColor: 'gray', padding: '1px' }}>
										<Page pageNumber={pageNumber} scale={scale} height={height}></Page>
									</Row>
								</Document>
							) : (
								<Skeleton paragraph={{ rows: 10, width: width * 0.75 }} />
							)}
						</Row>
					</Col>
				</Row>
			</Col>
		);
	else if (contentType === 'image/jpeg')
		return (
			<Col>
				<Row align="middle" justify="center">
					<Col>
						<Row>{!isLoading ? <Image src={localUrl} preview={false}></Image> : <Skeleton paragraph={{ rows: 10, width: width * 0.75 }} />}</Row>
					</Col>
				</Row>
			</Col>
		);
	else
		return (
			<Col>
				<div>
					{localUrl ? (
						<Row justify="center">
							<Text type="danger" strong>
								{t('messages.notSupportedFormat')}
							</Text>
						</Row>
					) : null}
					<Skeleton paragraph={{ rows: 10, width: width * 0.75 }} />
				</div>
			</Col>
		);
};
export default DocumentViewer;
