import { ReactNode, useEffect, useState } from 'react';
import { AutoComplete, Breadcrumb, Button, Col, Layout, message, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useStore } from 'react-redux';
import { AuthRoutes, CheckUncheck } from '../../components';
import { useTranslation } from 'react-i18next';
import { ApartmentOutlined, DeleteOutlined, EditOutlined, FilterFilled, PlusOutlined } from '@ant-design/icons';
import { cases as casesReducer } from '../../store/reducers';
import { Link } from 'react-router-dom';
import { Case, Center, Person, Priority } from '../../store/types';
import { casesSelectors, personsSelectors } from '../../store/selectors/management';
import { ColumnProps } from 'antd/lib/table';
import { centersSelectors } from '../../store/selectors';
import { caseTypesSelectors, prioritiesSelectors } from '../../store/selectors/domains';
import { CaseType } from '../../store/types/domains';
import { deleteCase } from '../../store/api';
import { baseStyles, colors, dateFormat } from '../../assets';
import { useAppConfigContext } from '../../utils';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Content } = Layout;

const PageCaseIndex = () => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [centers, setCenters] = useState<Center[]>([]);
	const [cases, setCases] = useState<Case[]>([]);
	const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
	const [persons, setPersons] = useState<Person[]>([]);
	const [priorities, setPriorities] = useState<Priority[]>([]);
	const [isloading, setIsLoading] = useState(true);

	useEffect(() => {
		setCenters(centersSelectors.selectAll(store.getState() as any));
		setPersons(personsSelectors.selectAll(store.getState() as any));
		setCaseTypes(caseTypesSelectors.selectAll(store.getState() as any));
		setPriorities(prioritiesSelectors.selectAll(store.getState() as any));
		var items = casesSelectors
			.selectAll(store.getState() as any)
			.filter(a => !a.isDeleted)
			.sort((a, b) => dayjs(b.creationDate).diff(dayjs(a.creationDate), 'seconds'));
		setCases(items);
		setIsLoading(false);
	}, [store]);

	const handleCaseDelete = async (item: Case) => {
		var response = await deleteCase(item, token, user);
		if (response) {
			store.dispatch(casesReducer.actions.casesCreateUpdate({ ...item, isDeleted: true }));
			setCases(casesSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted));
		} else message.error(t('message.unableToDelete'));
	};

	const columns: ColumnProps<Case>[] = [
		{
			title: t('management.patientName'),
			dataIndex: 'patientName',
			key: 'patientName',
			ellipsis: true,
			render: (_text: string, item: Case): ReactNode => {
				var person = persons.find(c => c.id === item.patientId);
				return <Text>{person ? `${person.lastName} ${person.secondLastName}, ${person.names}` : ''}</Text>;
			},
		},
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			ellipsis: true,
		},
		{
			title: t('management.patientSocialId'),
			dataIndex: 'socialId',
			key: 'socialId',
			width: 120,
			ellipsis: true,
			render: (_text: string, item: Case): ReactNode => <Text>{persons.find(c => c.id === item.patientId)?.socialId}</Text>,
		},
		{
			title: t('management.center'),
			dataIndex: 'center',
			key: 'center',
			render: (_text: string, item: Case): ReactNode => <Text>{centers.find(c => c.id === item.originId)?.name}</Text>,
		},
		{
			title: t('management.date'),
			dataIndex: 'date',
			key: 'date',
			width: 100,
			render: (_text: string, item: Case): ReactNode => <Text>{dayjs(item.creationDate).format(dateFormat)}</Text>,
		},
		{
			title: t('management.caseType'),
			dataIndex: 'caseType',
			key: 'caseType',
			width: 200,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: caseTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.caseTypeId === value,
			render: (_text: string, item: Case): ReactNode => <Tag style={styles.tag}>{caseTypes.find(a => a.id === item.caseTypeId)?.description}</Tag>,
		},
		{
			title: t('management.priority'),
			dataIndex: 'priority',
			key: 'priority',
			width: 100,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: priorities.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.priorityId === value,
			render: (_text: string, item: Case): ReactNode => <Tag style={styles.tagAlternative}>{priorities.find(a => a.id === item.priorityId)?.description}</Tag>,
		},
		{
			title: t('management.isClosed'),
			dataIndex: 'isClosed',
			key: 'isClosed',
			width: 100,
			ellipsis: {
				showTitle: false,
			},
			render: (_text: string, item: Case): ReactNode => <CheckUncheck isChecked={item.isClosed} />,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Case): ReactNode => {
				return (
					<Space size="middle">
						<Link to={AuthRoutes.cases + `/details`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleCaseDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.cases') },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Link key="link-add-case" to={AuthRoutes.cases + `/details`} state={{ id: 0 }}>
								<Button key="button-add-case" type="primary" shape="round" icon={<PlusOutlined />}>
									{t('general.add')}
								</Button>
							</Link>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete popupMatchSelectWidth={baseStyles.popupMatchSelectWidth} style={styles.search} placeholder={t('general.search')} allowClear />
							</Space>
						</Col>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<Table<Case>
							rowKey={record => record.id}
							loading={isloading}
							key={`activity-table`}
							tableLayout="auto"
							size="small"
							dataSource={cases}
							columns={columns}
							pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
							style={{ width: '96vw' }}
						/>
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PageCaseIndex;
