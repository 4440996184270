import { useEffect, useState } from 'react';
import { DatePicker, Form, Input, message, Switch } from 'antd';
import { useStore } from 'react-redux';
import { AccreditationComment, initialAccreditationCommentValue } from '../../store/types';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { accreditationCommentSelectors } from '../../store/selectors';
import { SizedModal } from '..';
import { createUpdateAccreditationComment } from '../../store/api';
import { accreditationComments } from '../../store/reducers';
import { useAppConfigContext } from '../../utils';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/es_ES';

const { TextArea } = Input;

const key = 'saving';
/**
 * Form to display a form for an email
 */
const FormProfessionalAccreditationCommentEdit = (props: {
	accreditationCommentId: number;
	professionalId: number;
	open: boolean;
	title: string;
	onClose: () => void;
}) => {
	const store = useStore();
	const state = store.getState() as any;
	const { token, user } = useAppConfigContext();
	const [formProfessionalAccreditationCommentEdit] = Form.useForm();
	const { t } = useTranslation();
	const [accreditationComment, setAccreditationComment] = useState<AccreditationComment>(initialAccreditationCommentValue);
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		var item = accreditationCommentSelectors.selectById(state, props.accreditationCommentId) || initialAccreditationCommentValue;
		setAccreditationComment(item);
		setIsVisible(props.open);
		formProfessionalAccreditationCommentEdit.setFieldsValue({
			comment: item.comment,
			commentDateTime: dayjs(item.commentDateTime) || dayjs(),
			isPrivate: item.isPrivate,
		});
	}, [formProfessionalAccreditationCommentEdit, props, state]);

	const handleSave = async () => {
		try {
			var values = await formProfessionalAccreditationCommentEdit.validateFields();
			var temp: AccreditationComment = {
				...accreditationComment,
				professionalId: props.professionalId,
				comment: values['comment'],
				commentDateTime: values['commentDateTime'].toDate(),
				isPrivate: values['isPrivate'],
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var result = await createUpdateAccreditationComment(temp, props.professionalId, token, user);
			if (result !== undefined) {
				store.dispatch(accreditationComments.actions.accreditationCommentCreateUpdate(result));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('message.errorOnSave'), key, duration: 2 });
		} catch (errors) {
			message.info({ content: t('message.errorOnSave'), key, duration: 2 });
		}
		props.onClose();
	};

	const handleCancel = () => {
		props.onClose();
	};

	return (
		<SizedModal size="small" title={props.title} open={isVisible} onOk={handleSave} onCancel={handleCancel}>
			<Form {...baseStyles.formLayout} form={formProfessionalAccreditationCommentEdit} name="accreditation-comment-details">
				<Form.Item label={t('professionals.comment')} name="comment" rules={[{ required: true }]}>
					<TextArea />
				</Form.Item>
				<Form.Item label={t('professionals.dateTime')} name="commentDateTime" rules={[{ required: true }]}>
					<DatePicker locale={locale} format={'DD/MM/YYYY HH:mm'} showTime />
				</Form.Item>
				<Form.Item label={t('professionals.isPrivate')} name="isPrivate" valuePropName="checked">
					<Switch />
				</Form.Item>
			</Form>
		</SizedModal>
	);
};
export default FormProfessionalAccreditationCommentEdit;
