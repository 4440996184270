import { useEffect, useState } from 'react';
import { Select, Upload, DatePicker, Form, Col, Row, Switch, Input, message, Alert } from 'antd';
import { useStore } from 'react-redux';
import { professionalDocumentsSelectors, professionalDocumentTypeSelectors } from '../../store/selectors';
import { initialProfessionalDocumentValue, ProfessionalDocument, ProfessionalDocumentType } from '../../store/types';
import { InboxOutlined } from '@ant-design/icons';
import { dateFormat } from '../../assets/formats';
import { DraggerProps, RcFile } from 'antd/lib/upload';
import { baseStyles } from '../../assets/styles';
import { uploadProfessionalDocument } from '../../store/api';
import { DocumentViewer, SizedModal } from '../../components';
import { useTranslation } from 'react-i18next';
import { professionalDocuments } from '../../store/reducers';
import { useAppConfigContext } from '../../utils';
import dayjs from 'dayjs';

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;
const key = 'saving';

/**
 * Form to display a form for a document
 */
const FormProfessionalDocumentEdit = ({ professionalId, documentId, showProperties, open, title, onClose }) => {
	const store = useStore();
	const { t } = useTranslation();
	const [formDocument] = Form.useForm();
	const { token, user } = useAppConfigContext();
	const [professionalDocumentTypes, setProfessionalDocumentTypes] = useState<ProfessionalDocumentType[]>([]);
	const [professionalDocument, setProfessionalDocument] = useState<ProfessionalDocument>(initialProfessionalDocumentValue);
	const [isValidData, setIsValidData] = useState<boolean>(true);
	const [isPDF, setIsPDF] = useState<boolean>(true);
	const [url, setUrl] = useState<string>('');
	const [file, setFile] = useState<RcFile>(undefined);
	const [modified, setModified] = useState<boolean>(false);

	useEffect(() => {
		var item: ProfessionalDocument = professionalDocumentsSelectors.selectById(store.getState() as any, documentId) || {
			...initialProfessionalDocumentValue,
			professionalId: professionalId,
		};
		setProfessionalDocumentTypes(professionalDocumentTypeSelectors.selectAll(store.getState() as any).filter(pdt => !pdt.isDeleted));
		setProfessionalDocument(item);
		setUrl(item.url);
		setIsPDF(true);
		formDocument.setFieldsValue({
			documentType: item.documentTypeId,
			details: item.details,
			documentDate: dayjs(item.documentDate || Date.now()),
			documentUploadDateTime: dayjs(item.documentUploadDateTime || Date.now()),
			isPrivate: item.isPrivate,
		});
	}, [documentId, formDocument, open, professionalId, store]);

	const draggerProps: DraggerProps = {
		name: 'file',
		multiple: false,
		maxCount: 1,
		style: {
			width: 300,
		},
		showUploadList: false,
		accept: 'application/pdf',
		onRemove: () => {
			setFile(undefined);
		},
		beforeUpload: (file: RcFile): boolean => {
			if (file.type === 'application/pdf') {
				setModified(true);
				var url = URL.createObjectURL(file);
				setIsPDF(true);
				setFile(file);
				setUrl(url);
			} else {
				setIsPDF(false);
			}
			return false;
		},
	};

	const getUploadedFile = (e: { fileList: RcFile[] }) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const handleValuesChange = (changedValues: any) => {
		setModified(true);
		if (changedValues['documentType']) {
			var tdocumentType = professionalDocumentTypes.find(a => a.id === changedValues['documentType']);
			if (tdocumentType === undefined) {
				setIsValidData(false);
			} else {
				setIsValidData(true);
			}
		}
	};

	const handleSave = async () => {
		if (!isValidData && modified) {
			return;
		}
		const values = await formDocument.validateFields();
		if (values.errorField === undefined) {
			var temp: ProfessionalDocument = {
				...professionalDocument,
				documentDate: values['documentDate'].toDate(),
				documentTypeId: values['documentType'],
				documentUploadDateTime: values['documentUploadDateTime'].toDate(),
				details: values['details'] || '',
				isPrivate: values['isPrivate'],
				modified: modified,
			};
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var response = await uploadProfessionalDocument(temp, file, token, user);
			if (response !== undefined) {
				store.dispatch(professionalDocuments.actions.professionalDocumentCreateUpdate(response));
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
			} else message.info({ content: t('messages.dataNotSaved'), key, duration: 2 });
			setIsPDF(true);
		}
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<SizedModal size="medium" title={title} open={open} onOk={handleSave} onCancel={handleCancel}>
			{showProperties ? (
				<Row gutter={[12, 12]}>
					<Col span={9}>
						<Form {...baseStyles.formLayout} form={formDocument} onValuesChange={handleValuesChange} name="document-details">
							<Form.Item label={t('professionals.documentType')} name="documentType" rules={[{ required: true, message: 'Debe ingresar un tipo' }]}>
								<Select>
									{professionalDocumentTypes.map(dt => {
										return (
											<Option key={`documentType-${dt.id}`} value={dt.id}>
												{dt.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('professionals.details')} name="details">
								<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
							</Form.Item>
							<Form.Item label={t('professionals.documentDate')} name="documentDate" rules={[{ required: true, message: t('messages.enterDate') }]}>
								<DatePicker format={dateFormat} />
							</Form.Item>
							<Form.Item label={t('professionals.documentUploadDate')} name="documentUploadDateTime">
								<DatePicker disabled format={dateFormat} />
							</Form.Item>
							<Form.Item label={t('professionals.isPrivate')} name="isPrivate" valuePropName="checked">
								<Switch />
							</Form.Item>
							<Form.Item label={t('general.file')} name="upload" valuePropName="fileList" getValueFromEvent={getUploadedFile}>
								<Dragger {...draggerProps}>
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
								</Dragger>
							</Form.Item>
							{!isValidData ? <Alert message={t('messages.correctErrorsToContinue')} type="error" /> : null}
							{!isPDF ? <Alert message={t('messages.notPDF')} type="error" /> : null}
						</Form>
					</Col>
					<Col span={15}>
						<DocumentViewer url={url} size="medium"></DocumentViewer>
					</Col>
				</Row>
			) : (
				<DocumentViewer url={url} size="large"></DocumentViewer>
			)}
		</SizedModal>
	);
};

export default FormProfessionalDocumentEdit;
