import { colorsLightRedSalud } from './style-colors-redsalud';

export const colors = colorsLightRedSalud;

export const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

export const baseStyles = {
	popupMatchSelectWidth: 150,
	container: {
		flex: 1,
		backgroundColor: colors.foregroundColor,
		color: colors.foregroundColor,
	},
	content: {
		paddingLeft: '10px',
		minHeight: '97vh',
	},
	datePicker: {
		width: 300,
	},
	graph: {
		height: 500,
	},
	menu: {
		flex: 1,
		backgroundColor: colors.backgroundColor,
	},
	trigger: {
		backgroundColor: colors.brandColor1,
	},
	topBar: {
		backgroundColor: colors.brandColor1,
		color: colors.backgroundColor,
		overflow: 'hidden',
	},
	text: {
		color: colors.foregroundColor,
		fontSize: 14,
	},
	longText: {
		color: colors.foregroundColor,
		fontSize: 14,
		padding: 10,
	},
	filter: {
		color: colors.brandColor2,
	},
	spinner: {
		color: colors.brandColor1,
	},
	buttonCard: {
		backgroundColor: colors.brandColor2,
		color: 'white',
	},
	button: {
		justifyContent: 'center',
		backgroundColor: colors.brandColor1,
		border: colors.brandColor3,
	},
	buttonInverse: {
		justifyContent: 'center',
		backgroundColor: colors.brandColor1,
		color: colors.backgroundColor,
	},
	buttonBreadCrumbs: {
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	menuIcon: {
		color: colors.brandColor2,
	},
	formLayout: {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 16,
		},
	},
	googleMapOverlay: {
		background: 'white',
		padding: 15,
	},
	badge: {
		backgroundColor: colors.brandColor2,
		color: colors.backgroundColor,
		borderRadius: 10,
	},
	tag: {
		backgroundColor: colors.brandColor1,
		fontSize: 10,
		color: colors.backgroundColor,
		borderRadius: 10,
	},
	tagBig: {
		backgroundColor: colors.brandColor1,
		fontSize: 12,
		color: colors.backgroundColor,
		borderRadius: 10,
	},
	tagAlternative: {
		backgroundColor: colors.brandColor2,
		fontSize: 10,
		color: colors.backgroundColor,
		borderRadius: 10,
	},
	professionalType: {
		backgroundColor: colors.brandColor2,
		color: colors.backgroundColor,
		borderRadius: 10,
	},
	input: {
		borderRadius: 15,
		borderColor: colors.brandColor3,
		color: colors.defaultGray,
	},
	check: {
		fontSize: 40,
		color: 'green',
	},
	unchecked: {
		fontSize: 40,
		color: 'red',
	},
	tabs: {
		width: '100%',
		padding: 10,
		color: colors.brandColor3,
	},
	body: {
		padding: 10,
	},
	card: {
		minHeight: 400,
		margin: 10,
		borderRadius: 10,
		overflow: 'auto',
	},
	toolbar: {
		padding: 10,
	},
	avatar: {
		backgroundColor: colors.brandColor2,
		fontSize: 36,
	},
	avatarAlternative: {
		backgroundColor: colors.brandColor1,
		fontSize: 36,
	},
	header: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 5,
		paddingBottom: 5,
		backgroundColor: colors.brandColor2,
	},
	componentHeader: {
		backgroundColor: colors.brandColor1,
	},
	textHeader: {
		color: colors.foregroundColor,
	},
	search: {
		width: 300,
		borderRadius: 25,
	},
	selectFilter: {
		width: 250,
	},
	layout: {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 8,
		},
	},
};
