import { EventItem, DocumentAttachment, LinkItem } from '../types';
import { urls } from './urls';
import { parseDocumentAttachment } from './attachments';
import { ParentType, ImageAttachment } from '../../store/types/attachments';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const parseEvent = (data: any): EventItem => {
	dayjs.extend(utc);
	dayjs.extend(timezone);
	var e: EventItem = {
		id: data.id,
		professionalTypeId: data.professionalType === null ? 0 : data.professionalType.id,
		eventTypeId: data.eventType === null ? 0 : data.eventType.id,
		title: data.title,
		avatar: data.avatar,
		organizer: data.organizer,
		speaker: data.speaker ? data.speaker : '',
		location: data.location,
		countryId: data.country === null ? 0 : data.country.id,
		summary: data.summary,
		startDateTime: dayjs.utc(data.startDateTime).tz('America/Santiago').toDate().toISOString(),
		endDateTime: dayjs.utc(data.endDateTime).tz('America/Santiago').toDate().toISOString(),
		url: data.url,
		isPublished: data.isPublished,
		isDeleted: data.isDeleted,
		enrolleds: data.enrolleds.map((p: { id: number }) => p.id),
		tags: data.tags.map((t: { description: string }) => t.description),
	};
	return e;
};

/**
 * Parse a new Link Subject
 */
export const parseLinkItem = (data: any): LinkItem => {
	var l: LinkItem = {
		id: data.id,
		professionalTypeId: data.professionalType === null ? 0 : data.professionalType.id,
		title: data.title,
		avatar: data.avatar,
		summary: data.summary,
		isPublished: data.isPublished,
		url: data.url,
		publishDate: dayjs.utc(data.publishDate).tz('America/Santiago').toDate().toISOString(),
		tags: data.tags.map((t: { description: string }) => t.description),
		isDeleted: data.isDeleted,
	};
	return l;
};

export const fetchEvents = async (token, user): Promise<{ events: EventItem[]; eventAttachments: DocumentAttachment[] }> => {
	var result = {
		events: [] as EventItem[],
		eventAttachments: [] as DocumentAttachment[],
	};
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	var response = await fetch(urls.events, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var e: EventItem = parseEvent(jsonObject[i]);
		result.events.push(e);
		if (jsonObject[i].attachments !== undefined) {
			for (var j = 0; j < jsonObject[i].attachments.length; j++) {
				var a: DocumentAttachment = parseDocumentAttachment(jsonObject[i].attachments[j], e.id, ParentType.eventItem);
				result.eventAttachments.push(a);
			}
		}
	}
	return result;
};

export const fetchLinkItems = async (token, user): Promise<{ linkItems: LinkItem[]; imageAttachments: ImageAttachment[] }> => {
	var result = {
		linkItems: [] as LinkItem[],
		imageAttachments: [] as ImageAttachment[],
	};
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	var response = await fetch(urls.links, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var l: LinkItem = parseLinkItem(jsonObject[i]);
		result.linkItems.push(l);
	}
	return result;
};

export const createUpdateEvent = async (event: EventItem, token, user): Promise<EventItem | undefined> => {
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
		body: JSON.stringify(event),
	};
	var response = await fetch(urls.createUpdateEvent, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result: EventItem = parseEvent(data);
		return result;
	}
	return undefined;
};

export const createUpdateLinkItem = async (link: LinkItem, token, user): Promise<LinkItem | undefined> => {
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
		body: JSON.stringify(link),
	};
	var response = await fetch(urls.createUpdateLink, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		var result: LinkItem = parseLinkItem(data);
		return result;
	}
	return undefined;
};

export const deleteEvent = async (eventItem: EventItem, token, user): Promise<boolean> => {
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	const response = await fetch(urls.deleteEvent + `?id=${eventItem.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const deleteLink = async (linkItem: LinkItem, token, user): Promise<boolean> => {
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	const response = await fetch(urls.deleteLink + `?id=${linkItem.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const getEnrolledProfessionals = async (eventItem: EventItem, token, user): Promise<void> => {
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	const response = await fetch(urls.getEnrolledProfessionals + `?id=${eventItem.id}`, requestOptions);
	if (response.status === 200) {
		var blob = await response.blob();
		FileSaver.saveAs(blob, 'list.csv');
	}
};
