import { useEffect, useState } from 'react';
import { EventItem, initialEventValue, Professional } from '../../store/types';
import { Row, Button, Space, Table, Card } from 'antd';
import { eventsSelectors, professionalsSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { baseStyles, colors } from '../../assets/styles';
import { getEnrolledProfessionals } from '../../store/api/training';
import { useAppConfigContext } from '../../utils';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const FormEnrolleds = (props: { eventId: number }) => {
	const id = props.eventId;
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [professionals, setProfessionals] = useState<Professional[]>([]);
	const [eventItem, setEventItem] = useState<EventItem>();

	useEffect(() => {
		var item = eventsSelectors.selectById(state, id) || initialEventValue;
		setEventItem(item);
		setProfessionals(professionalsSelectors.selectAll(state).filter(p => item.enrolleds.includes(p.id)));
	}, [state, id]);

	const columns: ColumnProps<Professional>[] = [
		{
			title: t('general.lastname'),
			dataIndex: 'lastName',
			key: 'lastname',
		},
		{
			title: t('general.secondLastname'),
			dataIndex: 'secondLastName',
			key: 'secondLastName',
		},
		{
			title: t('general.names'),
			dataIndex: 'names',
			key: 'names',
		},
		{
			title: t('general.socialId'),
			dataIndex: 'socialId',
			key: 'socialId',
		},
	];

	return (
		<div>
			<Space></Space>
			<Row>
				<Card
					size="small"
					title={t('training.enrolleds')}
					style={{ ...styles.card, width: '95%' }}
					extra={
						<Button
							type="primary"
							style={{ backgroundColor: colors.brandColor2 }}
							icon={<DownloadOutlined />}
							shape="round"
							onClick={() => getEnrolledProfessionals(eventItem, token, user)}
						>
							{t('general.download')}
						</Button>
					}
				>
					<Table<Professional> rowKey={record => record.id} tableLayout="auto" size="small" dataSource={professionals} columns={columns} pagination={false} />
				</Card>
			</Row>
		</div>
	);
};
export default FormEnrolleds;
