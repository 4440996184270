/* eslint-disable no-restricted-globals */
import dayjs from 'dayjs';
import { FilterDataType } from '../types/clinical-notes';

const filterNotes = (data: FilterDataType) => {
	var filters = data.filters;
	var patientData = data.patientData;
	if (patientData === undefined) self.postMessage([]);
	var startDate = data.startDate;
	var endDate = data.endDate;
	var result = patientData.episodios;
	if (patientData !== undefined) {
		if (startDate !== '' && endDate !== '') {
			var tempStartDate = dayjs(startDate);
			var tempEndDate = dayjs(endDate) || dayjs();
			result = result.filter(e => dayjs(e.fecha) >= tempStartDate && dayjs(e.fecha) <= tempEndDate);
		}
		filters.forEach(f => {
			var filterType = f.type;
			var filterValue = f.value;
			switch (filterType) {
				case 'professionals':
					result = result.filter(e => e.profesional?.nombre_completo === filterValue);
					break;
				case 'specialties':
					result = result.filter(e => e.profesional?.especialidad === filterValue);
					break;
				case 'centers':
					result = result.filter(e => e.establecimiento?.descripcion === filterValue);
					break;
				case 'systems':
					result = result.filter(e => e.sistema === filterValue);
					break;
				case 'types':
					result = result.filter(e => e.tipo === filterValue);
					break;
			}
		});
	}
	return result;
};

export default filterNotes;
