import { ReactNode, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Space, DatePicker, Input, Card, Button, message, Form, Select, Row, Col } from 'antd';
import { Popconfirm, Tooltip, Table, Typography, Collapse, AutoComplete, Switch, Layout, Breadcrumb } from 'antd';
import { AuthRoutes, EditableCell, FormPersonIndex, FormTaskDetails, SizedModal } from '../../components';
import {
	Priority,
	Center,
	Task,
	User,
	Comment,
	initialCommentValue,
	TaskStatus,
	TaskType,
	initialCaseValue,
	Case,
	Person,
	initialTaskValue,
} from '../../store/types';
import { caseTypesSelectors, gendersSelectors, prioritiesSelectors, taskStatusesSelectors, taskTypesSelectors } from '../../store/selectors/domains';
import { centersSelectors, commentsSelectors, usersSelectors } from '../../store/selectors';
import { cases as casesReducer, comments as commentsReducer, tasks as tasksReducer } from '../../store/reducers';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {
	ApartmentOutlined,
	ArrowLeftOutlined,
	DeleteOutlined,
	EditOutlined,
	FilterFilled,
	PlusOutlined,
	RollbackOutlined,
	SaveOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { dateFormat, baseStyles, colors } from '../../assets';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import {
	createUpdateCase,
	createUpdateCaseComment,
	createUpdateTask,
	deleteActivityComment,
	deleteTask,
	fetchCaseComments,
	fetchCaseTasks,
} from '../../store/api/management';
import { casesSelectors, personsSelectors, tasksSelectors } from '../../store/selectors/management';
import { CaseType, Gender } from '../../store/types/domains';
import { removeItem, includes, useAppConfigContext } from '../../utils';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Content } = Layout;

const key = 'saving';

const PageCaseDetails = () => {
	const store = useStore();
	const location = useLocation();
	const navigate = useNavigate();
	const params = location.state as any;
	var id: number = parseInt(params.id);
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [formCaseDetails] = Form.useForm();
	const [formComment] = Form.useForm();
	const [title, setTitle] = useState<string>('');
	const [changed, setChanged] = useState<boolean>(false);
	const [caseItem, setCaseItem] = useState<Case>(initialCaseValue);
	const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [priorities, setPriorities] = useState<Priority[]>([]);
	const [users, setUsers] = useState<User[]>([]);
	const [patient, setPatient] = useState<Person>();
	const [contact, setContact] = useState<Person>();
	const [genders, setGender] = useState<Gender[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
	const [selectedTask, setSelectedTask] = useState<Task>(initialTaskValue);
	const [tasks, setTasks] = useState<Task[]>([]);
	const [taskStatuses, setTaskStatuses] = useState<TaskStatus[]>([]);
	const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
	const [comments, setComments] = useState<Comment[]>([]);
	const [editingCommentKey, setEditingCommentKey] = useState<number>(0);
	const [isloadingComments, setIsLoadingComments] = useState(true);
	const [isloadingUsers, setIsLoadingUsers] = useState(true);
	const [isloadingTasks, setIsLoadingTasks] = useState(true);
	const [searchText, setSearchText] = useState<string>('');
	const [options, setOptions] = useState([]);
	const [personId, setPersonId] = useState<number>(0);
	const [isPatient, setIsPatient] = useState<boolean>(true);
	const [isTaskModalVisible, setIsTaskModalVisible] = useState<boolean>(false);
	const [isPersonModalVisible, setIsPersonModalVisible] = useState<boolean>(false);
	const [span, setSpan] = useState<number>(24);

	window.onresize = () => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
	};

	useEffect(() => {
		window.innerWidth > 1600 ? setSpan(12) : setSpan(24);
		setTaskStatuses(taskStatusesSelectors.selectAll(store.getState() as any));
		setTaskTypes(taskTypesSelectors.selectAll(store.getState() as any));
		setCaseTypes(caseTypesSelectors.selectAll(store.getState() as any));
		setPriorities(prioritiesSelectors.selectAll(store.getState() as any));
		setGender(gendersSelectors.selectAll(store.getState() as any));
		var persons = personsSelectors.selectAll(store.getState() as any);
		setCenters(
			centersSelectors
				.selectAll(store.getState() as any)
				.filter(c => !c.isDeleted && c.isVisible)
				.sort((a, b) => a.name.localeCompare(b.name)),
		);
		var item: Case = casesSelectors.selectById(store.getState() as any, id) || initialCaseValue;
		setPatient(persons.find(p => p.id === item.patientId));
		setContact(persons.find(p => p.id === item.contactId));
		setCaseItem(item);
		var userItems = usersSelectors.selectAll(store.getState() as any).filter(u => !u.isDeleted);
		setUsers(userItems);
		setSelectedUsers(userItems.filter(u => item.participantsId.includes(u.id)));
		setIsLoadingUsers(false);
		fetchCaseComments(id, token, user).then(items => {
			setComments(items.filter(c => !c.isDeleted));
			setIsLoadingComments(false);
		});
		fetchCaseTasks(id, token, user).then(items => {
			setTasks(items.filter(t => item.tasksIds.includes(t.id) && !t.isDeleted));
			setIsLoadingTasks(false);
		});
		setTitle(item.description || t('management.newCase'));
		formCaseDetails.setFieldsValue({
			reason: item.reason,
			subject: item.subject,
			description: item.description || t('management.newCase'),
			caseNumber: item.caseNumber.toUpperCase(),
			caseType: item.caseTypeId || 1,
			isClosed: item.isClosed,
			creationDate: dayjs(item.creationDate || Date.now()),
			priority: item.priorityId || 1,
			origin: item.originId || 1,
		});
	}, [id, formCaseDetails, store, t, token, user]);

	const isEditingComment = (item: Comment) => item.id === editingCommentKey;

	const handleEditComment = (item: Comment) => {
		formComment.setFieldsValue({
			...item,
		});
		setEditingCommentKey(item.id);
	};

	const handleAddComment = () => {
		const newData: Comment = initialCommentValue;
		setComments([...comments, newData]);
	};

	const handleDeleteComment = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			var ok = await deleteActivityComment(item, token, user);
			if (ok) {
				var temp = { ...item, isDeleted: true };
				store.dispatch(commentsReducer.actions.commmentsCreateUpdate(temp));
				setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.activityId === id && !c.isDeleted));
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
		setIsLoadingComments(false);
	};

	const handleCommentCancel = () => {
		setComments(comments.slice(0, comments.length - 1));
		setEditingCommentKey(0);
	};

	const handleAddTask = () => {
		setIsTaskModalVisible(true);
	};

	const handleDeleteTask = async item => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		try {
			var temp: Case = {
				...caseItem,
				tasksIds: removeItem(caseItem.tasksIds, item.id),
			};
			var deleteTaskResponse = await deleteTask(item, token, user);
			if (deleteTaskResponse) {
				store.dispatch(tasksReducer.actions.tasksCreateUpdate({ ...item, isDeleted: true }));
				var updateCaseResponse = await createUpdateCase(temp, token, user);
				if (updateCaseResponse !== undefined) {
					store.dispatch(casesReducer.actions.casesCreateUpdate(updateCaseResponse));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setCaseItem(casesSelectors.selectById(store.getState() as any, id) || initialCaseValue);
					setTasks(tasksSelectors.selectAll(store.getState() as any).filter(t => updateCaseResponse.tasksIds.includes(t.id) && !t.isDeleted));
					setChanged(false);
				} else message.error(t('message.unableToDelete'));
			} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleCaseSave = async () => {
		try {
			const values = await formCaseDetails.validateFields();
			if (values.errorField === undefined) {
				var temp: Case = {
					...caseItem,
					reason: values['reason'],
					subject: values['subject'],
					description: values['description'],
					caseTypeId: values['caseType'],
					closedDate: values['closeDate'],
					priorityId: values['priority'],
					isClosed: values['isClosed'],
					participantsId: selectedUsers.map(u => {
						return u.id;
					}),
					originId: values['origin'],
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdateCase(temp, token, user);
				if (response !== undefined) {
					store.dispatch(casesReducer.actions.casesCreateUpdate(response));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setCaseItem(casesSelectors.selectById(store.getState() as any, id) || initialCaseValue);
					setChanged(false);
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleCommentSave = async (item: Comment) => {
		setIsLoadingComments(true);
		try {
			const values = await formComment.validateFields();
			if (values.errorField === undefined) {
				var temp: Comment = {
					...item,
					id: item.id,
					activityId: 0,
					caseId: id,
					description: values['description'],
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var response = await createUpdateCaseComment(temp, id, token, user);
				if (response !== undefined) {
					store.dispatch(commentsReducer.actions.commmentsCreateUpdate(response));
					setComments(commentsSelectors.selectAll(store.getState() as any).filter(c => c.caseId === id && !c.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.info({ content: t('messages.errorOnSave'), key, duration: 2 });
				setEditingCommentKey(0);
			}
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
			setEditingCommentKey(0);
		}
		setIsLoadingComments(false);
	};

	const handleSearchUser = (searchText: string) => {
		setSearchText(searchText);
		if (searchText !== '') {
			var seleted = users
				.filter(u => u.username.includes(searchText))
				.map(u => {
					return { value: u.username };
				});
			setOptions(!searchText ? [] : seleted);
		}
	};

	const handleSelectUser = (seletedData: string) => {
		setChanged(true);
		setSearchText('');
		var user = users.find(u => u.username === seletedData);
		if (user && !caseItem.participantsId.includes(user.id)) {
			setChanged(true);
			setSelectedUsers([...selectedUsers, user]);
		}
		setOptions([]);
	};

	const handleTaskSave = async () => {
		setIsTaskModalVisible(false);
		try {
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			if (caseItem.id === 0) {
				var responseCase = await createUpdateCase(caseItem, token, user);
				if (responseCase !== undefined) {
					store.dispatch(casesReducer.actions.casesCreateUpdate(responseCase));
					setCaseItem(casesSelectors.selectById(store.getState() as any, id) || initialCaseValue);
				} else {
					message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
					return;
				}
			}
			var responseTask = await createUpdateTask(selectedTask, 0, id, token, user);
			if (responseTask !== undefined) {
				var tempCase: Case = {
					...caseItem,
				};
				if (!tempCase.tasksIds.includes(responseTask.id))
					tempCase = {
						...caseItem,
						tasksIds: caseItem.tasksIds.concat(responseTask.id),
					};
				responseCase = await createUpdateCase(tempCase, token, user);
				if (responseCase !== undefined) {
					store.dispatch(casesReducer.actions.casesCreateUpdate(responseCase));
					setCaseItem(responseCase);
					store.dispatch(tasksReducer.actions.tasksCreateUpdate(responseTask));
					setTasks(tasksSelectors.selectAll(store.getState() as any).filter(t => responseCase.tasksIds.includes(t.id) && !t.isDeleted));
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
					setChanged(false);
				}
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleDeleteUser = (item: User) => {
		setChanged(true);
		setSelectedUsers(selectedUsers.filter(u => u.id !== item.id));
	};

	const handleValuesChange = (changedValues: any) => {
		setChanged(true);
		if (changedValues['description']) setTitle(changedValues['description']);
	};

	const columns: ColumnProps<User>[] = [
		{
			title: t('management.description'),
			dataIndex: 'username',
			key: 'username',
			width: '50%',
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: User): ReactNode => {
				return (
					<Space size="middle">
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteUser(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsTask: ColumnProps<Task>[] = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			width: '50%',
		},
		{
			title: t('management.creationDate'),
			dataIndex: 'creationDate',
			key: 'creationDate',
			width: 150,
			sorter: (a, b) => dayjs(a.creationDate).diff(dayjs(b.creationDate), 'seconds'),
			render: (_text: string, item: Task): ReactNode => <Text>{item.creationDate ? dayjs(item.creationDate).format(dateFormat) : ''}</Text>,
		},
		{
			title: t('management.taskType'),
			dataIndex: 'taskTypeId',
			key: 'taskTypeId',
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: taskTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.taskTypeId === value,
			render: (_text: string, item: Task): ReactNode => <Text>{taskTypes.find(t => t.id === item.taskTypeId)?.description}</Text>,
		},
		{
			title: t('management.taskStatus'),
			dataIndex: 'taskStatusId',
			key: 'taskStatusId',
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: taskStatuses.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.taskStatusId === value,
			render: (_text: string, item: Task): ReactNode => <Text>{taskStatuses.find(t => t.id === item.taskStatusId)?.description}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Task): ReactNode => {
				return (
					<Space size="middle">
						<Link to={AuthRoutes.tasks + `/details`} state={{ id: item.id, activityId: id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteTask(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsComment = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
			editable: true,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Comment): ReactNode => {
				const editable = isEditingComment(item);
				return editable ? (
					<Space>
						<Button type="primary" shape="round" onClick={() => handleCommentSave(item)} icon={<SaveOutlined />} />
						<Popconfirm title={t('messages.confirmCancel')} onConfirm={handleCommentCancel} okText={t('general.ok')} cancelText={t('general.cancel')}>
							<Button type="primary" shape="round" icon={<RollbackOutlined />} />
						</Popconfirm>
					</Space>
				) : (
					<Space size="middle">
						<Tooltip title={t('general.edit')}>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									handleEditComment(item);
								}}
								icon={<EditOutlined />}
							/>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteComment(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const columnsMergedComment = columnsComment.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (item: Comment) => ({
				item,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditingComment(item),
			}),
		};
	});

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.cases') },
								{ title: title },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						{changed ? (
							<Popconfirm
								title={t('messages.confirmExit')}
								onConfirm={() => {
									navigate(AuthRoutes.cases + '/index');
								}}
								okText={t('general.ok')}
								cancelText={t('general.cancel')}
							>
								<Tooltip title={t('general.back')}>
									<Button icon={<ArrowLeftOutlined />} type="primary" shape="round">
										{t('general.back')}
									</Button>
								</Tooltip>
							</Popconfirm>
						) : (
							<Tooltip title={t('general.back')}>
								<Button icon={<ArrowLeftOutlined />} type="primary" shape="round" onClick={() => navigate(AuthRoutes.cases + '/index')}>
									{t('general.back')}
								</Button>
							</Tooltip>
						)}
						,
						<Button type="primary" disabled={!changed} shape="round" icon={<SaveOutlined />} onClick={handleCaseSave}>
							{t('general.save')}
						</Button>
						,
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col span={span}>
							<Card size="small" title={t('management.details')} style={styles.card}>
								<Form {...baseStyles.formLayout} form={formCaseDetails} name="activity-details" onValuesChange={handleValuesChange}>
									<Form.Item label={t('management.caseNumber')} name="caseNumber">
										<Input disabled />
									</Form.Item>
									<Form.Item label={t('management.caseType')} name="caseType" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{caseTypes.map(p => {
												return (
													<Option key={`caseType-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('management.reason')} name="reason">
										<Input />
									</Form.Item>
									<Form.Item label={t('management.subject')} name="subject">
										<Input />
									</Form.Item>
									<Form.Item label={t('management.description')} name="description">
										<TextArea />
									</Form.Item>
									<Form.Item label={t('management.origin')} name="origin" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) => includes((option!.children as unknown as string).toLowerCase(), input.toLowerCase())}
										>
											{centers.map(p => {
												return (
													<Option key={`center-${p.id}`} value={p.id}>
														{p.name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('management.priority')} name="priority" rules={[{ required: true, message: t('messages.enterValue') }]}>
										<Select>
											{priorities.map(p => {
												return (
													<Option key={`priority-${p.id}`} value={p.id}>
														{p.description}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label={t('management.isClosed')} name="isClosed" valuePropName="checked">
										<Switch
											onChange={value => {
												formCaseDetails.setFieldsValue({
													closeDate: value ? dayjs(Date.now()) : '',
												});
											}}
										/>
									</Form.Item>
									<Form.Item label={t('management.closeDate')} name="closeDate">
										<DatePicker locale={locale} format={dateFormat} />
									</Form.Item>
								</Form>
								<Collapse accordion>
									<Panel
										header={
											patient
												? `${t('management.patientInformation')} : ${patient?.lastName} ${patient?.secondLastName}, ${patient?.names} (${patient?.socialId})`
												: t('management.patientInformation')
										}
										key="1"
										extra={
											<Button
												type="primary"
												shape="round"
												icon={<UserOutlined />}
												onClick={() => {
													setIsPatient(true);
													setIsPersonModalVisible(true);
												}}
											/>
										}
									>
										<Form {...baseStyles.formLayout} form={formCaseDetails} name="patient-details">
											<Form.Item label={t('general.fullName')}>
												<Input value={patient ? `${patient?.names} ${patient?.lastName} ${patient?.secondLastName}` : ''} disabled />
											</Form.Item>
											<Form.Item label={t('general.birthdate')}>
												<Input value={patient ? dayjs(patient?.birthDate).format(dateFormat) : ''} disabled />
											</Form.Item>
											<Form.Item label={t('general.gender')}>
												<Input value={genders.find(g => g.id === patient?.genderId)?.description} disabled />
											</Form.Item>
											<Form.Item label={t('general.socialId')}>
												<Input value={patient?.socialId} disabled />
											</Form.Item>
										</Form>
									</Panel>
									<Panel
										header={
											contact
												? `${t('management.contactInformation')} : ${contact?.lastName} ${contact?.secondLastName}, ${contact?.names} (${contact?.socialId})`
												: t('management.contactInformation')
										}
										key="2"
										extra={
											<Button
												type="primary"
												shape="round"
												icon={<UserOutlined />}
												onClick={() => {
													setIsPatient(false);
													setIsPersonModalVisible(true);
												}}
											/>
										}
									>
										<Form {...baseStyles.formLayout} form={formCaseDetails} name="patient-details">
											<Form.Item label={t('general.fullName')}>
												<Input value={contact ? `${contact?.names} ${contact?.lastName} ${contact?.secondLastName}` : ''} disabled />
											</Form.Item>
											<Form.Item label={t('general.birthdate')}>
												<Input value={contact ? dayjs(contact?.birthDate).format(dateFormat) : ''} disabled />
											</Form.Item>
											<Form.Item label={t('general.gender')}>
												<Input value={genders.find(g => g.id === contact?.genderId)?.description} disabled />
											</Form.Item>
											<Form.Item label={t('general.socialId')}>
												<Input value={contact?.socialId} disabled />
											</Form.Item>
										</Form>
									</Panel>
								</Collapse>
							</Card>
						</Col>
						<Col span={span}>
							<Card size="small" title={t('management.users')} style={styles.card}>
								<div className="body">
									<AutoComplete
										style={{ width: '100%' }}
										value={searchText}
										options={options}
										onSelect={handleSelectUser}
										onSearch={handleSearchUser}
										placeholder={t('management.searchUser')}
									/>
								</div>
								<Table<User>
									rowKey={item => item.id}
									loading={isloadingUsers}
									key={`news-table`}
									tableLayout="auto"
									size="small"
									dataSource={selectedUsers}
									columns={columns}
									pagination={false}
								/>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.tasks')}
								style={styles.card}
								extra={<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddTask}></Button>}
							>
								<Table<Task>
									rowKey={item => item.id}
									loading={isloadingTasks}
									key={`tasks-table`}
									tableLayout="auto"
									size="small"
									dataSource={tasks}
									columns={columnsTask}
									pagination={false}
								/>
							</Card>
						</Col>
						<Col span={span}>
							<Card
								size="small"
								title={t('management.comments')}
								style={styles.card}
								extra={<Button type="primary" shape="round" style={styles.buttonCard} icon={<PlusOutlined />} onClick={handleAddComment}></Button>}
							>
								<Form form={formComment} component={false}>
									<Table<Comment>
										components={{
											body: {
												cell: EditableCell,
											},
										}}
										rowKey={item => item.id}
										loading={isloadingComments}
										key={`comments-table`}
										tableLayout="auto"
										size="small"
										dataSource={comments}
										columns={columnsMergedComment}
										pagination={false}
									/>
								</Form>
							</Card>
						</Col>
					</Row>
				</Content>
				<SizedModal
					size="medium"
					title={caseItem.description}
					open={isTaskModalVisible}
					bodyStyle={{ backgroundColor: 'lightGray' }}
					onCancel={() => {
						setIsTaskModalVisible(false);
					}}
					onOk={() => {
						handleTaskSave();
					}}
				>
					<FormTaskDetails taskId={selectedTask.id} setSelectedTask={setSelectedTask}></FormTaskDetails>
				</SizedModal>
				<SizedModal
					size="medium"
					title={caseItem.description}
					open={isPersonModalVisible}
					bodyStyle={{ backgroundColor: 'lightGray' }}
					onCancel={() => {
						setIsPersonModalVisible(false);
					}}
					onOk={() => {
						setIsPersonModalVisible(false);
						if (isPatient) {
							setChanged(true);
							setCaseItem({ ...caseItem, patientId: personId[0] });
							setPatient(personsSelectors.selectById(store.getState() as any, personId[0]));
						} else {
							setChanged(true);
							setCaseItem({ ...caseItem, contactId: personId[0] });
							setContact(personsSelectors.selectById(store.getState() as any, personId[0]));
						}
					}}
				>
					<FormPersonIndex setPersonId={setPersonId}></FormPersonIndex>
				</SizedModal>
			</Col>
		</div>
	);
};

export default PageCaseDetails;
