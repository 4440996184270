import { Col, Row, Typography, Button, Steps, Space, Avatar, Image, Layout, Card, QRCode } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NonAuthRoutes, RequiredAuth } from '../../components';
import { urls } from '../../store/api';
import { colors } from '../../assets/styles';
import background from '../../assets/images/background-redsalud-santiago.jpg';
import iosLogo from '../../assets/images/ios_logo.png';
import androidLogo from '../../assets/images/android_logo.png';
import iosStep2 from '../../assets/images/ios_step2.jpg';
import iosStep3 from '../../assets/images/ios_step3.jpg';
import androidStep1 from '../../assets/images/android_step1.jpg';
import androidStep2 from '../../assets/images/android_step2.jpg';
import androidStep3 from '../../assets/images/android_step3.jpg';
import { useAppConfigContext } from '../../utils';

const { Title, Text } = Typography;
const { Content } = Layout;

const PageAppDownload = () => {
	const { t } = useTranslation();
	const { initialConfig, user } = useAppConfigContext();
	const [iosLink, setIosLink] = useState<string>('');
	const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
	const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
	const [osSelected, setOsSelected] = useState<boolean>(false);
	const [isIos, setIsIos] = useState<boolean>(false);
	const [current, setCurrent] = useState(0);
	const navigate = useNavigate();
	document.title = initialConfig.systemName;

	window.onresize = () => {
		setInnerHeight(window.innerHeight);
		setInnerWidth(window.innerWidth);
	};

	const fetchIosRedeemCode = async () => {
		var redeemCode = await fetch(`${urls.getIOsRedeemCode}?username=${user?.username}&isAssigned=true`);
		if (redeemCode.ok) {
			var url = await redeemCode.text();
			setIosLink(url);
		}
	};

	const onChange = (value: number) => {
		setCurrent(value);
	};

	const stepsIos = [
		{
			key: 'iosStep1',
			title: t('messages.downloadAppFromQR'),
		},
		{
			key: 'iosStep2',
			title: t('messages.getApp'),
		},
		{
			key: 'iosStep3',
			title: t('messages.openApp'),
		},
	];

	const contentsIos = [
		<Col>
			<Row justify="center">
				<QRCode size={innerHeight * 0.25} value={iosLink} />
			</Row>
			<Row justify="center">
				<Button
					style={{ marginTop: 10, marginLeft: innerWidth * 0.05, marginRight: innerWidth * 0.05 }}
					block
					type="primary"
					shape="round"
					onClick={() => window.open(iosLink, '_blank', 'noopener,noreferrer')}
				>
					{t('general.getApp')}
				</Button>
			</Row>
			<Row>
				<Title level={3}>{t('messages.iosTextStep1')}</Title>
			</Row>
			<Row align="middle">
				<Col></Col>
				<Col flex={1}></Col>
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(1)}>
						{t('general.next')}
					</Button>
				</Col>
			</Row>
		</Col>,
		<Col>
			<Row justify="center" style={{ height: innerHeight * 0.25 }}>
				<Image height="100%" width="auto" src={iosStep2}></Image>
			</Row>
			<Row>
				<Title level={3}>{t('messages.iosTextStep2')}</Title>
			</Row>
			<Row align="middle">
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(0)}>
						{t('general.previous')}
					</Button>
				</Col>
				<Col flex={1}></Col>
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(2)}>
						{t('general.next')}
					</Button>
				</Col>
			</Row>
		</Col>,
		<Col>
			<Row justify="center" style={{ height: innerHeight * 0.25 }}>
				<Image height="100%" width="auto" src={iosStep3}></Image>
			</Row>
			<Row>
				<Title level={3}>{t('messages.iosTextStep3')}</Title>
			</Row>
			<Row align="middle">
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(1)}>
						{t('general.previous')}
					</Button>
				</Col>
				<Col flex={1}></Col>
				<Col>
					<Button type="primary" shape="round" onClick={() => navigate(NonAuthRoutes.login)}>
						{t('messages.backToLogin')}
					</Button>
				</Col>
			</Row>
		</Col>,
	];

	const stepsAndroid = [
		{
			key: 'androidStep1',
			title: t('messages.downloadAppFromQR'),
		},
		{
			key: 'androidStep2',
			title: t('messages.confirmDownload'),
		},
		{
			key: 'androidStep3',
			title: t('messages.installApp'),
		},
		{
			key: 'androidStep4',
			title: t('messages.openApp'),
		},
	];

	const contentsAndroid = [
		<Col>
			<Row justify="center">
				<QRCode
					size={innerHeight * 0.25}
					value="https://storage.googleapis.com/bucket-prod-redsalud-cliniciansapp/binaries/android/somos_redsalud_android_latest.apk"
				/>
			</Row>
			<Row justify="center">
				<Button
					style={{ marginTop: 10, marginLeft: innerWidth * 0.05, marginRight: innerWidth * 0.05 }}
					block
					type="primary"
					shape="round"
					onClick={() =>
						window.open(
							'https://storage.googleapis.com/bucket-prod-redsalud-cliniciansapp/binaries/android/somos_redsalud_android_latest.apk',
							'_blank',
							'noopener,noreferrer',
						)
					}
				>
					{t('general.getApp')}
				</Button>
			</Row>
			<Row>
				<Title level={3}>{t('messages.androidTextStep1')}</Title>
			</Row>
			<Row align="middle">
				<Col></Col>
				<Col flex={1} style={{ paddingLeft: 10, paddingRight: 10 }}></Col>
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(1)}>
						{t('general.next')}
					</Button>
				</Col>
			</Row>
		</Col>,
		<Col>
			<Row justify="center" style={{ height: innerHeight * 0.25 }}>
				<Image height="100%" width="auto" src={androidStep1}></Image>
			</Row>
			<Row>
				<Title level={3}>{t('messages.androidTextStep2')}</Title>
			</Row>
			<Row align="middle">
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(0)}>
						{t('general.previous')}
					</Button>
				</Col>
				<Col flex={1}></Col>
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(2)}>
						{t('general.next')}
					</Button>
				</Col>
			</Row>
		</Col>,
		<Col>
			<Row justify="center" style={{ height: innerHeight * 0.25 }}>
				<Image height="100%" width="auto" src={androidStep2}></Image>
			</Row>
			<Row>
				<Title level={3}>{t('messages.androidTextStep3')}</Title>
			</Row>
			<Row align="middle">
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(1)}>
						{t('general.previous')}
					</Button>
				</Col>
				<Col flex={1}></Col>
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(3)}>
						{t('general.next')}
					</Button>
				</Col>
			</Row>
		</Col>,
		<Col>
			<Row justify="center" style={{ height: innerHeight * 0.25 }}>
				<Image height="100%" width="auto" src={androidStep3}></Image>
			</Row>
			<Row>
				<Title level={3}>{t('messages.androidTextStep4')}</Title>
			</Row>
			<Row align="middle">
				<Col>
					<Button type="primary" shape="round" onClick={() => setCurrent(2)}>
						{t('general.previous')}
					</Button>
				</Col>
				<Col flex={1}></Col>
				<Col>
					<Button type="primary" shape="round" onClick={() => navigate(NonAuthRoutes.login)}>
						{t('messages.backToLogin')}
					</Button>
				</Col>
			</Row>
		</Col>,
	];

	if (!osSelected)
		return (
			<RequiredAuth>
				<Layout style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
					<Content>
						<Col>
							<Row style={{ height: '5vh' }}></Row>
							<Row style={{ height: '30vh' }}>
								<Col flex={1}></Col>
								<Col>
									<Card
										title={
											t('messages.startPage') + ' (' + initialConfig.version + ' - ' + process.env.REACT_APP_ENVIRONMENT + ' - ' + initialConfig.language + ')'
										}
										style={{ minWidth: 400, width: '30vw', height: '100%' }}
										styles={{ header: { backgroundColor: colors.brandColor1, color: colors.backgroundColor } }}
									>
										<Row align="middle" justify="center">
											<Title>{t('messages.selectOs')}</Title>
										</Row>
										<Row align="middle" justify="center">
											<Space direction="horizontal" size="large">
												<Avatar
													size={innerHeight * 0.15}
													shape="square"
													src={iosLogo}
													onClick={() => {
														setOsSelected(true);
														setCurrent(0);
														setIsIos(true);
														fetchIosRedeemCode();
													}}
												></Avatar>
												<Avatar
													size={innerHeight * 0.15}
													shape="square"
													src={androidLogo}
													onClick={() => {
														setOsSelected(true);
														setCurrent(0);
														setIsIos(false);
													}}
												></Avatar>
											</Space>
										</Row>
										<Row style={{ height: 40 }}>
											<Text style={{ textAlign: 'center', width: '100vh' }}>{`RedSalud ©2024-2025 ${t(
												'general.createdBy',
											)} Dr. Francisco Fukuda - Dirección de Informática Médica`}</Text>
										</Row>
										<Row style={{ width: '100%' }} justify="center">
											<Col></Col>
											<Col flex={1}></Col>
											<Col>
												<Button type="primary" shape="round" onClick={() => navigate(NonAuthRoutes.login)}>
													{t('messages.backToLogin')}
												</Button>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col flex={1}></Col>
							</Row>
							<Row style={{ height: '65vh' }}></Row>
						</Col>
					</Content>
				</Layout>
			</RequiredAuth>
		);
	else
		return (
			<RequiredAuth>
				<Layout style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
					<Content>
						<Col>
							<Row style={{ height: '5vh' }}></Row>
							<Row style={{ height: '30vh' }}>
								<Col flex={1}></Col>
								<Col>
									<Card
										title={
											t('messages.startPage') + ' (' + initialConfig.version + ' - ' + process.env.REACT_APP_ENVIRONMENT + ' - ' + initialConfig.language + ')'
										}
										style={{ minWidth: 400, width: '30vw', height: '100%' }}
										styles={{ header: { backgroundColor: colors.brandColor1, color: colors.backgroundColor } }}
									>
										<Row style={{ height: 20 }} />
										<Row align="middle" justify="center">
											<Col flex={1}>
												<Steps current={current} onChange={onChange} items={isIos ? stepsIos : stepsAndroid} />
											</Col>
										</Row>
										<Row style={{ height: 20 }}></Row>
										<Row justify="center">
											<Col flex={1}>{isIos ? contentsIos[current] : contentsAndroid[current]}</Col>
										</Row>
									</Card>
								</Col>
								<Col flex={1}></Col>
							</Row>
							<Row style={{ height: '65vh' }}></Row>
						</Col>
					</Content>
				</Layout>
			</RequiredAuth>
		);
};

export default PageAppDownload;
