import { useState, ReactNode, useEffect } from 'react';
import { Privilege, PrivilegeType, PrivilegeStatus, initialPrivilegeValue } from '../../store/types';
import { useStore } from 'react-redux';
import { Table, Space, Tooltip, Button, Typography, Popconfirm, message, Card, Popover } from 'antd';
import { privilegeSelectors, privilegeTypesSelectors, privilegeStatusesSelectors } from '../../store/selectors';
import { privileges as privilegesReducer } from '../../store/reducers';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { FormProfessionalPrivilegeEdit } from '../../components';
import { deletePrivilege, fetchPrivilegesById } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../assets/formats';
import { isAuthorized } from '../../utils/util-authentication';
import { useAppConfigContext } from '../../utils';
import dayjs from 'dayjs';

const { Text } = Typography;
const key = 'saving';

const FormProfessionalPrivileges = ({ professionalId, professionalType, disabled = false }) => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [privilegeTypes, setPrivilegeTypes] = useState<PrivilegeType[]>([]);
	const [privilegeStatus, setPrivilegeStatus] = useState<PrivilegeStatus[]>([]);
	const [privileges, setPrivileges] = useState<Privilege[]>([]);
	const [privilege, setPrivilege] = useState<Privilege>(initialPrivilegeValue);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setPrivilegeTypes(privilegeTypesSelectors.selectAll(store.getState() as any));
		setPrivilegeStatus(privilegeStatusesSelectors.selectAll(store.getState() as any));
		setIsLoading(true);
		fetchPrivilegesById(professionalId, token, user).then(items => {
			store.dispatch(privilegesReducer.actions.privilegeLoading());
			store.dispatch(privilegesReducer.actions.privilegeReceived(items));
			setPrivileges(items);
			setIsLoading(false);
		});
	}, [professionalId, store, token, user]);

	const handleEdit = (item: Privilege) => {
		setPrivilege(item);
		setModalTitle(t('professionals.editPrivilege'));
		setIsModalVisible(true);
	};

	const handleAddPrivilege = () => {
		setPrivilege(initialPrivilegeValue);
		setModalTitle(t('professionals.addPrivilege'));
		setIsModalVisible(true);
	};

	const handleDelete = async (item: Privilege) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deletePrivilege(item, token, user);
		if (response) {
			setIsLoading(true);
			fetchPrivilegesById(professionalId, token, user).then(items => {
				store.dispatch(privilegesReducer.actions.privilegeLoading());
				store.dispatch(privilegesReducer.actions.privilegeReceived(items));
				setPrivileges(items);
				setIsLoading(false);
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
			});
		} else message.info(t('message.unableToDelete'));
	};

	const handleClose = () => {
		setIsModalVisible(false);
		setPrivileges(privilegeSelectors.selectAll(store.getState() as any).filter(p => p.professionalId === professionalId && !p.isDeleted));
	};

	const columns: ColumnProps<Privilege>[] = [
		{
			dataIndex: 'modifiedBy',
			key: 'modifiedBy',
			width: 20,
			render: (_text: string, item: Privilege): ReactNode => (
				<Popover
					content={
						<div>
							<Text>{t('general.modifiedBy')} </Text>
							<Text type="warning">{item.modifiedBy}</Text>
						</div>
					}
				>
					<InfoCircleTwoTone />
				</Popover>
			),
		},
		{
			title: t('professionals.privilegeType'),
			dataIndex: 'privilegeType',
			key: 'privilegeType',
			width: 250,
			render: (_text: string, item: Privilege): ReactNode => <Text>{privilegeTypes?.find(a => a.id === item.privilegeTypeId)?.description}</Text>,
		},
		{
			title: t('professionals.privilegeStatus'),
			dataIndex: 'privilegeStatus',
			key: 'privilegeStatus',
			width: 100,
			render: (_text: string, item: Privilege): ReactNode => <Text>{privilegeStatus?.find(a => a.id === item.privilegeStatusId)?.description}</Text>,
		},
		{
			title: t('professionals.dateTime'),
			dataIndex: 'privilegeDateTime',
			key: 'privilegeDateTime',
			width: 100,
			render: (_text: string, item: Privilege): ReactNode => <Text>{dayjs(item.privilegeDateTime).format(dateFormat)}</Text>,
		},
		{
			title: t('professionals.realHours'),
			dataIndex: 'realHours',
			key: 'realHours',
			width: 80,
			render: (_text: string, item: Privilege): ReactNode => <Text>{item.realHours}</Text>,
		},
		{
			title: t('professionals.requestedHours'),
			dataIndex: 'requestedHours',
			key: 'requestedHours',
			width: 80,
			render: (_text: string, item: Privilege): ReactNode => <Text>{item.requestedHours}</Text>,
		},
		{
			title: t('professionals.comments'),
			dataIndex: 'comments',
			key: 'comments',
			render: (_text: string, item: Privilege): ReactNode => <Text>{item.comments}</Text>,
		},
		{
			title: t('general.actions'),
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (_text: string, item: Privilege): ReactNode => {
				return disabled || !isAuthorized('Component_Privileges', user) ? null : (
					<Space size="small">
						<Tooltip title={t('general.edit')}>
							<Button
								style={styles.button}
								shape="round"
								size="small"
								type="primary"
								onClick={() => {
									handleEdit(item);
								}}
							>
								<EditOutlined />
							</Button>
						</Tooltip>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button size="small" shape="round" danger>
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card
			size="small"
			title={t('professionals.privileges')}
			style={styles.card}
			extra={[
				disabled || !isAuthorized('Component_Privileges', user) ? null : (
					<Button type="primary" shape="round" icon={<PlusOutlined />} style={styles.buttonCard} onClick={handleAddPrivilege}></Button>
				),
			]}
		>
			<Table<Privilege>
				key={`privileges-${Date.now()}`}
				rowKey={record => record.id}
				tableLayout="auto"
				size="small"
				dataSource={privileges}
				columns={columns}
				pagination={false}
				loading={isLoading}
			/>
			<FormProfessionalPrivilegeEdit
				title={modalTitle}
				open={isModalVisible}
				privilegeId={privilege.id}
				professionalId={professionalId}
				onClose={handleClose}
			/>
		</Card>
	);
};
export default FormProfessionalPrivileges;
