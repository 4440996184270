import { Permission, Profile, User } from '../types';
import { urls } from './urls';

/**
 * Parse a permission
 */
export const parseUser = (data: any): User => {
	var u: User = {
		id: data.id,
		username: data.username,
		permissionIds: data.permissions.map((p: { id: any }) => p.id),
		profileIds: data.profiles.map((p: { id: any }) => p.id),
		centerIds: data.centers.map((p: { id: any }) => p.id),
		professionalId: parseInt(data.professional ? data.professional.id : 0),
		externalId: data.externalId,
		networkId: parseInt(data.network ? data.network.id : 0),
		expoPushToken: data.expoPushToken,
		webLogins: data.numberOfWebLogins || 0,
		mobileLogins: data.numberOfMobileLogins || 0,
		isDeleted: data.isDeleted,
	};
	return u;
};

/**
 * Parse a permission
 */
export const parsePermission = (data: any): Permission => {
	var p: Permission = {
		id: data.id,
		code: data.code,
		description: data.description,
		isDeleted: data.isDeleted,
	};
	return p;
};

/**
 * Parse a new Link Subject
 */
export const parseProfile = (data: any): Profile => {
	var p: Profile = {
		id: data.id,
		code: data.code,
		description: data.description,
		permissionIds: data.permissions.map((p: any) => p.id),
		isDeleted: data.isDeleted,
	};
	return p;
};

export const fetchPermissions = async (token, user): Promise<Permission[]> => {
	var result: Permission[] = [] as Permission[];
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	var response = await fetch(urls.permissions, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var p: Permission = parsePermission(jsonObject[i]);
		result.push(p);
	}
	return result;
};

export const fetchProfiles = async (token, user): Promise<Profile[]> => {
	var result: Profile[] = [] as Profile[];
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	var response = await fetch(urls.profiles, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var p: Profile = parseProfile(jsonObject[i]);
		result.push(p);
	}
	return result;
};

export const fetchUsers = async (token, user): Promise<User[]> => {
	var result: User[] = [] as User[];
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	var response = await fetch(urls.users, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var u: User = parseUser(jsonObject[i]);
		result.push(u);
	}
	return result;
};

export const createUpdateUser = async (user, token, currentUser): Promise<User | undefined> => {
	var temp: User;
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${currentUser?.username}`,
		},
		body: JSON.stringify(user),
	};
	const response = await fetch(urls.createUpdateUser, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseUser(data);
		return temp;
	}
	return undefined;
};

export const deleteUser = async (user, token, currentUser): Promise<boolean> => {
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${currentUser?.username}`,
		},
	};
	const response = await fetch(urls.deleteUser + `?userId=${user.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};

export const createUpdateProfile = async (profile: Profile, token, user): Promise<Profile | undefined> => {
	var temp: Profile;
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
		body: JSON.stringify(profile),
	};
	const request = `${urls.createUpdateProfile}`;
	var response = await fetch(request, requestOptions);
	if (response.status === 200) {
		var data = await response.json();
		temp = parseProfile(data);
		return temp;
	}
	return undefined;
};
