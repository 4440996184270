import { useEffect, useState } from 'react';
import { message, Dropdown, MenuProps, Button, Layout, Breadcrumb, Col, Row, Space } from 'antd';
import { Center, initialCenterValue } from '../../store/types';
import { centersSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { FormCenterDetails, AuthRoutes } from '../../components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import { colors } from '../../assets/styles';
import { useAppConfigContext } from '../../utils';

const { Content } = Layout;

const PageViewDetails = () => {
	const store = useStore();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useAppConfigContext();
	const [centers, setCenters] = useState<Center[]>([]);
	const [center, setCenter] = useState<Center>(initialCenterValue);

	useEffect(() => {
		var includedCenters = centersSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted && user.centerIds?.includes(c.id));
		var selectedCenter = initialCenterValue;
		if (includedCenters.length === 0) {
			navigate(AuthRoutes.home);
			message.success({ content: t('messages.noCenterAssigned'), duration: 2 });
		} else selectedCenter = includedCenters[0];
		setCenters(includedCenters);
		setCenter(selectedCenter);
	}, [navigate, store, t, user.centerIds]);

	const handleMenuClick: MenuProps['onClick'] = e => {
		var selectedCenter = centers.find(c => c.id === parseInt(e.key));
		setCenter(selectedCenter);
	};

	const items: MenuProps['items'] = centers.map(c => ({
		label: c.name,
		key: c.id,
	}));

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[{ title: <MedicineBoxOutlined twoToneColor={colors.brandColor1} /> }, { title: t('view.viewContent') }, { title: center.name }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Dropdown.Button
								type="primary"
								menu={{ items, onClick: handleMenuClick }}
								overlayStyle={{ maxHeight: 200, overflowY: 'auto' }}
								buttonsRender={() => [
									<Button type="primary" shape="round" key="leftButton">
										{t('messages.changeCenter')}
									</Button>,
									<Button type="primary" shape="round" icon={<DownOutlined></DownOutlined>}></Button>,
								]}
							></Dropdown.Button>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<FormCenterDetails centerId={center.id} disabled />
				</Content>
			</Col>
		</div>
	);
};

export default PageViewDetails;
