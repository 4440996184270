import { Document, DocumentAttachment, ImageAttachment } from '../types';
import { urls } from './urls';
import { parseDocumentAttachment } from './attachments';
import { ParentType } from '../../store/types/attachments';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

/**
 * Fetch documents from microservices
 */
export const fetchDocuments = async (
	token,
	user,
): Promise<{ documents: Document[]; documentAttachments: DocumentAttachment[]; imageAttachments: ImageAttachment[] }> => {
	dayjs.extend(utc);
	dayjs.extend(timezone);
	var result = {
		documents: [] as Document[],
		documentAttachments: [] as DocumentAttachment[],
		imageAttachments: [] as ImageAttachment[],
	};
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	var response = await fetch(urls.documents, requestOptions);
	var data = await response.json();
	var jsonObject: any = data;
	for (var i = 0; i < jsonObject.length; i++) {
		var d: Document = parseDocument(jsonObject[i]);
		result.documents.push(d);
		if (jsonObject[i].attachments !== undefined) {
			for (var j = 0; j < jsonObject[i].attachments.length; j++) {
				var a: DocumentAttachment = parseDocumentAttachment(jsonObject[i].attachments[j], d.id, ParentType.documentItem);
				result.documentAttachments.push(a);
			}
		}
	}
	return result;
};

/**
 * Parse and object to a Document type
 */
export const parseDocument = (data: any): Document => {
	var d: Document = {
		id: data.id,
		professionalTypeId: data.professionalType === null ? 0 : data.professionalType.id,
		title: data.title,
		avatar: data.avatar,
		author: data.author,
		documentDate: dayjs.utc(data.documentDate).tz('America/Santiago').toDate().toISOString(),
		publishDate: dayjs.utc(data.publishDate).tz('America/Santiago').toDate().toISOString(),
		summary: data.summary,
		url: data.url,
		isPublished: data.isPublished,
		tags: data.tags.map((t: { description: string }) => t.description),
		isDeleted: data.isDeleted,
	};
	return d;
};

/**
 * Post a new Document
 */
export const createUpdateDocument = async (document: Document, token, user): Promise<Document | undefined> => {
	try {
		var bearer = 'Bearer ' + token;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${bearer}`,
				Username: `${user?.username}`,
			},
			body: JSON.stringify(document),
		};
		var response = await fetch(urls.createUpdateDocument, requestOptions);
		if (response.status === 200) {
			var data = await response.json();
			var result: Document = parseDocument(data);
			return result;
		}
	} catch (e) {
		return undefined;
	}
	return undefined;
};

export const deleteDocument = async (document: Document, token, user): Promise<boolean> => {
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			Username: `${user?.username}`,
		},
	};
	const response = await fetch(urls.deleteDocument + `?id=${document.id}`, requestOptions);
	if (response.status === 200) {
		return true;
	}
	return false;
};
