import { useEffect, useState } from 'react';
import { Space, Button, Row, Col, Typography, Avatar, AutoComplete, Card, Layout, Breadcrumb, FloatButton } from 'antd';
import { PlusOutlined, EditOutlined, LoadingOutlined, GoldTwoTone } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import { Address, Center, CenterType, Commune } from '../../store/types';
import { addressesSelectors, centersSelectors, centerTypesSelectors, communesSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { AuthRoutes } from '../../components';
import { useTranslation } from 'react-i18next';
import { includes } from '../../utils/util-custom-validators';
import { getInitials } from '../../utils/util-generics';
import { baseStyles, colors } from '../../assets/styles';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Text, Title } = Typography;
const { Content } = Layout;

const PageCentersIndex = () => {
	const navigate = useNavigate();
	const store = useStore();
	const extraItems = 20;
	const cardWidth = 250;
	const cardHeight = 350;
	const { t } = useTranslation();
	const styles = baseStyles;
	const [centerTypes, setCenterTypes] = useState<CenterType[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [partialCenters, setPartialCenters] = useState<Center[]>([]);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [index, setIndex] = useState<number>(30);
	const [addresses, setAddresses] = useState<Address[]>([]);
	const [communes, setCommunes] = useState<Commune[]>([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setCenterTypes(centerTypesSelectors.selectAll(store.getState() as any));
		var items = centersSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted);
		setCenters(items);
		setPartialCenters(items.slice(0, items.length > index ? index - 1 : items.length));
		setAddresses(addressesSelectors.selectAll(store.getState() as any));
		setCommunes(communesSelectors.selectAll(store.getState() as any));
	}, [index, store]);

	const handleNext = () => {
		var step = extraItems;
		if (index + extraItems > centers.length) {
			step = centers.length - index;
			setHasMore(false);
		}
		setPartialCenters(partialCenters.concat(centers.slice(index, step)));
		setIndex(index + step);
	};

	const handleSelect = (option: number) => {
		navigate(AuthRoutes.centers + `/details`, { state: { id: option } });
	};

	const handleSearch = (value: string) => {
		if (value) {
			var searchResult = centersSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted && includes(c.name, value));
			var searchVisualization = searchResult.map(item => {
				return {
					value: item.id,
					label: (
						<div>
							<Text>{item.name}</Text>
						</div>
					),
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[{ title: <GoldTwoTone twoToneColor={colors.brandColor1} /> }, { title: t('general.network') }, { title: t('general.centers') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Link to={AuthRoutes.centers + `/details`} state={{ id: 0 }}>
								<Button style={styles.componentHeader} type="primary" shape="round" icon={<PlusOutlined />}>
									{t('general.add')}
								</Button>
							</Link>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									options={options}
									onSelect={handleSelect}
									onSearch={handleSearch}
								/>
							</Space>
						</Col>
						<Col flex={4}> </Col>
						<Col flex={3}> </Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<InfiniteScroll dataLength={partialCenters.length} next={handleNext} hasMore={hasMore} loader={<LoadingOutlined />} style={{ overflow: 'hidden' }}>
							<Row gutter={[24, 24]}>
								<FloatButton.BackTop key="back-top" type="primary" tooltip={t('messages.backToTop')} />
								{partialCenters.map((item, index) => {
									var address = addresses.find(a => a.id === item.addressId);
									return (
										<Col key={`col-${index}`} span={window.innerWidth / cardWidth}>
											<Card
												id={`card-${index}`}
												style={{ width: cardWidth, height: cardHeight }}
												extra={[
													<Space>
														<Button onClick={() => handleSelect(item.id)} key={`edit-${item.id}`} type="primary" shape="round" icon={<EditOutlined />} />
													</Space>,
												]}
											>
												<Space direction="vertical" style={{ width: '100%', justifyContent: 'center' }}>
													<Row justify="center" style={{ paddingTop: 20 }}>
														{item.avatar !== '' ? (
															<Avatar size={120} src={`${item.avatar}?${Date.now()}`} />
														) : (
															<Avatar style={item.centerTypeId === 1 ? styles.avatar : styles.avatarAlternative} size={120}>
																{getInitials(item.name)}
															</Avatar>
														)}
													</Row>
													<Row justify="center">
														<Title ellipsis level={5}>{`${item.name}`}</Title>
													</Row>
													<Row justify="center">
														<Text strong>{centerTypes.find(c => c.id === item.centerTypeId)?.description}</Text>
													</Row>
													<Row justify="center">
														{address?.number ? (
															<Text ellipsis>
																{address?.street} {address?.number}, {communes.find(c => c.id === address?.communeId)?.description}
															</Text>
														) : (
															<Text ellipsis>
																{address?.street}, {communes.find(c => c.id === address?.communeId)?.description}
															</Text>
														)}
													</Row>
												</Space>
											</Card>
										</Col>
									);
								})}
							</Row>
						</InfiniteScroll>
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PageCentersIndex;
