import { useEffect, useRef, useState } from 'react';
import {
	MoreOutlined,
	LogoutOutlined,
	ApartmentOutlined,
	BookTwoTone,
	ControlTwoTone,
	GoldTwoTone,
	HomeOutlined,
	IdcardTwoTone,
	MedicineBoxOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Col, ConfigProvider, Dropdown, Layout, Menu, Row, Switch, theme, Typography } from 'antd';
import { IconMoon, IconSun, NonAuthRoutes, RequiredAuth, SizedModal } from '../../components';
import { Outlet, useNavigate } from 'react-router-dom';
import { countriesSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { initialNetworkValue, Network, Country } from '../../store/types';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { networksSelectors } from '../../store/selectors/network';
import { locales } from '../../App';
import { AuthRoutes, useAuth } from '../../components/main/auth-route';
import { isAuthorized } from '../../utils/util-authentication';
import { useAppConfigContext } from '../../utils/useAppConfigContext';

const { Text } = Typography;
const { Footer, Content, Header } = Layout;

const PageMain = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const store = useStore();
	const headerRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const rootSubmenuKeys = ['menu-center', 'menu-management', 'menu-professionals', 'menu-network', 'menu-content', 'menu-configuration'];
	const auth = useAuth();
	const styles = baseStyles;
	const { initialConfig, user, isDarkMode, setCountry, setIsDarkMode } = useAppConfigContext();
	const [network, setNetworks] = useState<Network>(initialNetworkValue);
	const [countries, setCountries] = useState<Country[]>([]);
	const [openKeys, setOpenKeys] = useState([]);
	const [headerHeight, setHeaderHeight] = useState<number>(0);
	const [footerPosition, setFooterPosition] = useState<number>(0);
	const [isModalOpen, setIsModalOpen] = useState(false);

	window.onresize = () => {
		var headerHeight = headerRef.current ? headerRef.current.clientHeight : 1;
		setHeaderHeight(headerHeight);
		setFooterPosition(window.innerHeight - headerHeight + 24);
	};

	useEffect(() => {
		document.title = initialConfig.systemName;
		setCountry(countries.find(c => c.id === network.countryId)?.code || 'CL');
		var headerHeight = headerRef.current ? headerRef.current.clientHeight : 1;
		setNetworks(networksSelectors.selectById(store.getState() as any, 1) || initialNetworkValue);
		setCountries(countriesSelectors.selectAll(store.getState() as any));
		setHeaderHeight(headerHeight);
		setFooterPosition(window.innerHeight - headerHeight + 24);
	}, [auth, countries, initialConfig.systemName, network.countryId, setCountry, store]);

	const handleClick = () => {
		setIsModalOpen(true);
	};

	const handleOpenChange = keys => {
		const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	const menuItems = () => {
		var items = [];
		items.push({
			label: t('general.home'),
			key: 'menu-home',
			icon: <HomeOutlined twoToneColor={colors.brandColor1} />,
		});
		var menuView = {
			label: t('view.viewContent'),
			key: 'menu-view',
			icon: <MedicineBoxOutlined twoToneColor={colors.brandColor1} />,
			children: [],
		};
		if (isAuthorized('Page_CenterProfessionals', user))
			menuView.children.push({
				label: t('view.professionals'),
				key: 'menu-viewProfessionals',
			});
		if (isAuthorized('Page_CenterNews', user))
			menuView.children.push({
				label: t('view.news'),
				key: 'menu-viewNews',
			});
		if (isAuthorized('Page_CenterDocuments', user))
			menuView.children.push({
				label: t('view.documents'),
				key: 'menu-viewDocuments',
			});
		if (isAuthorized('Page_CenterLinks', user))
			menuView.children.push({
				label: t('view.links'),
				key: 'menu-viewLinks',
			});
		if (menuView.children.length > 0) items.push(menuView);
		var menuManagement = {
			label: t('management.management'),
			key: 'menu-management',
			icon: <ApartmentOutlined twoToneColor={colors.brandColor1} />,
			children: [],
		};
		if (isAuthorized('Page_EHRTools', user))
			menuManagement.children.push({
				label: t('management.ehrTools'),
				key: 'menu-ehr',
			});
		if (isAuthorized('Page_Activities', user))
			menuManagement.children.push({
				label: t('management.activities'),
				key: 'menu-activities',
			});
		if (isAuthorized('Page_Cases', user))
			menuManagement.children.push({
				label: t('management.cases'),
				key: 'menu-cases',
			});
		if (isAuthorized('Page_Tasks', user))
			menuManagement.children.push({
				label: t('management.tasks'),
				key: 'menu-tasks',
			});
		if (isAuthorized('Page_Persons', user))
			menuManagement.children.push({
				label: t('management.persons'),
				key: 'menu-persons',
			});
		if (menuManagement.children.length > 0) items.push(menuManagement);
		var menuProfessionals = {
			label: t('general.professionals'),
			key: 'menu-professionals',
			icon: <IdcardTwoTone twoToneColor={colors.brandColor1} />,
			children: [],
		};
		if (isAuthorized('Page_Physicians', user))
			menuProfessionals.children.push({
				label: t('general.physicians'),
				key: 'menu-physicians',
			});
		if (isAuthorized('Page_Dentists', user))
			menuProfessionals.children.push({
				label: t('general.dentists'),
				key: 'menu-dentists',
			});
		if (isAuthorized('Page_Nurses', user))
			menuProfessionals.children.push({
				label: t('general.nurses'),
				key: 'menu-nurses',
			});
		if (isAuthorized('Page_Kinesiologists', user))
			menuProfessionals.children.push({
				label: t('general.kinesiologists'),
				key: 'menu-kinesiologists',
			});
		if (isAuthorized('Page_Psychologist', user))
			menuProfessionals.children.push({
				label: t('general.psychologists'),
				key: 'menu-psychologists',
			});
		if (isAuthorized('Page_Nutritionists', user))
			menuProfessionals.children.push({
				label: t('general.nutricionists'),
				key: 'menu-nutricionists',
			});
		if (isAuthorized('Page_MedicalTechnologists', user))
			menuProfessionals.children.push({
				label: t('general.medicalTechnologists'),
				key: 'menu-medicalTechnologists',
			});
		if (menuProfessionals.children.length > 0) items.push(menuProfessionals);
		var menuNetwork = {
			label: t('general.network'),
			key: 'menu-network',
			icon: <GoldTwoTone twoToneColor={colors.brandColor1} />,
			children: [],
		};
		if (isAuthorized('Page_Centers', user))
			menuNetwork.children.push({
				label: t('general.centers'),
				key: 'menu-centers',
			});
		if (isAuthorized('Page_Services', user))
			menuNetwork.children.push({
				label: t('general.services'),
				key: 'menu-services',
			});
		if (menuNetwork.children.length > 0) items.push(menuNetwork);
		var menuContent = {
			label: t('general.content'),
			key: 'menu-content',
			icon: <BookTwoTone twoToneColor={colors.brandColor1} />,
			children: [],
		};
		if (isAuthorized('Page_News', user)) {
			menuContent.children.push({
				label: t('general.news'),
				key: 'menu-news',
			});
		}
		if (isAuthorized('Page_Documents', user)) {
			menuContent.children.push({
				label: t('general.documents'),
				key: 'menu-documents',
			});
		}
		if (isAuthorized('Page_Events', user))
			menuContent.children.push({
				label: t('general.events'),
				key: 'menu-events',
			});
		if (isAuthorized('Page_Links', user))
			menuContent.children.push({
				label: t('general.links'),
				key: 'menu-links',
			});
		if (menuContent.children.length > 0) items.push(menuContent);
		var menuConfiguration = {
			label: t('general.configuration'),
			key: 'menu-configuration',
			icon: <ControlTwoTone twoToneColor={colors.brandColor1} />,
			children: [],
		};
		if (isAuthorized('Page_Users', user))
			menuConfiguration.children.push({
				label: t('general.users'),
				key: 'menu-users',
			});
		if (isAuthorized('Page_Domains', user))
			menuConfiguration.children.push({
				label: t('general.domains'),
				key: 'menu-domains',
			});
		if (menuConfiguration.children.length > 0) items.push(menuConfiguration);
		return items;
	};

	const handleMenuClick = (item: any) => {
		switch (item.key) {
			case 'menu-home':
				navigate(AuthRoutes.home);
				break;
			case 'menu-viewDetails':
				navigate(AuthRoutes.viewDetails);
				break;
			case 'menu-viewProfessionals':
				navigate(AuthRoutes.viewProfessionals);
				break;
			case 'menu-viewNews':
				navigate(AuthRoutes.viewNews);
				break;
			case 'menu-viewDocuments':
				navigate(AuthRoutes.viewDocuments);
				break;
			case 'menu-viewLinks':
				navigate(AuthRoutes.viewLinks);
				break;
			case 'menu-ehr':
				navigate(AuthRoutes.ehr + '/index');
				break;
			case 'menu-activities':
				navigate(AuthRoutes.activities + '/index');
				break;
			case 'menu-cases':
				navigate(AuthRoutes.cases + '/index');
				break;
			case 'menu-tasks':
				navigate(AuthRoutes.tasks + '/index');
				break;
			case 'menu-persons':
				navigate(AuthRoutes.persons + '/index');
				break;
			case 'menu-physicians':
				navigate(AuthRoutes.physicians + '/index', { state: { professionalCode: 'ME' } });
				break;
			case 'menu-nurses':
				navigate(AuthRoutes.nurses + '/index', { state: { professionalCode: 'EN' } });
				break;
			case 'menu-kinesiologists':
				navigate(AuthRoutes.kinesiologists + '/index', { state: { professionalCode: 'KS' } });
				break;
			case 'menu-psychologists':
				navigate(AuthRoutes.psychologists + '/index', { state: { professionalCode: 'PS' } });
				break;
			case 'menu-dentists':
				navigate(AuthRoutes.dentists + '/index', { state: { professionalCode: 'OD' } });
				break;
			case 'menu-nutricionists':
				navigate(AuthRoutes.nutritionists + '/index', { state: { professionalCode: 'NU' } });
				break;
			case 'menu-medicalTechnologists':
				navigate(AuthRoutes.medicalTechnologists + '/index', { state: { professionalCode: 'TM' } });
				break;
			case 'menu-centers':
				navigate(AuthRoutes.centers + '/index');
				break;
			case 'menu-services':
				navigate(AuthRoutes.services + '/index');
				break;
			case 'menu-news':
				navigate(AuthRoutes.news + '/index');
				break;
			case 'menu-documents':
				navigate(AuthRoutes.documents + '/index');
				break;
			case 'menu-events':
				navigate(AuthRoutes.events + '/index');
				break;
			case 'menu-links':
				navigate(AuthRoutes.links + '/index');
				break;
			case 'menu-users':
				navigate(AuthRoutes.configuration + '/users');
				break;
			case 'menu-domains':
				navigate(AuthRoutes.configuration + '/domains');
				break;
		}
	};

	const items = [
		{
			key: '1',
			label: (
				<Col>
					<Row justify="center">
						<Avatar size={120} gap={10} src={user?.imageUrl}></Avatar>
					</Row>
					<Row justify="center">
						<Text strong>{`${user?.givenName} ${user?.familyName}`}</Text>
					</Row>
					<Row justify="center">
						<Text>{user?.username}</Text>
					</Row>
				</Col>
			),
		},
		{
			key: '2',
			label: <Text onClick={handleClick}>{t('general.logout')}</Text>,
			icon: <LogoutOutlined onClick={handleClick} />,
		},
	];

	return (
		<RequiredAuth>
			<ConfigProvider
				locale={locales[initialConfig.language]}
				theme={{
					token: { colorPrimary: colors.brandColor1, colorSuccess: colors.brandColor2 },
					algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
					components: {
						Input: {
							colorTextQuaternary: isDarkMode ? '#141414' : '#fff',
							algorithm: true,
						},
						DatePicker: { colorTextQuaternary: isDarkMode ? '#141414' : '#fff', algorithm: true },
					},
				}}
			>
				<Layout>
					<Content>
						<Header ref={headerRef} style={{ background: isDarkMode ? '#141414' : '#fff', position: 'fixed', width: '100%', top: 0, zIndex: 1 }}>
							<Row>
								<Col span={12}>
									<Row>
										<Col flex={1}>
											<Menu
												style={{ minWidth: 0, flex: 'auto' }}
												defaultSelectedKeys={['menu-home']}
												mode="horizontal"
												openKeys={openKeys}
												triggerSubMenuAction="click"
												onClick={handleMenuClick}
												subMenuCloseDelay={0.5}
												onOpenChange={handleOpenChange}
												items={menuItems()}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={12}>
									<Row justify="end" align="middle" gutter={16}>
										<Col>
											<Row>
												<Text strong ellipsis>{`${t('general.welcome')} ${user?.givenName ? user?.givenName : ''}`}</Text>
											</Row>
										</Col>
										<Col>
											<Dropdown menu={{ items }} trigger={['click']}>
												<Button key="more" type="primary" shape="round" icon={<MoreOutlined />}></Button>
											</Dropdown>
										</Col>
										<Col>
											<Switch
												onChange={(checked: boolean) => {
													setIsDarkMode(checked);
												}}
												checked={isDarkMode}
												unCheckedChildren={<IconSun />}
												checkedChildren={<IconMoon />}
											></Switch>
										</Col>
									</Row>
								</Col>
							</Row>
						</Header>
						<Content ref={contentRef} className="site-layout" style={{ ...styles.content, paddingTop: headerHeight }}>
							<Outlet />
						</Content>
					</Content>
					<Footer style={{ position: 'fixed', paddingTop: 5, height: 40, textAlign: 'center', width: '100%', top: footerPosition }}>{`RedSalud ©2024-2025 ${t(
						'general.createdBy',
					)} Dr. Francisco Fukuda - Dirección de Informática Médica`}</Footer>
				</Layout>
				<SizedModal
					size="xsmall"
					open={isModalOpen}
					title={t('general.logout')}
					okText={t('general.ok')}
					cancelText={t('general.cancel')}
					onOk={() => {
						navigate(NonAuthRoutes.login);
					}}
				>
					<Text>{t('messages.confirmExitMessage')}</Text>
				</SizedModal>
			</ConfigProvider>
		</RequiredAuth>
	);
};
export default PageMain;
