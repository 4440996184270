import { useState, ReactNode, useEffect } from 'react';
import { useStore } from 'react-redux';
import { Card, Typography, Table } from 'antd';
import { Center, CenterType } from '../../store/types';
import { centersSelectors, centerTypesSelectors } from '../../store/selectors';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { fetchProfessional } from '../../store/api';
import { baseStyles } from '../../assets/styles';
import { useAppConfigContext } from '../../utils';

const { Text } = Typography;

const FormProfessionalCentersData = ({ professionalId }) => {
	const store = useStore();
	const { t } = useTranslation();
	const styles = baseStyles;
	const { token, user } = useAppConfigContext();
	const [centerTypes, setCenterTypes] = useState<CenterType[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);

	useEffect(() => {
		fetchProfessional(professionalId, token, user).then(item => {
			setCenters(centersSelectors.selectAll(store.getState() as any).filter(c => c.isVisible && item.centers.includes(c.id)));
		});
		setCenterTypes(centerTypesSelectors.selectAll(store.getState() as any));
	}, [professionalId, store, token, user]);

	const columns: ColumnProps<Center>[] = [
		{
			title: t('professionals.centerName'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('professionals.centerType'),
			dataIndex: 'centerTypeId',
			key: 'centerTypeId',
			render: (_text: string, item: Center): ReactNode => {
				return <Text>{centerTypes.find(c => c.id === item.centerTypeId)?.description}</Text>;
			},
		},
	];

	return (
		<Card size="small" title={t('professionals.privileges')} style={styles.card}>
			<Table<Center> rowKey={item => item.id} tableLayout="auto" size="small" dataSource={centers} pagination={false} columns={columns} />
		</Card>
	);
};
export default FormProfessionalCentersData;
