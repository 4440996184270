import { useEffect, useState } from 'react';
import { Professional, initialProfessionalValue, ProfessionalType, initialSimpleDomainValue, SimpleDomain } from '../../store/types';
import { Col, Tabs, message, Button, Space, Tag, Popconfirm, Layout, Row, Breadcrumb, Tooltip } from 'antd';
import { professionals as professionalsReducer } from '../../store/reducers';
import { useStore } from 'react-redux';
import { accreditationsSelectors, professionalsSelectors } from '../../store/selectors/professional';
import { accreditationStatusesSelectors, professionalTypeSelectors } from '../../store/selectors';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Components from '../../components';
import * as Icons from '@ant-design/icons';
import { createUpdateProfessional } from '../../store/api';
import { ArrowLeftOutlined, DeleteOutlined, IdcardTwoTone, SaveOutlined } from '@ant-design/icons';
import { baseStyles, colors } from '../../assets/styles';
import { deleteProfessional, fetchProfessional } from '../../store/api/professional';
import { isAuthorized } from '../../utils/util-authentication';
import { AuthRoutes } from '../../components';
import { useAppConfigContext } from '../../utils';
import dayjs from 'dayjs';

const key = 'saving';

const { Content } = Layout;
const {
	FormProfessionalDetails,
	FormProfessionalContactData,
	FormProfessionalAccomplishmentsData,
	FormProfessionalAccreditationData,
	FormProfessionalCenters,
	FormProfessionalSummary,
	FormProfessionalTimeline,
	FormConfirmExitWithoutSaving,
} = Components;

const PageProfessionalDetails = () => {
	const { t } = useTranslation();
	const store = useStore();
	const styles = baseStyles;
	const navigate = useNavigate();
	const params = useLocation().state as any;
	const disabled: boolean = params.disabled;
	const previousRoute = params.route;
	const professionalTypeId: number = parseInt(params.professionalTypeId);
	const { token, user, initialConfig } = useAppConfigContext();
	const [professionalId, setProfessionalId] = useState<number>(parseInt(params.id));
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [changed, setChanged] = useState<boolean>(false);
	const [confirmExitOpen, setConfirmExitOpen] = useState<boolean>(false);
	const [professionalCode, setProfessionalCode] = useState<string>('');
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [professional, setProfessional] = useState<Professional>({ ...initialProfessionalValue, professionalTypeId: professionalTypeId });
	const [accreditationStatus, setAccreditationStatus] = useState<SimpleDomain>(initialSimpleDomainValue);
	const [hasErrors, setHasErrors] = useState<boolean>(false);

	const tabs = [
		{
			icon: <Icons.ProfileOutlined />,
			label: t('professionals.details'),
			key: 0,
			children: (
				<FormProfessionalDetails
					professionalId={professionalId}
					professionalTypeId={professionalTypeId}
					setProfessionalData={setProfessional}
					setHasErrors={setHasErrors}
					setIsChanged={setChanged}
				/>
			),
		},
		{
			icon: <Icons.EnvironmentOutlined />,
			label: t('professionals.contactData'),
			disabled: isDisabled,
			key: 1,
			children: <FormProfessionalContactData professionalId={professionalId} />,
		},
		{
			icon: <Icons.BellOutlined />,
			label: t('general.training'),
			key: 2,
			disabled: isDisabled,
			children: <FormProfessionalAccomplishmentsData professionalId={professionalId} professionalType={professionalTypeId} />,
		},
		{
			icon: <Icons.SafetyCertificateOutlined />,
			label: t('professionals.accreditation'),
			key: 3,
			disabled: isDisabled,
			children: (
				<FormProfessionalAccreditationData
					professionalId={professionalId}
					professionalType={professionalTypeId}
					setProfessionalData={setProfessional}
					setIsChanged={setChanged}
				/>
			),
		},
		{
			icon: <Icons.MedicineBoxOutlined />,
			label: t('general.centers'),
			key: 5,
			disabled: isDisabled,
			children: <FormProfessionalCenters professionalId={professionalId} setProfessionalData={setProfessional} setIsChanged={setChanged} />,
		},
		{
			icon: <Icons.ContainerOutlined />,
			label: t('general.summary'),
			key: 6,
			disabled: isDisabled,
			children: <FormProfessionalSummary professionalId={professionalId} professionalType={professionalTypeId} />,
		},
		{
			icon: <Icons.FieldTimeOutlined />,
			label: t('professionals.timeline'),
			key: 7,
			disabled: isDisabled,
			children: <FormProfessionalTimeline professionalId={professionalId} professionalType={professionalTypeId} />,
		},
	];

	useEffect(() => {
		var accreditationItems = accreditationsSelectors
			.selectAll(store.getState() as any)
			.filter(a => a.professionalId === professionalId)
			.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)));
		var accreditationStatusItems = accreditationStatusesSelectors.selectAll(store.getState() as any);
		setAccreditationStatus(
			accreditationItems.length > 0 ? accreditationStatusItems.find(acs => acs.id === accreditationItems[0].accreditationStatusId) : undefined,
		);
		var items = professionalTypeSelectors.selectAll(store.getState() as any);
		setProfessionalTypes(items);
		fetchProfessional(professionalId, token, user).then(item => setProfessional(item));
		setIsDisabled(professionalId === 0 ? true : false);
		setProfessionalCode(items.find(i => i.id === professionalTypeId).code);
	}, [professionalId, professionalTypeId, store, token, user]);

	const handleSave = async () => {
		try {
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var response = await createUpdateProfessional(professional, token, user, initialConfig.networkId);
			if (response !== undefined) {
				store.dispatch(professionalsReducer.actions.professionalsCreateUpdate(response));
				setProfessionalId(response.id);
				setIsDisabled(response.id === 0 ? true : false);
				setProfessional(professionalsSelectors.selectById(store.getState() as any, professionalId) || initialProfessionalValue);
				message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				setChanged(false);
			} else message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};

	const handleDelete = async () => {
		try {
			message.loading({ content: t('messages.saving'), key, duration: 0 });
			var response = await deleteProfessional(professional, token, user);
			if (response) {
				var temp = { ...professional, isDeleted: true };
				store.dispatch(professionalsReducer.actions.professionalsCreateUpdate(temp));
				message.success({ content: t('messages.itemDeleted'), key, duration: 2 });
				navigate(previousRoute, { state: { professionalCode: professionalCode } });
			} else message.error({ content: t('messages.unableToDelete'), key, duration: 2 });
		} catch (errorInfo) {
			message.error({ content: t('messages.errorOnSave'), key, duration: 2 });
		}
	};
	const getPath = (id: any) => {
		var code = professionalTypeSelectors.selectById(store.getState() as any, id)?.code;
		var path = '';
		switch (code) {
			case 'ME':
				path = AuthRoutes.physicians + '/index';
				break;
			case 'EN':
				path = AuthRoutes.nurses + '/index';
				break;
			case 'KS':
				path = AuthRoutes.kinesiologists + '/index';
				break;
			case 'PS':
				path = AuthRoutes.psychologists + '/index';
				break;
			case 'OD':
				path = AuthRoutes.dentists + '/index';
				break;
			case 'NU':
				path = AuthRoutes.nutritionists + '/index';
				break;
			case 'TM':
				path = AuthRoutes.medicalTechnologists + '/index';
				break;
		}
		return path;
	};

	const getState = (id: any) => {
		return professionalTypeSelectors.selectById(store.getState() as any, id)?.code;
	};

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[
								{ title: <IdcardTwoTone twoToneColor={colors.brandColor1} /> },
								{ title: t('general.professionals') },
								{
									title: (
										<Link to={{ pathname: getPath(professionalTypeId) }} state={{ professionalCode: getState(professionalTypeId) }}>
											{professionalTypes.find(p => p.id === professionalTypeId)?.description}
										</Link>
									),
								},
								{ title: professional?.lastName + ' ' + professional?.secondLastName + ', ' + professional?.names },
								{
									title:
										accreditationStatus?.code === 'DA' ? (
											<Tag style={{ background: 'red', ...styles.tagAlternative }}>{accreditationStatus?.description.toUpperCase()}</Tag>
										) : (
											<Tag style={styles.tagAlternative}>{accreditationStatus?.description.toUpperCase()}</Tag>
										),
								},
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							{changed ? (
								<Popconfirm
									title={t('messages.confirmExit')}
									onConfirm={() => {
										navigate(getPath(professionalTypeId), { state: { professionalCode: getState(professionalTypeId) } });
									}}
									okText={t('general.ok')}
									cancelText={t('general.cancel')}
								>
									<Tooltip title={t('general.back')}>
										<Button icon={<ArrowLeftOutlined />} type="primary" shape="round">
											{t('general.back')}
										</Button>
									</Tooltip>
								</Popconfirm>
							) : (
								<Tooltip title={t('general.back')}>
									<Button
										icon={<ArrowLeftOutlined />}
										type="primary"
										shape="round"
										onClick={() => navigate(getPath(professionalTypeId), { state: { professionalCode: getState(professionalTypeId) } })}
									>
										{t('general.back')}
									</Button>
								</Tooltip>
							)}
							<Button key="button-save" disabled={!changed || hasErrors} type="primary" shape="round" onClick={handleSave} icon={<SaveOutlined />}>
								{t('general.save')}
							</Button>
							{isAuthorized('Delete_Professional', user) ? (
								<Popconfirm title={t('messages.confirmDeleteItem')} onConfirm={handleDelete} okText={t('general.ok')} cancelText={t('general.cancel')}>
									<Button style={{ background: 'red' }} key="button-delete" type="primary" shape="round" icon={<DeleteOutlined />}>
										{t('general.delete')}
									</Button>
								</Popconfirm>
							) : null}
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background" style={{ paddingBottom: 20, minHeight: '100vh' }}>
					{!disabled ? (
						<Tabs
							type="card"
							defaultActiveKey="0"
							style={{ width: '100%', padding: 10 }}
							items={tabs.map(item => {
								return {
									label: item.label,
									icon: item.icon,
									key: 'tab-' + item.key.toString(),
									disabled: item.disabled,
									children: item.children,
								};
							})}
						/>
					) : (
						<Tabs
							type="card"
							defaultActiveKey="0"
							style={{ width: '100%', padding: 10 }}
							items={[
								{
									label: t('general.summary'),
									icon: <Icons.ContainerOutlined />,
									key: 'tab-1',
									disabled: professionalId === 0 ? true : false,
									children: <Components.FormProfessionalSummary professionalId={professionalId} professionalType={professionalTypeId} />,
								},
							]}
						/>
					)}
				</Content>
			</Col>
			<FormConfirmExitWithoutSaving
				open={confirmExitOpen}
				onOk={() => {
					setConfirmExitOpen(false);
					navigate(previousRoute, { state: { professionalCode: professionalCode } });
				}}
				onCancel={() => {
					setConfirmExitOpen(false);
				}}
			></FormConfirmExitWithoutSaving>
		</div>
	);
};
export default PageProfessionalDetails;
