import { Space, Typography, Tag } from 'antd';
import { ClockCircleOutlined, LaptopOutlined } from '@ant-design/icons';
import { timeFormat, colors } from '../../assets';
import dayjs from 'dayjs';
import IconClinic from '../svg/icon-clinic';
import IconStethoscope from '../svg/icon-stethoscope';

const { Text } = Typography;

const NodeEncounter = ({ date, professional_name, professional_specialty, system, scope, type, location }) => {
	switch (scope) {
		case 'Quirúrgico':
			return (
				<Space direction="vertical">
					<Space direction="horizontal" wrap>
						<ClockCircleOutlined />
						<Text style={{ width: 100 }}>{dayjs(date).format(timeFormat)}</Text>
						<Text>{professional_name}</Text>
						{professional_specialty ? (
							<Tag color={colors.brandColor2} icon={<IconStethoscope></IconStethoscope>}>
								{professional_specialty}
							</Tag>
						) : null}
					</Space>
					<Space direction="horizontal" size={1} wrap>
						<Tag color={colors.brandColor1} icon={<LaptopOutlined />}>
							{system}
						</Tag>
						<Tag color={colors.brandColor1}>{scope}</Tag>
						<Tag color={colors.brandColor1}>{type}</Tag>
						<Tag color={colors.brandColor1} icon={<IconClinic></IconClinic>}>
							{location}
						</Tag>
					</Space>
				</Space>
			);
		case 'Hospitalario':
			return (
				<Space direction="vertical">
					<Space direction="horizontal" wrap>
						<ClockCircleOutlined />
						<Text style={{ width: 100 }}>{dayjs(date).format(timeFormat)}</Text>
						<Text>{professional_name}</Text>
						{professional_specialty ? (
							<Tag color={colors.brandColor2} icon={<IconStethoscope></IconStethoscope>}>
								{professional_specialty}
							</Tag>
						) : null}
					</Space>
					<Space direction="horizontal" size={1} wrap>
						<Tag color={colors.brandColor1} icon={<LaptopOutlined />}>
							{system}
						</Tag>
						<Tag color={colors.brandColor1}>{scope}</Tag>
						<Tag color={colors.brandColor1}>{type}</Tag>
						<Tag color={colors.brandColor1} icon={<IconClinic></IconClinic>}>
							{location}
						</Tag>
					</Space>
				</Space>
			);
		case 'Ambulatorio':
			return (
				<Space direction="vertical">
					<Space direction="horizontal" wrap>
						<ClockCircleOutlined />
						<Text style={{ width: 100 }}>{dayjs(date).format(timeFormat)}</Text>
						<Text>{professional_name}</Text>
						{professional_specialty ? (
							<Tag color={colors.brandColor2} icon={<IconStethoscope></IconStethoscope>}>
								{professional_specialty}
							</Tag>
						) : null}
					</Space>
					<Space direction="horizontal" size={1} wrap>
						<Tag color={colors.brandColor1} icon={<LaptopOutlined />}>
							{system}
						</Tag>
						<Tag color={colors.brandColor1}>{scope}</Tag>
						<Tag color={colors.brandColor1}>{type}</Tag>
						<Tag color={colors.brandColor1} icon={<IconClinic></IconClinic>}>
							{location}
						</Tag>
					</Space>
				</Space>
			);
	}
};

export default NodeEncounter;
