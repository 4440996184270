export interface NameType {
	propertyName: string;
	propertyType: string;
}

export const uuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const getProperty = <T, K extends keyof T>(object: T, propertyName: K) => {
	return object[propertyName];
};

export const getTypeOfProperty = <T, K extends keyof T>(object: T, propertyName: K) => {
	return typeof object[propertyName];
};

export const getPropertiesAndTypes = (objectType: any): NameType[] => {
	var result: NameType[] = [];
	var properties: string[] = Object.keys(objectType);
	properties.forEach(p => {
		result.push({
			propertyName: p,
			propertyType: getTypeOfProperty(objectType, p),
		});
	});
	return result;
};

export const base64toBlob = (base64Data: string, contentType: string) => {
	contentType = contentType || '';
	var sliceSize = 1024;
	var byteCharacters = atob(base64Data);
	var bytesLength = byteCharacters.length;
	var slicesCount = Math.ceil(bytesLength / sliceSize);
	var byteArrays = new Array(slicesCount);

	for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		var begin = sliceIndex * sliceSize;
		var end = Math.min(begin + sliceSize, bytesLength);

		var bytes = new Array(end - begin);
		for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, { type: contentType });
};

export const formatSocialId = (text: string) => {
	var leftPart = text.substring(0, text.length - 2);
	const thousands = /\B(?=(\d{3})+(?!\d))/g;
	leftPart = leftPart.replace(thousands, '.');
	return leftPart + text.substring(text.length - 2, text.length);
};

export const getInitials = (text: string) => {
	var splited = text.split(' ');
	if (splited.length === 1 && splited[0].length < 3) return '??';
	else if (splited.length === 1) return splited[0].substr(0, 2).toUpperCase();
	else if (splited.length === 3 && splited[1].length === 1) return `${splited[0].charAt(0).toUpperCase()}${splited[2].charAt(0).toUpperCase()}`;
	else if (splited.length >= 2) return `${splited[0].charAt(0).toUpperCase()}${splited[1].charAt(0).toUpperCase()}`;
};

export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
	var tempArr = [];
	arr.forEach(e => {
		if (e !== value) tempArr.push(e);
	});
	return tempArr;
}
