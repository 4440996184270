import { Key, ReactNode, useEffect, useState } from 'react';
import { AutoComplete, Breadcrumb, Button, Card, Col, Form, Input, Layout, message, Row, Select, Space, Table, Tabs, Tooltip, Typography } from 'antd';
import { initialUserValue, Permission, Professional, ProfessionalType, Profile, User } from '../../store/types';
import { permissionSelectors, professionalsSelectors, professionalTypeSelectors, profileSelectors, usersSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { TableRowSelection } from 'antd/lib/table/interface';
import { centersSelectors, networksSelectors } from '../../store/selectors/network';
import { Center, Network } from '../../store/types/network';
import { useTranslation } from 'react-i18next';
import { users as usersReducer } from '../../store/reducers';
import { createUpdateUser } from '../../store/api';
import { includes, isValidRedSaludEmail, objectsAreEquals, userExists } from '../../utils/util-custom-validators';
import { baseStyles, colors } from '../../assets/styles';
import { ColumnProps } from 'antd/lib/table';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, ClearOutlined, ControlTwoTone, SaveOutlined } from '@ant-design/icons';
import { AuthRoutes } from '../../components';
import { useAppConfigContext } from '../../utils';

const key = 'saving';

const { Option } = Select;
const { Text } = Typography;
const { Content } = Layout;

const PageConfigurationUserEdit = () => {
	const store = useStore();
	const navigate = useNavigate();
	const location = useLocation();
	const params = location.state as any;
	const id = params.id;
	const { t } = useTranslation();
	const styles = baseStyles;
	const { token, user, setUser, isDarkMode } = useAppConfigContext();
	const [formConfigurationUserEdit] = Form.useForm();
	const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
	const [professional, setProfessional] = useState<Professional>();
	const [professionals, setProfessionals] = useState<Professional[]>([]);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [permissions, setPermissions] = useState<Permission[]>([]);
	const [profiles, setProfiles] = useState<Profile[]>([]);
	const [originalUser, setOriginalUser] = useState<User>(initialUserValue);
	const [tUser, setTUser] = useState<User>(initialUserValue);
	const [users, setUsers] = useState<User[]>([]);
	const [networks, setNetworks] = useState<Network[]>([]);
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const [isValidData, setIsValidData] = useState<boolean>(true);

	window.onresize = () => {
		setInnerHeight(window.innerHeight);
	};

	useEffect(() => {
		setProfessionalTypes(professionalTypeSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted));
		var professionalItems = professionalsSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted);
		setProfessionals(professionalItems);
		setCenters(centersSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted && p.isVisible));
		setPermissions(permissionSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted));
		setProfiles(profileSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted));
		var networkItems = networksSelectors.selectAll(store.getState() as any);
		setNetworks(networkItems);
		setUsers(usersSelectors.selectAll(store.getState() as any));
		var item = usersSelectors.selectById(store.getState() as any, id) || initialUserValue;
		var selectedProfessional = professionalItems.find(p => p.id === item.professionalId);
		setProfessional(selectedProfessional);
		setOriginalUser(item);
		setTUser(item);
		formConfigurationUserEdit.setFieldsValue({
			username: item.username,
			network: networkItems[0].description,
			professional: selectedProfessional ? `${selectedProfessional?.lastName} ${selectedProfessional?.secondLastName}, ${selectedProfessional?.names}` : '',
			externalId: item.externalId,
			expoPushToken: item.expoPushToken,
		});
	}, [store, formConfigurationUserEdit, t, id]);

	const handleSearch = value => {
		if (value) {
			setProfessionals(
				professionalsSelectors
					.selectAll(store.getState() as any)
					.filter(
						p => (includes(p.names, value) || includes(p.lastName, value) || includes(p.socialId, value) || includes(p.secondLastName, value)) && !p.isDeleted,
					),
			);
		} else setProfessionals(professionalsSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted));
	};

	const professionalColumns: ColumnProps<Professional>[] = [
		{
			title: t('general.names'),
			dataIndex: 'names',
			key: 'names',
		},
		{
			title: t('general.lastname'),
			dataIndex: 'lastName',
			key: 'lastName',
		},
		{
			title: t('general.secondLastname'),
			dataIndex: 'secondLastName',
			key: 'secondLastName',
		},
		{
			title: t('general.socialId'),
			dataIndex: 'socialId',
			key: 'socialId',
		},
	];

	const profileColumns: ColumnProps<Profile>[] = [
		{
			title: t('configurations.code'),
			dataIndex: 'code',
			key: 'code',
			defaultSortOrder: 'descend',
		},
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
		},
	];

	const permissionColumns: ColumnProps<Permission>[] = [
		{
			title: t('configurations.code'),
			dataIndex: 'code',
			key: 'code',
			defaultSortOrder: 'descend',
		},
		{
			title: t('general.description'),
			dataIndex: 'description',
			key: 'description',
		},
	];

	const centerColumns: ColumnProps<Center>[] = [
		{
			title: t('general.name'),
			dataIndex: 'name',
			key: 'name',
			defaultSortOrder: 'descend',
			width: '70%',
		},
		{
			title: t('configurations.code'),
			dataIndex: 'internalCode',
			key: 'internalCode',
			width: '30%',
			render: (_text: string, item: Center): ReactNode => <Text>{item.internalCode.toUpperCase()}</Text>,
		},
	];

	const permissionRowSelection: TableRowSelection<Permission> = {
		onChange: (_selectedRowKeys: Key[], selectedRows: Permission[]) => {
			setTUser({ ...tUser, permissionIds: selectedRows.map(k => k.id) });
			setIsChanged(!objectsAreEquals(originalUser, { ...tUser, permissionIds: selectedRows.map(k => k.id) }));
		},
		selectedRowKeys: tUser?.permissionIds,
	};

	const profileRowSelection: TableRowSelection<Profile> = {
		onChange: (_selectedRowKeys: Key[], selectedRows: Profile[]) => {
			setTUser({ ...tUser, profileIds: selectedRows.map(k => k.id) });
			setIsChanged(!objectsAreEquals(originalUser, { ...tUser, profileIds: selectedRows.map(k => k.id) }));
		},
		selectedRowKeys: tUser?.profileIds,
	};

	const centersRowSelection: TableRowSelection<Center> = {
		onChange: (_selectedRowKeys: Key[], selectedRows: Center[]) => {
			setTUser({ ...tUser, centerIds: selectedRows.map(k => k.id) });
			setIsChanged(!objectsAreEquals(originalUser, { ...tUser, centerIds: selectedRows.map(k => k.id) }));
		},
		selectedRowKeys: tUser?.centerIds,
	};

	const professionalRowSelection = {
		onChange: (_selectedRowKeys: Key[], selectedRows: Professional[]) => {
			formConfigurationUserEdit.setFieldsValue({
				professional: selectedRows[0] ? `${selectedRows[0].lastName} ${selectedRows[0].secondLastName}, ${selectedRows[0].names}` : '',
			});
			setProfessional(selectedRows[0]);
			setTUser({ ...tUser, professionalId: selectedRows[0].id });
			setIsChanged(!objectsAreEquals(originalUser, { ...tUser, professionalId: selectedRows[0].id }));
		},
		selectedRowKeys: [professional?.id],
	};

	const handleClear = () => {
		setProfessional(undefined);
		formConfigurationUserEdit.setFieldsValue({
			professional: '',
		});
		professionalRowSelection.selectedRowKeys = [];
	};

	const handleOnChangeProfessionalType = value => {
		var professionalItems = professionalsSelectors.selectAll(store.getState() as any).filter(p => !p.isDeleted);
		if (value !== undefined) setProfessionals(professionalItems.filter(p => p.professionalTypeId === value));
		else setProfessionals(professionalItems);
	};

	const handleValuesChange = (changedValues: any) => {
		var item = tUser;
		if (changedValues['username'] !== undefined) {
			item = { ...tUser, username: changedValues['username'] };
			setIsValidData(true);
		}
		setIsChanged(!objectsAreEquals(originalUser, item));
		setTUser(item);
	};

	const handleSave = async () => {
		try {
			var values = await formConfigurationUserEdit.validateFields();
			if (users.filter(u => u.username === values['username']).length > 0 && tUser.id === 0) {
				message.error({ content: t('messages.userExists'), key, duration: 2 });
			} else {
				var temp: User = {
					...tUser,
					username: values['username'],
					networkId: networks[0].id,
					professionalId: professional?.id || 0,
					externalId: values['externalId'],
					expoPushToken: tUser.expoPushToken,
				};
				message.loading({ content: t('messages.saving'), key, duration: 0 });
				var result = await createUpdateUser(temp, token, user);
				if (result !== undefined) {
					if (user.id === result.id) setUser(result);
					store.dispatch(usersReducer.actions.userCreateUpdate(result));
					setTUser(result);
					message.success({ content: t('messages.dataSaved'), key, duration: 2 });
				} else message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
			}
		} catch (e) {
			message.error({ content: t('messages.errorOnTransaction'), key, duration: 2 });
		}
	};

	const tabs = [
		{
			label: t('configurations.user'),
			key: 0,
			children: (
				<div>
					<Card size="small" title={t('general.details')} style={{ margin: 10, borderRadius: 10, overflow: 'hidden' }}>
						<Form {...styles.layout} form={formConfigurationUserEdit} onValuesChange={handleValuesChange}>
							<Form.Item
								label={t('configurations.user')}
								name="username"
								rules={[
									{
										required: true,
										message: t('messages.enterValue'),
									},
									{
										validator: async (_, value) => {
											if (!isValidRedSaludEmail(value)) {
												setIsValidData(false);
												return Promise.reject(new Error(t('messages.invalidRedSaludEmail')));
											}
											if ((await userExists(value, tUser, token)) && tUser.id === 0 && isValidRedSaludEmail(value)) {
												setIsValidData(false);
												return Promise.reject(new Error(t('messages.userExists')));
											} else return Promise.resolve();
										},
									},
								]}
							>
								<Input style={{ color: isDarkMode ? 'white' : 'black' }} disabled={id === 0 ? false : true} />
							</Form.Item>
							<Form.Item
								label={t('general.network')}
								name="network"
								rules={[
									{
										required: true,
										message: t('messages.enterValue'),
									},
								]}
							>
								<Select>
									{networks.map(pt => {
										return (
											<Option key={`network-${pt.id}`} value={pt.id}>
												{pt.description}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t('configurations.professional')} name="professional">
								<Input
									style={{ color: isDarkMode ? 'white' : 'black' }}
									disabled
									suffix={<Button size="small" icon={<ClearOutlined />} onClick={handleClear} />}
								/>
							</Form.Item>
							<Form.Item label={t('configurations.externalId')} name="externalId">
								<Input style={{ color: isDarkMode ? 'white' : 'black' }} disabled />
							</Form.Item>
							<Form.Item label="Expo Push Token" name="expoPushToken">
								<Input style={{ color: isDarkMode ? 'white' : 'black' }} disabled />
							</Form.Item>
						</Form>
					</Card>
					<Card size="small" style={{ margin: 10, borderRadius: 10, overflow: 'hidden' }}>
						<Row>
							<Col key="col1" flex={3}>
								<Space direction="horizontal">
									<Text strong>{t('general.searchEngine')}</Text>
									<AutoComplete
										popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
										style={styles.search}
										placeholder={t('general.search')}
										onSearch={handleSearch}
										allowClear
									/>
									<Select style={{ width: 200 }} onChange={handleOnChangeProfessionalType} allowClear>
										{professionalTypes.map(p => {
											return (
												<Option key={`options-${p.id}`} value={p.id}>
													{p.description}
												</Option>
											);
										})}
									</Select>
								</Space>
							</Col>
						</Row>
						<Row style={{ paddingTop: 10 }}>
							<Table<Professional>
								rowKey={item => item.id}
								rowSelection={{
									type: 'radio',
									...professionalRowSelection,
								}}
								key={`profile-table`}
								tableLayout="auto"
								size="small"
								dataSource={professionals}
								columns={professionalColumns}
								scroll={{
									y: innerHeight * 0.5,
								}}
							/>
						</Row>
					</Card>
				</div>
			),
		},
		{
			label: t('configurations.profiles'),
			key: 1,
			children: (
				<div>
					<Table<Profile>
						rowKey={item => item.id}
						rowSelection={{ ...profileRowSelection }}
						key={`profile-table`}
						tableLayout="auto"
						size="small"
						dataSource={profiles}
						pagination={false}
						columns={profileColumns}
						scroll={{
							y: innerHeight * 0.75,
						}}
					/>
				</div>
			),
		},
		{
			label: t('configurations.permissions'),
			key: 2,
			children: (
				<div>
					<Table<Permission>
						rowKey={item => item.id}
						rowSelection={{ ...permissionRowSelection }}
						key={`permissions-table`}
						tableLayout="auto"
						size="small"
						dataSource={permissions}
						pagination={false}
						columns={permissionColumns}
						scroll={{
							y: innerHeight * 0.75,
						}}
					/>
				</div>
			),
		},
		{
			label: t('configurations.centers'),
			key: 3,
			children: (
				<div>
					<Table<Center>
						rowKey={item => item.id}
						rowSelection={{ ...centersRowSelection }}
						key={`centers-table`}
						tableLayout="auto"
						size="small"
						dataSource={centers}
						pagination={false}
						columns={centerColumns}
						scroll={{
							y: innerHeight * 0.75,
						}}
					/>
				</div>
			),
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Breadcrumb
							separator=">"
							items={[
								{ title: <ControlTwoTone twoToneColor={colors.brandColor1} /> },
								{ title: t('general.configuration') },
								{ title: <Link to={{ pathname: AuthRoutes.configuration + '/users' }}>{t('general.users')}</Link> },
								{ title: tUser.username },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Tooltip title={t('general.back')}>
								<Button icon={<ArrowLeftOutlined />} type="primary" shape="round" onClick={() => navigate(AuthRoutes.configuration + '/users')}>
									{t('general.back')}
								</Button>
							</Tooltip>
							<Button type="primary" shape="round" icon={<SaveOutlined />} onClick={handleSave} disabled={!isChanged || !isValidData}>
								{t('general.save')}
							</Button>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Tabs
						defaultActiveKey="0"
						style={styles.tabs}
						items={tabs.map(item => {
							return {
								label: item.label,
								key: item.key.toString(),
								children: item.children,
							};
						})}
					></Tabs>
				</Content>
			</Col>
		</div>
	);
};

export default PageConfigurationUserEdit;
