import { FunctionComponent, useState, useEffect } from 'react';
import { Space, Typography, Spin, Row, Col, InputNumber, Modal, Skeleton, Image } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import { urls } from '../../store/api';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { Text } = Typography;

interface ModalDocumentViewerProps extends ModalProps {
	url: string;
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | undefined;
}

const ModalDocumentViewer: FunctionComponent<ModalDocumentViewerProps> = ({ url, size, ...props }) => {
	const [pdf, setPdf] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { t } = useTranslation();
	const [width, setWidth] = useState<number>(1);
	const [height] = useState<number>(600);
	const [scale, setScale] = useState<number>(1);
	const [numPages, setNumPages] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [localUrl, setLocalUrl] = useState<string>('');
	const [contentType, setContenType] = useState<string>('');
	var proportion: number = 0;
	switch (size) {
		case 'xsmall':
			proportion = 2 / 5;
			break;
		case 'small':
			proportion = 1 / 3;
			break;
		case 'medium':
			proportion = 1 / 2;
			break;
		case 'large':
			proportion = 3 / 4;
			break;
		case 'xlarge':
			proportion = 4 / 5;
			break;
	}

	useEffect(() => {
		setWidth(window.innerWidth * proportion);
		var prefix = url.substring(0, 5);
		if (url !== null && url !== '' && prefix !== 'blob:') {
			fetch(`${urls.getDocument}?uri=${url}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/pdf',
				},
			}).then(response => {
				var responseContentType = response.headers.get('Content-Type');
				setContenType(responseContentType);
				response.blob().then(blob => {
					var file = new Blob([blob], { type: responseContentType });
					var local = window.URL.createObjectURL(file);
					setLocalUrl(local);
					setIsLoading(false);
				});
			});
		} else {
			setLocalUrl(url);
			setIsLoading(false);
		}
	}, [proportion, url]);

	const handlePageDimensions = async (pageNumber: number, pdf: any) => {
		if (pageNumber <= (pdf?.numPages || 0) || pageNumber >= 1) {
			var page = await pdf?.getPage(pageNumber);
			var pageWidth = page?.getViewport({ scale: 1 }).width || 1;
			var widthScale = Math.min(1, width / pageWidth);
			return widthScale;
		} else return 1;
	};

	const handleDocumentLoadSuccess = async (pdf: any) => {
		setPageNumber(1);
		setNumPages(pdf.numPages);
		setPdf(pdf);
		setScale(await handlePageDimensions(1, pdf));
	};

	const handlePageNumberChange = (value: number | string | null | undefined) => {
		if (typeof value === 'number') {
			setPageNumber(value);
		}
	};

	const handleLeftClick = async () => {
		var newPageNumber = Math.max(1, pageNumber - 1);
		if (pdf !== undefined) setScale(await handlePageDimensions(newPageNumber, pdf));
		setPageNumber(newPageNumber);
	};

	const handleRightClick = async () => {
		var newPageNumber = Math.min(numPages, pageNumber + 1);
		if (pdf !== undefined) setScale(await handlePageDimensions(newPageNumber, pdf));
		setPageNumber(newPageNumber);
	};

	const spinner = (
		<Col>
			<Row justify="center">
				<Spin size="large"></Spin>
			</Row>
		</Col>
	);

	if (contentType === 'application/pdf')
		return (
			<Modal title={t('general.viewDocument')} bodyStyle={{ height: height, padding: 0 }} width={width} {...props}>
				<div>
					<Col>
						<Row style={{ padding: 5 }}>
							<Col flex={1}>
								<Row justify="start">
									<LeftCircleOutlined style={{ fontSize: 24 }} onClick={handleLeftClick} />
								</Row>
							</Col>
							<Col flex={8}>
								<Row justify="center" align="middle">
									<Space direction="horizontal" size="small">
										<Text>{t('general.page')}</Text>
										<InputNumber min={1} max={numPages} value={pageNumber} onChange={handlePageNumberChange} type="number" width="150px" />
										<Text>de</Text>
										<InputNumber type="number" value={numPages} width="150px" disabled />
									</Space>
								</Row>
							</Col>
							<Col flex={1}>
								<Row justify="end">
									<RightCircleOutlined style={{ fontSize: 24 }} onClick={handleRightClick} />
								</Row>
							</Col>
						</Row>
						<Row align="middle" justify="center">
							<Col>
								<Row>
									{!isLoading ? (
										<Document file={localUrl} loading={spinner} onLoadSuccess={handleDocumentLoadSuccess}>
											<Row style={{ backgroundColor: 'black', padding: '1px' }}>
												<Page pageNumber={pageNumber} scale={scale}></Page>
											</Row>
										</Document>
									) : (
										<Skeleton paragraph={{ rows: 10, width: width * 0.75 }} />
									)}
								</Row>
							</Col>
						</Row>
					</Col>
				</div>
			</Modal>
		);
	else if (contentType === 'image/jpeg')
		return (
			<Col>
				<Row align="middle" justify="center">
					<Col>
						<Row>{!isLoading ? <Image src={localUrl} preview={false}></Image> : <Skeleton paragraph={{ rows: 10, width: width * 0.75 }} />}</Row>
					</Col>
				</Row>
			</Col>
		);
	else
		return (
			<Col>
				<div>
					<Row justify="center">
						<Text type="danger" strong>
							{t('messages.notSupportedFormat')}
						</Text>
					</Row>
					<Skeleton paragraph={{ rows: 10, width: width * 0.75 }} />
				</div>
			</Col>
		);
};
export default ModalDocumentViewer;
