import { ReactNode, useEffect, useState } from 'react';
import { AutoComplete, Breadcrumb, Button, Col, Layout, message, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useStore } from 'react-redux';
import { AuthRoutes } from '../../components';
import { useTranslation } from 'react-i18next';
import { activities as activitiesReducer } from '../../store/reducers';
import { ApartmentOutlined, DeleteOutlined, EditOutlined, FilterFilled, PlusOutlined } from '@ant-design/icons';
import { activitiesSelectors, centersSelectors } from '../../store/selectors';
import { Link } from 'react-router-dom';
import { Activity, ActivityType, Center, Priority } from '../../store/types';
import { ColumnProps } from 'antd/lib/table';
import { activityTypesSelectors, prioritiesSelectors } from '../../store/selectors/domains';
import { deleteActivity } from '../../store/api';
import { baseStyles, colors } from '../../assets/styles';
import { useAppConfigContext } from '../../utils';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Content } = Layout;

const PageActivityIndex = () => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [activities, setActivities] = useState<Activity[]>([]);
	const [centers, setCenters] = useState<Center[]>([]);
	const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);
	const [priorities, setPriorities] = useState<Priority[]>([]);
	const [isloading, setIsLoading] = useState(true);

	useEffect(() => {
		setCenters(centersSelectors.selectAll(store.getState() as any).filter(c => c.isVisible));
		setActivityTypes(activityTypesSelectors.selectAll(store.getState() as any));
		setPriorities(prioritiesSelectors.selectAll(store.getState() as any));
		var items = activitiesSelectors
			.selectAll(store.getState() as any)
			.filter(a => a.isDeleted === false)
			.sort((a, b) => dayjs(b.activityDate).diff(dayjs(a.activityDate), 'seconds'));
		setActivities(items);
		setIsLoading(false);
	}, [store]);

	const handleDelete = async (item: Activity) => {
		var response = await deleteActivity(item, token, user);
		if (response) {
			store.dispatch(activitiesReducer.actions.activitiesCreateUpdate({ ...item, isDeleted: true }));
			setActivities(activitiesSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted));
		} else message.error(t('message.unableToDelete'));
	};

	const columns: ColumnProps<Activity>[] = [
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: t('management.center'),
			dataIndex: 'center',
			key: 'center',
			render: (_text: string, item: Activity): ReactNode => <Text>{centers.find(c => c.id === item.originId)?.name}</Text>,
		},
		{
			title: t('management.activityNumber'),
			dataIndex: 'activityNumber',
			key: 'activityNumber',
			width: 300,
			render: (_text: string, item: Activity): ReactNode => <Text>{item.activityNumber.toUpperCase()}</Text>,
		},
		{
			title: t('management.activityDate'),
			dataIndex: 'activityTypeId',
			key: 'activityDate',
			width: 150,
			render: (_text: string, item: Activity): ReactNode => <Text>{dayjs(item.activityDate).format('DD-MM-YYYY')}</Text>,
		},
		{
			title: t('management.activityType'),
			dataIndex: 'activityTypeId',
			key: 'activityTypeId',
			width: 150,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: activityTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.activityTypeId === value,
			render: (_text: string, item: Activity): ReactNode => <Tag style={styles.tag}>{activityTypes.find(a => a.id === item.activityTypeId)?.description}</Tag>,
		},
		{
			title: t('management.priority'),
			dataIndex: 'priority',
			key: 'priority',
			width: 100,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: priorities.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.priorityId === value,
			render: (_text: string, item: Activity): ReactNode => (
				<Tag style={styles.tagAlternative}>{priorities.find(a => a.id === item.priorityId)?.description}</Tag>
			),
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Activity): ReactNode => {
				return (
					<Space size="middle">
						<Link to={AuthRoutes.activities + `/details`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.activities') },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Link key="link-add-activity" to={AuthRoutes.activities + `/details`} state={{ id: 0 }}>
								<Button key="button-add-activity" type="primary" shape="round" icon={<PlusOutlined />}>
									{t('general.add')}
								</Button>
							</Link>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete popupMatchSelectWidth={baseStyles.popupMatchSelectWidth} style={styles.search} placeholder={t('general.search')} allowClear />
							</Space>
						</Col>
						<Col flex={4} />
						<Col flex={3} />
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<Table<Activity>
							rowKey={record => record.id}
							loading={isloading}
							key={`activity-table`}
							tableLayout="auto"
							size="small"
							dataSource={activities}
							columns={columns}
							pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
							style={{ width: '96vw' }}
						/>
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PageActivityIndex;
