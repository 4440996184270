import { urls } from '../store/api';

export const includes = (a: string, b: string): boolean => {
	return a
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toUpperCase()
		.includes(
			b
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toUpperCase(),
		);
};

export const includesSplitted = (a: string, b: string): boolean => {
	var splittedA = a.split(' ');
	var splittedB = b.split(' ');
	splittedA.forEach(e => {
		if (includes(e, b)) return true;
	});
	splittedB.forEach(e => {
		if (includes(e, a)) return true;
	});
	return false;
};

export const arrayIncludes = (arr: string[], b: string): boolean => {
	var response = false;
	arr.forEach(a => {
		if (a.localeCompare(b) === 0) response = response || true;
	});
	return response;
};

export const isValidEmail = (value: string): boolean => {
	var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,})+$/;
	return regex.test(value);
};

export const isValidRedSaludEmail = (value: string): boolean => {
	var regex = /^\w+([.-]?\w+)*@redsalud.cl/;
	return regex.test(value);
};

export const isValidPhone = (value: string): boolean => {
	var regex = /^^[+]?.{8,}\d+$/;
	return regex.test(value);
};

export const isValidRut = (value: string): boolean => {
	var regex = /\d{1,2}\.?\d{3}\.?\d{3}-[\dkK]{1}$/;
	if (regex.test(value)) {
		var comp = [2, 3, 4, 5, 6, 7, 2, 3];
		var verif = value.substring(value.length - 1);
		value = value.replace(/\./g, '');
		value = value.includes('-') ? value.substring(0, value.length - 2) : value.substring(0, value.length - 1);
		var vals = Array.from(value)
			.map(c => parseInt(c))
			.reverse();
		var sum = 0;
		for (var i = 0; i < vals.length; i++) {
			sum = sum + vals[i] * comp[i];
		}
		var dif = 11 - (sum - Math.trunc(sum / 11) * 11);
		if (dif < 10 && dif.toString() === verif) return true;
		else if (dif === 11 && verif === '0') return true;
		else if (dif === 10 && verif.toUpperCase() === 'K') return true;
		else return false;
	}
	return false;
};

export const userExists = async (userName: string, user: { externalId: any }, token: string): Promise<boolean> => {
	var exists = false;
	var bearer = 'Bearer ' + token;
	const requestOptions = {
		method: 'GET',
		headers: {
			Authorization: `${bearer}`,
			User: user?.externalId,
		},
	};
	var response = await fetch(urls.userExists + '?userName=' + userName, requestOptions);
	var data = await response.text();
	if (data === 'true') exists = true;
	return exists;
};

export const objectsAreEquals = (object1: object, object2: object): boolean => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (let key of keys1) {
		if (Array.isArray(object1[key])) {
			var array1 = object1[key];
			var array2 = object2[key];
			if (array1.length !== array2.length) {
				return false;
			}
			for (var i = 0; i < array1.length; i++) {
				if (!array2.includes(array1[i])) {
					return false;
				}
			}
		} else if (object1[key] !== object2[key]) {
			return false;
		}
	}
	return true;
};
