import { ReactNode, useEffect, useState } from 'react';
import { AutoComplete, Button, Col, message, Popconfirm, Row, Space, Table, Tooltip, Typography, Tag, Layout, Breadcrumb } from 'antd';
import { useStore } from 'react-redux';
import { AuthRoutes, CheckUncheck } from '../../components';
import { useTranslation } from 'react-i18next';
import { tasksSelectors } from '../../store/selectors';
import { ApartmentOutlined, DeleteOutlined, EditOutlined, FilterFilled, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { baseStyles, colors } from '../../assets/styles';
import { Task, TaskStatus, TaskType } from '../../store/types';
import { tasks as tasksReducer } from '../../store/reducers';
import { ColumnProps } from 'antd/lib/table';
import { taskStatusesSelectors, taskTypesSelectors } from '../../store/selectors/domains';
import { deleteTask, fetchMyTasks } from '../../store/api';
import { dateFormat } from '../../assets/formats';
import { useAppConfigContext } from '../../utils/useAppConfigContext';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Content } = Layout;

const PageTaskIndex = () => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [userId] = useState<number>(user.id);
	const [tasks, setTasks] = useState<Task[]>([]);
	const [tasksStatuses, setTaskStatuses] = useState<TaskStatus[]>([]);
	const [tasksTypes, setTaskTypes] = useState<TaskType[]>([]);
	const [isloading, setIsLoading] = useState(true);

	useEffect(() => {
		setTaskStatuses(taskStatusesSelectors.selectAll(store.getState() as any));
		setTaskTypes(taskTypesSelectors.selectAll(store.getState() as any));
		fetchMyTasks(userId, token, user).then(items => {
			setTasks(items.filter(t => !t.isDeleted));
			setIsLoading(false);
		});
	}, [store, token, user, userId]);

	const handleTaskDelete = async (item: Task) => {
		var response = await deleteTask(item, token, user);
		if (response) {
			store.dispatch(tasksReducer.actions.tasksCreateUpdate({ ...item, isDeleted: true }));
			setTasks(tasksSelectors.selectAll(store.getState() as any).filter(c => !c.isDeleted));
		} else message.error(t('message.unableToDelete'));
	};

	const columns: ColumnProps<Task>[] = [
		{
			dataIndex: 'owner',
			key: 'owner',
			width: 40,
			render: (_text: string, item: Task): ReactNode => (item.taskOwnerId === user.id ? <InfoCircleOutlined size={20} /> : null),
		},
		{
			title: t('management.description'),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: t('management.creationDate'),
			dataIndex: 'creationDate',
			key: 'creationDate',
			width: 150,
			sorter: (a, b) => dayjs(a.creationDate).diff(dayjs(b.creationDate), 'seconds'),
			render: (_text: string, item: Task): ReactNode => <Text>{item.creationDate ? dayjs(item.creationDate).format(dateFormat) : ''}</Text>,
		},
		{
			title: t('management.expirationDate'),
			dataIndex: 'expirationDate',
			key: 'expirationDate',
			width: 150,
			render: (_text: string, item: Task): ReactNode => <Text>{item.expirationDate ? dayjs(item.expirationDate).format(dateFormat) : ''}</Text>,
		},
		{
			title: t('management.taskType'),
			dataIndex: 'taskTypeId',
			key: 'taskTypeId',
			width: 200,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: tasksTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.taskTypeId === value,
			render: (_text: string, item: Task): ReactNode => <Tag style={styles.tag}>{tasksTypes.find(t => t.id === item.taskTypeId)?.description}</Tag>,
		},
		{
			title: t('management.taskStatus'),
			dataIndex: 'taskStatusId',
			key: 'taskStatusId',
			width: 200,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: tasksStatuses.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.taskStatusId === value,
			render: (_text: string, item: Task): ReactNode => (
				<Tag style={styles.tagAlternative}>{tasksStatuses.find(t => t.id === item.taskStatusId)?.description}</Tag>
			),
		},
		{
			title: t('management.isFinished'),
			dataIndex: 'isFinished',
			key: 'isFinished',
			width: 100,
			ellipsis: {
				showTitle: false,
			},
			render: (_text: string, item: Task): ReactNode => <CheckUncheck isChecked={item.taskStatusId === tasksStatuses.find(ts => ts.code === 'FIN')?.id} />,
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: Task): ReactNode => {
				return (
					<Space size="middle">
						<Link to={AuthRoutes.tasks + `/details`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleTaskDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[
								{ title: <ApartmentOutlined twoToneColor={colors.brandColor1} /> },
								{ title: t('management.management') },
								{ title: t('management.tasks') },
							]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Space>
							<Link key="link-add-activity" to={AuthRoutes.tasks + `/details`} state={{ id: 0 }}>
								<Button key="button-add-activity" type="primary" shape="round" icon={<PlusOutlined />}>
									{t('general.add')}
								</Button>
							</Link>
						</Space>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete popupMatchSelectWidth={baseStyles.popupMatchSelectWidth} style={styles.search} placeholder={t('general.search')} allowClear />
							</Space>
						</Col>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<Table<Task>
							rowKey={item => item.id}
							loading={isloading}
							key={`theirTasks-table`}
							tableLayout="auto"
							size="small"
							dataSource={tasks}
							columns={columns}
							pagination={{ position: ['bottomRight'], showTotal: total => `${t('general.total')} ${total} ${t('general.items')}` }}
							style={{ width: '96vw' }}
						/>
					</Row>
				</Content>
			</Col>
		</div>
	);
};

export default PageTaskIndex;
