import { useState, ReactNode, useEffect } from 'react';
import { LinkItem, ProfessionalType } from '../../store/types';
import { Link, useNavigate } from 'react-router-dom';
import { AuthRoutes, CheckUncheck } from '../../components';
import { Row, Col, Space, Button, Table, Tag, Avatar, Tooltip, Popconfirm, message, Typography, AutoComplete, Layout, Breadcrumb, FloatButton } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, LinkOutlined, FilterFilled, BookTwoTone } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { useStore } from 'react-redux';
import { linkItemsSelectors, professionalTypeSelectors } from '../../store/selectors';
import { linkItems as linkItemReducer } from '../../store/reducers';
import { baseStyles, colors } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { deleteLink } from '../../store/api/training';
import { includes } from '../../utils/util-custom-validators';
import { useAppConfigContext } from '../../utils';

const { Text } = Typography;
const { Content } = Layout;

const PageLinksIndex = () => {
	const navigate = useNavigate();
	const store = useStore();
	const state = store.getState() as any;
	const styles = baseStyles;
	const { t } = useTranslation();
	const { token, user } = useAppConfigContext();
	const [linkItems, setLinkItems] = useState<LinkItem[]>([]);
	const [professionalTypes, setProfessionalTypes] = useState<ProfessionalType[]>([]);
	const [isloading, setIsLoading] = useState(true);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setLinkItems(linkItemsSelectors.selectAll(state).filter(ls => !ls.isDeleted));
		setProfessionalTypes(professionalTypeSelectors.selectAll(state));
		setIsLoading(false);
	}, [state, linkItems.length]);

	const handleDelete = async (item: LinkItem) => {
		var response = await deleteLink(item, token, user);
		if (response) {
			store.dispatch(linkItemReducer.actions.linkItemCreateUpdate({ ...item, isDeleted: true }));
			setLinkItems(linkItemsSelectors.selectAll(state).filter(ls => !ls.isDeleted));
		} else message.error(t('message.unableToDelete'));
	};

	const handleSelect = (_value, option) => {
		navigate(AuthRoutes.links + `/details`, { state: { id: option.value } });
	};

	const handleSearch = (value: string) => {
		if (value) {
			var searchResult = linkItemsSelectors.selectAll(state).filter(ls => !ls.isDeleted && includes(ls.title, value));
			var searchVisualization = searchResult.map(item => {
				return {
					value: item.id,
					label: (
						<div>
							<Text>{item.title}</Text>
						</div>
					),
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	const columns: ColumnProps<LinkItem>[] = [
		{
			width: 35,
			render: (_text: string, record: LinkItem) => {
				if (record.avatar !== undefined) return <Avatar size="large" src={`${record.avatar}?${Date.now()}`} />;
				else return <Avatar size="large" icon={<LinkOutlined />} />;
			},
		},
		{
			title: `${t('training.title')}`,
			dataIndex: 'title',
			key: 'title',
			width: '25%',
		},
		{
			title: `${t('training.url')}`,
			dataIndex: 'url',
			key: 'url',
			width: '25%',
		},
		{
			title: `${t('training.published')}`,
			dataIndex: 'isPublished',
			key: 'isPublished',
			render: (_text: string, item: LinkItem): ReactNode => <CheckUncheck isChecked={item.isPublished} />,
		},
		{
			title: t('general.professionalType'),
			dataIndex: 'professionalTypeId',
			key: 'professionalTypeId',
			width: 180,
			filterIcon: <FilterFilled style={styles.filter}></FilterFilled>,
			filters: professionalTypes.map(t => {
				return {
					text: t.description,
					value: t.id,
				};
			}),
			onFilter: (value, item) => item.professionalTypeId === value,
			render: (_text: string, item: LinkItem): ReactNode => (
				<Tag style={styles.professionalType}>
					{item.professionalTypeId ? professionalTypes.find(p => p.id === item.professionalTypeId)?.description.toUpperCase() : t('general.all').toUpperCase()}
				</Tag>
			),
		},
		{
			title: t('general.actions'),
			dataIndex: 'actions',
			key: 'actions',
			width: 100,
			render: (_text: string, item: LinkItem): ReactNode => {
				return (
					<Space size="middle">
						<Link to={`${AuthRoutes.links}/details`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button type="primary" shape="round" icon={<EditOutlined />} />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDelete(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<div>
			<Col>
				<Row>
					<Col>
						<Breadcrumb
							separator=">"
							style={{ height: 31, margin: '16px 24px 0 0' }}
							items={[{ title: <BookTwoTone twoToneColor={colors.brandColor1} /> }, { title: t('general.content') }, { title: t('general.links') }]}
						/>
					</Col>
					<Col flex={1}></Col>
					<Col style={{ height: 31, margin: '16px 24px 0 0' }}>
						<Link to={`${AuthRoutes.links}/details`} state={{ id: 0 }}>
							<Button shape="round" type="primary" icon={<PlusOutlined />}>
								{t('general.add')}
							</Button>
						</Link>
					</Col>
				</Row>
				<Content className="site-layout-background">
					<Row>
						<Col flex={3}>
							<Space direction="horizontal">
								<Text strong>{t('general.searchEngine')}</Text>
								<AutoComplete
									popupMatchSelectWidth={baseStyles.popupMatchSelectWidth}
									style={styles.search}
									placeholder={t('general.search')}
									allowClear
									options={options}
									onSelect={handleSelect}
									onSearch={handleSearch}
								/>
							</Space>
						</Col>
						<Col flex={4}></Col>
						<Col flex={3}></Col>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						<FloatButton.BackTop key="back-top" type="primary" tooltip={t('messages.backToTop')} />
						<Table<LinkItem>
							rowKey={item => item.id}
							loading={isloading}
							tableLayout="auto"
							size="small"
							dataSource={linkItems}
							pagination={false}
							columns={columns}
							style={{ width: '98vw' }}
						/>
					</Row>
				</Content>
			</Col>
		</div>
	);
};
export default PageLinksIndex;
